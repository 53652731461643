<div *ngIf="loading" class="loadingScreen">
  <div class="center">
    <i class="fas fa-circle-notch fa-spin fa-10x"></i>
  </div>
</div>
<div class="w-100">
  <table class="table d-none d-lg-block w-100">
    <tr class="w-100">
      <th>Stamm</th>
      <th>Teilnehmer</th>
      <th>Rechnungsnummer</th>
      <th>Rechnungsstatus</th>
      <th class="minWidthAktion">Aktionen</th>
    </tr>
    <tr *ngFor="let rechnung of rechnungen" class="w-100">
      <td><img src="{{rechnung.stamm.icon}}" [ngbTooltip]="rechnung.stamm | stammName"
               class="stammIcon "> {{rechnung.stamm.name + (rechnung.stamm.title ? (" (" + rechnung.stamm.title + ")") : "") }}
      </td>
      <td>
        <span ngbTooltip="Teilgenommen" class="badge badge-success mx-1">{{rechnung.tnTeilgenommen}}</span>
        <span ngbTooltip="Mitarbeiter" class="badge badge-warning mx-1">{{rechnung.tnMitarbeiter}}</span>
        <span ngbTooltip="Ausgefallen" class="badge badge-danger mx-1">{{rechnung.tnAusgefallen}}</span>
        <span ngbTooltip="Gesamt"
              class="badge badge-secondary mx-1">{{rechnung.tnTeilgenommen + rechnung.tnMitarbeiter + rechnung.tnAusgefallen}}</span>

      </td>
      <td>
        <app-rechnungsnummer [rechnungsnummer]="rechnung.rechnungsNummer"></app-rechnungsnummer>
      </td>
      <td>{{rechnung.statusOld || "-"}}</td>
      <td>
        <i *ngIf="useAltRechnungAddr(rechnung)" class="fas fa-warning d-inline-block mr-1" [ngbTooltip]="'Alternative Rechnungsadresse wird verwendet'"></i>

        <span *ngIf="!rechnung.statusOld">
          <button (click)="createRechnung(rechnung)" class="btn scButton mr-1" ngbTooltip="Rechnung erzeugen"><i
            class="fas fa-plus"></i></button>
        </span>
        <span
          *ngIf="rechnung.statusOld === 'Erstellt' || rechnung.statusOld === 'Gesendet' || rechnung.statusOld === 'Gemahnt'" >
          <button class="btn scButton mr-1" (click)="showRechnung(rechnung)" ngbTooltip="Rechnung herunterladen"><i
            class="fas fa-download"></i></button>
          <button class="btn scButton mr-1" (click)="sendRechnung(rechnung)" ngbTooltip="Rechnung senden (E-Mail senden)"><i
            class="fas fa-paper-plane"></i></button>
          <button class="btn scButton mr-1" (click)="updateRechnung(rechnung)"
                  ngbTooltip="Rechnung durch eine neue Rechnung ersetzen"><i class="fas fa-recycle"></i></button>
          <button class="btn scButton mr-1 position-relative " (click)="incrementMahnung(rechnung)" ngbTooltip="Rechnung mahnen (Zähler wird erhöht. Es wird keine Mail gesendet!)">
              <i class="fas fa-bell"></i>
              <span *ngIf="rechnung.manungsanzahl" class="manungsanzahlIcon">{{rechnung.manungsanzahl}}</span>
          </button>

          <select [(ngModel)]="rechnung.rechnungsStatus" class="form-control d-inline dropDownMenu mr-1">
            <option>Gesendet</option>
            <option>Bezahlt</option>
            <option>Gemahnt</option>
            <option>Storniert</option>
          </select>
          <button class="btn scButton mr-1" (click)="saveStatus(rechnung)" ngbTooltip="Status speichern"><i
            class="fa fa-save"></i></button>
        </span>
        <span *ngIf="rechnung.statusOld === 'Bezahlt'">
          <button class="btn scButton mr-1" (click)="showRechnung(rechnung)" ngbTooltip="Rechnung herunterladen"><i
            class="fas fa-download"></i></button>
          <select [(ngModel)]="rechnung.rechnungsStatus" class="form-control d-inline dropDownMenu mr-1">
            <option>Gesendet</option>
            <option>Bezahlt</option>
            <option>Gemahnt</option>
            <option>Storniert</option>
          </select>
          <button class="btn scButton mr-1" (click)="saveStatus(rechnung)" ngbTooltip="Status speichern"><i
            class="fa fa-save"></i></button>
        </span>
        <span *ngIf="rechnung.statusOld === 'Storniert'">
          <button class="btn scButton mr-1" (click)="showRechnung(rechnung)" ngbTooltip="Rechnung herunterladen"><i
            class="fas fa-download"></i></button>
          <button class="btn scButton mr-1" (click)="updateRechnung(rechnung)"
                  ngbTooltip="Rechnung durch eine neue Rechnung ersetzen"><i class="fas fa-recycle"></i></button>
          <select [(ngModel)]="rechnung.rechnungsStatus" class="form-control d-inline dropDownMenu mr-1">
            <option>Bezahlt</option>
            <option>Gemahnt</option>
            <option>Storniert</option>
          </select>
          <button class="btn scButton mr-1" (click)="saveStatus(rechnung)" ngbTooltip="Status speichern"><i
            class="fa fa-save"></i></button>
        </span>
      </td>
    </tr>


  </table>

  <div class="container-fluid d-block d-lg-none">
    <div class="row pcBorder rounded pt-4 mx-1 my-3" *ngFor="let rechnung of rechnungen">
      <div class="col-12">
        <div class="row">
          <div class="col-12 text-center">
            <img src="{{rechnung.stamm.icon}}" [ngbTooltip]="rechnung.stamm | stammName" class="mr-2 stammIcon "><h5
            class="d-inline">{{rechnung.stamm.name + (rechnung.stamm.title ? (" (" + rechnung.stamm.title + ")") : "") }}</h5>
          </div>
        </div>
        <hr>
        <div class="row mt-3">
          <div class="col-3 text-center">
            <span ngbTooltip="Teilgenommen" class="badge large badge-success mx-1">{{rechnung.tnTeilgenommen}}</span>
          </div>

          <div class="col-3 text-center">
            <span ngbTooltip="Mitarbeiter" class="large badge badge-warning mx-1">{{rechnung.tnMitarbeiter}}</span>
          </div>
          <div class="col-3 text-center">
            <span ngbTooltip="Ausgefallen" class="large badge badge-danger mx-1">{{rechnung.tnAusgefallen}}</span>
          </div>
          <div class="col-3 text-center">
            <span ngbTooltip="Gesamt"
                  class="badge large badge-secondary mx-1">{{rechnung.tnTeilgenommen + rechnung.tnMitarbeiter + rechnung.tnAusgefallen}}</span>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 text-center">


            <span class="primaryFontColor ">Rechnungsnummer:</span><br>
            <app-rechnungsnummer [rechnungsnummer]="rechnung.rechnungsNummer"></app-rechnungsnummer>
            <br><br>
            <span class="primaryFontColor ">Rechnungsstatus:</span><br>
            {{rechnung.statusOld || "-"}}<br>
            <div class="my-2"
              *ngIf="rechnung.statusOld === 'Erstellt' || rechnung.statusOld === 'Gesendet' || rechnung.statusOld === 'Gemahnt'">
              <button class="btn scButton mr-1" (click)="showRechnung(rechnung)" ><i
                class="fas fa-download"></i></button>
            </div>
            <span
              class="text-secondary small">Das Bearbeiten der Rechnung kann nur an einem PC / Laptop erfolgen.</span>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>


