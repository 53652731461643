import {Component, OnInit, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ITeilnehmer} from '../../models/ITeilnehmer.interface';
import {TeilnehmerService} from '../../service/teilnehmer.service';
import { Gender } from 'src/app/models/gender.enum';
import { EventQueueService } from 'src/app/service/event-queue-service.service';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Icon} from "../../utils/icon/icon.component";
import {IconOption} from "../../utils/icon-switch/icon-switch.component";

@Component({
  selector: 'app-details-tn',
  templateUrl: './details-tn.component.html',
  styleUrls: ['./details-tn.component.scss']
})
export class DetailsTnComponent implements OnInit/*, OnDestroy*/ {
  @Input()
  public tnId: number;
  public tn?: ITeilnehmer;
  public iconList: Array<{icon:Icon, options: IconOption[], key:string}>;
  public Gender = Gender;

  constructor(
    private eventQueue: EventQueueService,
    private route: ActivatedRoute,
     private ngActiveModal: NgbActiveModal,
     private teilnehmerService: TeilnehmerService) {
    this.iconList = teilnehmerService.iconList;
    this.eventQueue.getObservable().subscribe(msg => {
      if (msg === "initDone") {
        this.ngOnInit();
      }
    });

  }

  ngOnInit() {
    this.tn = this.teilnehmerService.getTn(this.tnId);
  }

  close(){
    this.ngActiveModal.close();
  }

  /*ngOnDestroy() {
    this.sub.unsubscribe();
  }*/
  public readonly Icon = Icon;
}
