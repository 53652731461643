import {Component, OnInit, OnChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IVeranstaltung} from '../../models/IVeranstaltung.interface';
import {VeranstaltungenService} from '../../service/veranstaltungen.service';
import { EventQueueService } from 'src/app/service/event-queue-service.service';


@Component({
  selector: 'app-details-event',
  templateUrl: './details-event.component.html',
  styleUrls: ['./details-event.component.scss']
})
export class DetailsEventComponent implements OnInit {
  id?: number;
  public event?: IVeranstaltung;
  public selectedElement: string;

  constructor(
    private route: ActivatedRoute,
    private veranstaltungenService: VeranstaltungenService) {
    this.id = -1;
    this.route.params.subscribe(prams => {
      this.id = +prams['id'];
    });
  }

  ngOnInit() {
    this.event = this.veranstaltungenService.getEvent(this.id);
  }



}
