import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ITeilnehmer} from '../../models/ITeilnehmer.interface';
import {TeilnehmerService} from '../../service/teilnehmer.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';
import {ConfirmWindowComponent} from 'src/app/utils/confirm-window/confirm-window.component';
import {ToastrService} from 'ngx-toastr';
import {TimePickerService} from 'src/app/service/time-picker-service.service';


@Component({
  selector: 'app-edit-tn',
  templateUrl: './edit-tn.component.html',
  styleUrls: ['./edit-tn.component.scss'],
})
export class EditTnComponent implements OnInit {
  id?: number;
  public tn?: ITeilnehmer;
  constructor(
    private toast: ToastrService,
    private route: ActivatedRoute,
    private teilnehmerService: TeilnehmerService,
    private timePickerService: TimePickerService,
    private location: Location,
    private ngbModal: NgbModal,
    private toastr: ToastrService) {

    // Holt aus der Route die ID
    this.route.params.subscribe(prams => {
      this.id = +prams['id'];
      this.updateModel();
    });
  }

  ngOnInit() {
    this.updateModel();
  }

  private updateModel(){
    if (this.id === undefined) {
      console.warn("Failed to init EditTeilnehmerView, id is undefined");
      return;
    }
    this.tn = this.teilnehmerService.getTn(this.id);
  }

  save() {
    console.log("Speichern");
    console.log(this.tn);
    if(this.timePickerService.dateIsValid(this.tn.geburtsdatum)){
      this.teilnehmerService.updateTn(this.tn).
      then(msg=>{
        this.toast.success(msg, "Erfolgreich");
        this.location.back();
      }).
      catch(msg => this.toast.error(msg, "Fehler"));
    }else{
      this.toast.error("Ein Datum ist falsch eingegeben worden.", "Fehler");
    }
  }

  cancel() {
    console.log("Abbrechen");
    this.location.back();
  }
  delet(){
    const activeModal = this.ngbModal.open(ConfirmWindowComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.titel = "Teilnehmer löschen?";
    activeModal.componentInstance.text = "Soll der Teilnehmer unwiederuflich gelöscht werden?";
    activeModal.result.then((result) => {
      if(result){
        this.teilnehmerService.deleteTn(this.tn).then(msg => {
          this.toastr.success(msg, "Gelöscht");
          this.location.back();
        }).catch(err => {
          this.toastr.error(err, "Fehler");
        });
      }
    });
  }


}
