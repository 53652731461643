import { Injectable } from '@angular/core';
import { isTeilnehmer } from '../models/ITeilnehmer.interface';
import { isVeranstaltung } from '../models/IVeranstaltung.interface';
import { IStamm, isStamm } from '../models/IStamm.interface';
import * as XLSX from 'xlsx';
import { isNutzer } from '../models/INutzer.interface';
import { TeilnehmerService } from './teilnehmer.service';
import { StammService } from './stamm.service';
import { TimePickerService } from './time-picker-service.service';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(
    private teilnehmerService: TeilnehmerService,
    private timePickerService: TimePickerService,
    private stammService: StammService) { }

  public async importStamm(book: XLSX.WorkBook, importStatus: { name: string, status: 'ok' | 'fehler' | 'loading' | 'wait', msg?: string}[]){
    const data = XLSX.utils.sheet_to_json(book.Sheets[book.SheetNames[0]]);

    const newStamme: IStamm[] = data.map((row: {
      title: string,
      hausnummer: string,
      postleitzahl: number,
      strasse: string,
      ort: string,
      land: string,
      website: string,
      email: string,
      telefon: string,
      fax: string,
      name: string,
      kontoinhaber: string,
      iban: string,
      bic: string,
      bank: string
    }) => {
      return {
          id: -1,
          mitglieder: [],
          stammesleiter: [],
          title: row.title,
          hausnummer: row.hausnummer,
          icon: StammService.DEFAULT_ICON,
          ort: row.ort,
          postleitzahl: row.postleitzahl,
          strasse:row.strasse,
          land: row.land,
          website: row.website,
          email: row.email,
          telefon: row.telefon,
          fax: row.fax,
          name: row.name,
          kontoinhaber: row.kontoinhaber,
          iban: row.iban,
          bic: row.bic,
          bank: row.bank,
          alt_rechnung_land: "",
          alt_rechnung_empfaenger: "",
          alt_rechnung_ort: "",
          alt_rechnung_strasse: "",
          alt_rechnung_postleitzahl: "",
          alt_rechnung_hausnummer: ""
      };
    });

    newStamme.forEach((stamm: IStamm, index: number) => {
      importStatus[index] = { name: stamm.name, status: "wait" };
    });

    for (let i = 0; i < newStamme.length; i++) {
      importStatus[i].status = "loading";
      try {
        await this.stammService.createStamm(newStamme[i]);
        importStatus[i].status = "ok";
      } catch (exc) {
        importStatus[i].status = "fehler";
        importStatus[i].msg = exc;
      }
    }
  }

  public async importTeilnehmer(book: XLSX.WorkBook, stamm: IStamm, importStatus: { name: string, status: 'ok' | 'fehler' | 'loading' | 'wait', msg?: string }[]) {
    const data = XLSX.utils.sheet_to_json(book.Sheets[book.SheetNames[0]]);

    const newTns = data.map((row: any) => {
      const gebRaw = XLSX.SSF.parse_date_code(row.geburtsdatum);
      const geb = this.timePickerService.numToDate(gebRaw.y, gebRaw.m, gebRaw.d);
      return {
        id: row.id ? row.id : null,
        stamm: row.id ? this.teilnehmerService.getTn(row.id).stamm : stamm,
        vorname: row.vorname,
        nachname: row.nachname,
        geburtsdatum: geb,
        geschlecht: row.geschlecht,
        strasse: row.strasse,
        hausnummer: row.hausnummer,
        postleitzahl: row.postleitzahl,
        ort: row.ort,
        zusatz: row.zusatz,
        weitere_bemerkungen: row.weitere_bemerkungen,
        handynummer: row.handynummer || row['Telefon/Mobil'],
        e_mail_adresse: row.e_mail_adresse,
        schwimmabzeichen: row.schwimmabzeichen,
        medizinische_hinweise: row.medizinische_hinweise,
        ernaehrungsbesonderheiten: row.ernaehrungsbesonderheiten,
        vegetarier: this.isTrue(row.vegetarier),
        veganer: this.isTrue(row.veganer),
        krankheiten: row.krankheiten,
        darf_unter_aufsicht_schwimmen: this.isTrue(row.darf_unter_aufsicht_schwimmen),
        tetanus_impfung: this.isTrue(row.tetanus_impfung),
        besondere_aktivitaeten: this.isTrue(row.besondere_aktivitaeten),
        krankenversicherung: this.isTrue(row.krankenversicherung),
        zecken: this.isTrue(row.zecken),
        medi_verabreichen: this.isTrue(row.medi_verabreichen),
        lebensmittelunvertraeglichkeiten: row.lebensmittelunvertraeglichkeiten,
        per_vorname: row.per_vorname,
        per_nachname: row.per_nachname,
        per_strasse: row.per_strasse,
        per_hausnummer: row.per_hausnummer,
        per_postleitzahl: row.per_postleitzahl,
        per_ort: row.per_ort,
        per_zusatz: row.per_zusatz,
        per_telefonnummer: row.per_telefonnummer,
        per_handynummer: row.per_handynummer,
        per_e_mail_adresse: row.per_e_mail_adresse,
        mitarbeiterstatus: this.isTrue(row.mitarbeiterstatus)
      };
    });

    newTns.forEach((tn, index) => {
      importStatus[index] = { name: tn.vorname + " " + tn.nachname, status: "wait" };
    });

    for (let i = 0; i < newTns.length; i++) {
      importStatus[i].status = "loading";
      try {

        if(newTns[i].id) {
          await this.teilnehmerService.updateTn(newTns[i], i !== newTns.length - 1);
        } else {
          await this.teilnehmerService.createTn(newTns[i], i !== newTns.length - 1);
        }
        importStatus[i].status = "ok";
      } catch (exc) {
        importStatus[i].status = "fehler";
        importStatus[i].msg = exc;
      }
    }

  }

  public isTrue(value:string|boolean){
    if(typeof value === "boolean")
      return value;

    return value.toLowerCase() === "ja" || value.toLowerCase() === "wahr" || value.toLowerCase() === "true";
  }

  public export(obj: any[], filename: string = "export") {
    const nowString = this.timePickerService.toDateString(new Date());

    filename = nowString + " - " + this.cleanFilename(filename);

    if (obj.length === 0) {
      return;
    }

    const titleRow = this.getTitleRow(obj[0]);

    const book = XLSX.utils.book_new();
    const sheet = XLSX.utils.aoa_to_sheet([
      titleRow
    ].concat(obj.map(e => this.elementToLine(titleRow, e) as any)));
    XLSX.utils.book_append_sheet(book, sheet);
    XLSX.writeFile(book, filename + ".xlsx");
  }

  private getTitleRow(obj: any): string[] {
    const titleRow = Object.keys(obj);
    const iconIndex = titleRow.indexOf("icon");
    if(iconIndex !== -1){
      titleRow.splice(iconIndex, 1);
    }
    return titleRow;
  }

  private elementToLine(titleRow: string[], elem: any): (string | number)[] {
    return titleRow.map(k => this.toPrimitive(elem[k]));
  }

  private toPrimitive(value): string | number{
    if (isStamm(value)) {
      return value.name;
    }
    if (isTeilnehmer(value)) {
      return value.vorname + " " + value.nachname;
    }
    if (isNutzer(value)) {
      return value.vorname + " " + value.nachname;
    }
    if (isVeranstaltung(value)) {
      return value.titel;
    }
    if(typeof value === "boolean"){
      return value? "Ja" : "Nein";
    }
    if (typeof value === "string") {
      return value;
    }
    if (typeof value === "number") {
      return value;
    }
    if (Array.isArray(value)){
      return value.length;
    }
    return value as string;
  }

  private cleanFilename(raw: string): string{
    return raw.replace(/[^-a-zA-Z0-9_.()äöüß ]/g, '_');
  }
}
