<div class="row rowUeberschrift">
  <div class="col-12 text-center">
    <h1 class="text-center">Stammverwaltung</h1>
    <p class="text-center">Hier können Sie die vorhandenen Stämme einsehen und bearbeiten.</p>
  </div>
</div>

<app-filter [query]="query" [actionButtons]="actionButtons" (changeText)="onFilterChange($event)" [categories]="categories"
  [useUrlBar]="useUrlBarInSearch"></app-filter>

<div id="liste">
  <div class="row tableRow" *ngFor="let stamm of staemme;let id = index">
    <div class="col-12">
      <div class="row">
        <div class="col-4 align-self-center">
          <img src="{{stamm.icon}}" width=64px height=64px>
        </div>
        <div class="col-4 align-self-center">
          {{stamm.name + (stamm.title?(" (" + stamm.title + ")"):"")}}
        </div>
        <div class="col-4 align-self-center">
          <button class="btn btn-default" (click)="isOpen[id] = !isOpen[id]" ngbTooltip="Bearbeiten"><i
              class="fa fa-edit"></i></button>
        </div>
      </div>
      <div id="row{{id}}" class="row" [ngbCollapse]="!isOpen[id]" data-parent="#liste">
        <div class="col-12">

          <div class="row rowSpace">
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth pflichtfeld">Name</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].name">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">Titel</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].title">
            </div>
          </div>

          <div class="row rowSpace">
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth pflichtfeld">Straße</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].strasse">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth pflichtfeld">Hausnummer</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].hausnummer">
            </div>
          </div>

          <div class="row rowSpace">
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth pflichtfeld">Postleitzahl</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].postleitzahl">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth pflichtfeld">Ort</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].ort">
            </div>
          </div>

          <div class="row rowSpace">
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth pflichtfeld">Land</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].land">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append hover">
                <span class="input-group-text fixedWidth pflichtfeld">Icon</span>
              </div>
              <div class="custom-file">
                <input type="file" class="custom-file-input" (change)="fileUpload($event,staemme[id].id)"
                  id="customFile{{id}}" accept="image/*">
                <label class="custom-file-label" id="customFileLabel{{id}}" for="customFile{{id}}">Datei auswählen</label>
              </div>
            </div>
          </div>

          <div class="row rowSpace">
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth pflichtfeld">E-Mail</span>
              </div>
              <input type="email" class="form-control" [(ngModel)]="staemme[id].email">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">Webseite</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].website">
            </div>
          </div>

          <div class="row rowSpace">
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">Telefon</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].telefon">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">Fax</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].fax">
            </div>
          </div>

          <div class="row rowSpace">
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">Kontoinhaber</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].kontoinhaber">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">Bank</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].bank">
            </div>
          </div>

          <div class="row rowSpace">
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">IBAN</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].iban">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">BIC</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].bic">
            </div>
          </div>

          <div class="my-3 p-2 text-left rounded secondaryBgColor ">
            Folgende alternative Anschrift wird für <app-icon [icon]="Icon.VERANSTALTUNGSTYP_REGP"></app-icon>REGP-Veranstaltungen verwendet.
            Diese Felder müssen nur ausgefüllt werden, wenn die Rechnung in diesem Fall von der
            regulären Anschrift abweicht.
            Bei allen anderen (<app-icon [icon]="Icon.VERANSTALTUNGSTYP_LOCAL"></app-icon><app-icon [icon]="Icon.VERANSTALTUNGSTYP_REGIO"></app-icon>) Veranstaltungen wird die Anschrift des Stammes als Rechnungsadresse verwendet.
          </div>

          <div class="row rowSpace">
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">Empfänger</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].alt_rechnung_empfaenger">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">Hausnummer</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].alt_rechnung_hausnummer">
            </div>
          </div>
          <div class="row rowSpace">
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">Postleitzahl</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].alt_rechnung_postleitzahl">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">Straße</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].alt_rechnung_strasse">
            </div>
          </div>
          <div class="row rowSpace">
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">Ort</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].alt_rechnung_ort">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text fixedWidth">Land</span>
              </div>
              <input type="text" class="form-control" [(ngModel)]="staemme[id].alt_rechnung_land">
            </div>
          </div>

          <div class="row rowSpace">
            <div class="col-12 text-right">
              <button class="btn pcButton mr-2" (click)="openStammesleiterPopUp(id)">Stammesleiter</button>
              <button class="btn pcButton mr-2" (click)="save(id)">Ändern</button>
              <button class="btn btn-danger" (click)="delete(id)">Löschen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
