<div class="filterContainer ">
  <div class="d-flex px-3">
    <div class="noPadding">
      <button *ngFor="let ab of actionButtonsLeft" [ngClass]="{'d-none':!ab.mobile}"  (click)="ab.callback()" class="btn pcButton shadow d-lg-inline-block" ngbTooltip="{{ab.title}}" [innerHTML]="ab.value"></button>
    </div>
    <div class="flex-grow-1 flex-shrink-1 relative" >
      <input id="filter" class="my-2 form-control shadow" [(ngModel)]="query" placeholder="Filter" (ngModelChange)="textFieldUpdate()" autocomplete="off" (blur)="updateUrl()">
      <span id="searchHelper">
        <div *ngFor="let c of categories">
          <span class="category" *ngIf="c.type !== 'CAT'">
            <span (click)="addQuery(c.key + ':')">{{c.display}}</span>
            <span class="options" *ngIf="c.type === 'boolean'">
              <span (click)="addQuery(c.key + ':Ja ')">✅</span>
              <span (click)="addQuery(c.key + ':Nein ')">❎</span>
            </span>
          </span>
          <b *ngIf="c.type === 'CAT'" class="divider">{{c.display}}</b>
        </div>
      </span>
    </div>
    <div class="noPadding">
      <button *ngFor="let ab of actionButtonsRight" [ngClass]="{'d-none':!ab.mobile}"  (click)="ab.callback()" class="btn pcButton shadow d-lg-inline-block" ngbTooltip="{{ab.title}}" [innerHTML]="ab.value"></button>
    </div>
  </div>
</div>
