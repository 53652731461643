import {Injectable} from '@angular/core';
import {BackendService} from "./backend.service";

const SEARCH_DELAY = 100;

@Injectable({
  providedIn: 'root'
})
/*
 * vegan: vegetarier: laktoseintoleranz: lebensmittelunvertraeglichkeiten:* ernaehrungsbesonderheiten:*
 * : suchen über Felder
 * * Wildcard
 * ": " = true
 *
 * asdf
 * Suche Volltext nach asdf
 */
export class FilterEngineService {


  private timeoutIds: {[key: string]: any} = {};

  constructor() {
  }

  public async filter<T>(query: string, fullList: T[], filterKey?: string): Promise<T[]> {
    if(!filterKey){
      filterKey = "DEFAULT_FILTER_KEY";
    }
    if (fullList === undefined) {
      return Promise.resolve([]);
    }
    try {
      if (this.timeoutIds[filterKey]) {
        clearTimeout(this.timeoutIds[filterKey]);
      }
      return new Promise((resolve) => {
        this.timeoutIds[filterKey] = setTimeout(() => {
          const mappedList = fullList.map(BackendService.toSearchJsonObj)
          const filterResult = this.filterIntern(query, mappedList);
          const result = filterResult.map(e => fullList[mappedList.indexOf(e)]);
          resolve(result);
        }, SEARCH_DELAY)
      });
    } catch (err) {
      throw err;
    }
  }

  public filterIntern<T>(query: string, fullList: T[]): T[] {
    const queryElements = query.trim().split(' ');

    let reducedList = [...fullList];
    let sortKey: string | null = null;


    queryElements.forEach(queryElement => {
      if (queryElement.startsWith("$")) {
        sortKey = queryElement.substr(1);
        return;
      }
      reducedList = reducedList.filter(element => {
        return Object.keys(element).some(key => {
          queryElement = queryElement.toLowerCase();

          if (queryElement.indexOf(':') === -1) {
            const elementElement = element[key];

            if (typeof elementElement === 'boolean') {
              return elementElement && key.toLowerCase().indexOf(queryElement) !== -1;
            }

            return (elementElement + '').toLowerCase().indexOf(queryElement) !== -1;
          } else {
            const parts = queryElement.split(':');
            const left = parts[0];
            const right = parts[1];

            if (key.toLowerCase().indexOf(left) !== -1) {
              const value = element[key];

              switch (right) {
                case '!':
                  return !value;
                case '':
                case '*':
                  return !!value;
                default:
                  if (typeof value === 'boolean') {
                    switch (right) {
                      case 'true':
                      case 'ja':
                      case 'wahr':
                        return value === true;
                      case 'false':
                      case 'nein':
                      case 'falsch':
                        return value === false;
                    }
                    return false;
                  } else if (typeof value === 'string') {
                    return value.toLowerCase().indexOf(right) !== -1;
                  } else if (typeof value === 'number') {
                    try {
                      if (right.startsWith('<')) {
                        const rightAsNumber = parseFloat(right.substr(1));
                        return value < rightAsNumber;
                      } else if (right.startsWith('>')) {
                        const rightAsNumber = parseFloat(right.substr(1));
                        return value > rightAsNumber;
                      } else {
                        const rightAsNumber = parseFloat(right.substr(0));
                        return value === rightAsNumber;
                      }
                    } catch (error) {
                      // Kann nicht zu einer Zahl gemacht werden
                      return false;
                    }
                  } else {
                    return false;
                  }
              }
            } else {
              return false;
            }
          }
        });
      });
    });

    if (sortKey && reducedList.length > 0) {
      const keys = Object.keys(reducedList[0]);
      const keyIndex = keys.indexOf(sortKey) !== -1 ? keys.indexOf(sortKey) : keys.indexOf(sortKey.toLocaleLowerCase());
      if (keyIndex !== -1) {
        const key = keys[keyIndex];
        reducedList.sort((a: any, b: any) => {
          if (a[key] !== undefined && b[key] !== undefined) {
            if (typeof a[key] !== typeof b[key]) {
              return (typeof a).localeCompare(typeof b);
            }
            if (typeof a[key] === "string") {
              return a[key].localeCompare(b[key]);
            }
            if (typeof a[key] === "number") {
              return a[key] - b[key];
            }
            if (typeof a[key] === "boolean") {
              return b[key] - a[key];
            }
            return 0;
          }
          if (a[key] !== undefined) {
            return -1;
          }
          if (b[key] !== undefined) {
            return 1;
          }
          return 0;
        });
      }
    }
    return reducedList;
  };

}
