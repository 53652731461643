import { Component, OnInit } from '@angular/core';
import { INutzer } from 'src/app/models/INutzer.interface';
import { NutzerService } from 'src/app/service/nutzer.service';
import { Rolle } from 'src/app/models/Rolle.enum';
import { EventQueueService } from 'src/app/service/event-queue-service.service';

@Component({
  selector: 'app-view-user-management',
  templateUrl: './view-user-management.component.html',
  styleUrls: ['./view-user-management.component.scss']
})
export class ViewUserManagementComponent implements OnInit {

  public nutzer:INutzer[];
  public offeneNutzer:INutzer[];

  constructor(
    private nutzerService:NutzerService,
    private eventQueue: EventQueueService
    ) {
      this.eventQueue.getObservable().subscribe(msg => {
        if (msg === "initDone") {
          this.ngOnInit();
        }
      });
  }

  ngOnInit() {
    this.nutzer = this.nutzerService.getNutzerListe().filter(u=>u.rolle !== Rolle.WAIT);
    this.offeneNutzer = this.nutzerService.getNutzerListe().filter(u=>u.rolle === Rolle.WAIT);
    console.log("Nutzer: "+this.nutzer.length);
    console.log("offene: "+this.offeneNutzer.length);
  }

}
