import { Component } from '@angular/core';
import { SessionService } from 'src/app/service/session.service';
import { EventQueueService } from 'src/app/service/event-queue-service.service';
import { Rolle } from 'src/app/models/Rolle.enum';
import * as sha1 from 'js-sha1';
import { CheatServiceService } from 'src/app/service/cheat-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public username = "";
  public password = "";
  public isSL: boolean;
  public maxPerm: string;
  constructor(
    public sessionService: SessionService,
    public cheatService: CheatServiceService,
    private eventQueue: EventQueueService) {
    this.updatePermissionButtons();

    this.eventQueue.getObservable().subscribe(msg => {
      if (msg === "initDone") {
        this.updatePermissionButtons();
      }
    });
  }

  private updatePermissionButtons() {
    if(this.sessionService.session && this.sessionService.session.nutzer){
      this.isSL = this.sessionService.session.currentPermission === Rolle.SL;
      this.maxPerm = this.sessionService.session.nutzer.rolle === Rolle.SL ? 'SL' : this.sessionService.session.nutzer.rolle === Rolle.SU ? 'SU' : 'ADM';
    } else {
      this.isSL = true;
      this.maxPerm = 'SL';
    }
  }

  public login() {
    this.sessionService.login(this.username, sha1(this.password));
    this.password = "";
  }

  public logout() {
    this.sessionService.logout();
    this.username = "";
  }

  public changeRoll() {
    this.sessionService.togglePermission();
    this.maxPerm = '<i class="fas fa-circle-notch fa-spin"></i>';

  }

}
