import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IVeranstaltung} from "../../../models/IVeranstaltung.interface";
import {VeranstaltungenService} from 'src/app/service/veranstaltungen.service';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';
import {IStamm} from 'src/app/models/IStamm.interface';
import {NutzerService} from 'src/app/service/nutzer.service';
import {StammService} from 'src/app/service/stamm.service';
import {INutzer} from 'src/app/models/INutzer.interface';
import {ConfirmWindowComponent} from 'src/app/utils/confirm-window/confirm-window.component';
import {ToastrService} from 'ngx-toastr';
import {SessionService} from 'src/app/service/session.service';
import {Rolle} from 'src/app/models/Rolle.enum';
import {TimePickerService} from 'src/app/service/time-picker-service.service';


@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss']
})
export class EditEventComponent implements OnInit {

  @Input() public eventId!: number;
  public originalEvent?: IVeranstaltung;
  public event?: IVeranstaltung;
  private _startDatum?: NgbDateStruct;
  private _endDatum?: NgbDateStruct;
  private _anmeldeschluss?: NgbDateStruct;
  public staemmeToAdd: IStamm[];
  public vms: INutzer[];
  public Rolle = Rolle;

  constructor(
    private route: ActivatedRoute,
    private veranstaltungenService: VeranstaltungenService,
    private location: Location,
    public nutzerService: NutzerService,
    public stammService: StammService,
    private ngbModal: NgbModal,
    private timePickerService: TimePickerService,
    public sessionService: SessionService,
    private toastr: ToastrService) {


    this.staemmeToAdd = [];
    this.vms = [];
  }

  ngOnInit() {
    console.log("onInit");
    this.updateStaemmeToAdd();
    this.originalEvent = this.veranstaltungenService.getEvent(this.eventId);
    this.event = Object.assign({},this.originalEvent);
  }

  updateOption(type: string) {
    if (this.event === undefined) {
      console.warn("Failed to update Event Type");
      return;
    }

    this.event.veranstaltungstyp = type;
  }


  public get startDatum(): NgbDateStruct {
    if (!this._startDatum) {
      this._startDatum = this.timePickerService.dateToStruct(this.event.anfangsdatum);
    }
    return this._startDatum;
  }

  public set startDatum(newDate: NgbDateStruct) {
    this.event.anfangsdatum = this.timePickerService.structToDate(newDate);
    this._startDatum = newDate;
  }


  public get endDatum(): NgbDateStruct {
    if (!this._endDatum) {
      this._endDatum = this.timePickerService.dateToStruct(this.event.enddatum);
    }
    return this._endDatum;
  }

  public set endDatum(newDate: NgbDateStruct) {
    this.event.enddatum = this.timePickerService.structToDate(newDate);
    this._endDatum = newDate;
  }

  public get anmeldeschluss(): NgbDateStruct {
    if (!this._anmeldeschluss) {
      this._anmeldeschluss = this.timePickerService.dateToStruct(this.event.anmeldeschluss);
    }
    return this._anmeldeschluss;
  }

  public set anmeldeschluss(newDate: NgbDateStruct) {
    this.event.anmeldeschluss = this.timePickerService.structToDate(newDate);
    this._anmeldeschluss = newDate;
  }

  save() {
    console.log("Speichern");
    if(
      this.timePickerService.dateIsValid(this.event.anfangsdatum)&&
      this.timePickerService.dateIsValid(this.event.enddatum)&&
      this.timePickerService.dateIsValid(this.event.anmeldeschluss)
      ){
      this.veranstaltungenService.updateVeranstaltung(this.event).
        then(msg => {
          this.toastr.success(msg, "Erfolgreich");
        }).
        catch(msg => this.toastr.error(msg, "Fehler"));
      }else{
        this.toastr.error("Ein Datum ist falsch eingegeben worden.", "Fehler");
      }
  }

  cancel() {
    this.event = Object.assign({},this.originalEvent);
  }

  delete() {
    console.log("Löschen");
    const activeModal = this.ngbModal.open(ConfirmWindowComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.titel = "Veranstaltung löschen?";
    activeModal.componentInstance.text = "Soll die Veranstaltung unwiderruflich gelöscht werden?";
    activeModal.result.then(async (result) => {
      if (result) {
        console.log("VA wird gelöscht.");
        try{
          const msg = await this.veranstaltungenService.deleteVeranstaltung(this.event);
          this.toastr.success(msg, "Gelöscht");
          this.location.back();
        } catch(err){
          this.toastr.error(err, "Fehler");
        }
      }
    }, (reason) => {
      // ???
    });
  }

  public veranstalterToText(stamm: IStamm) {
    return stamm.name + (stamm.title ? (" (" + stamm.title + ")") : "");
  }

  public veranstalterToHtml(stamm: IStamm) {
    const txt = stamm.name + (stamm.title ? (" (" + stamm.title + ")") : "");
    return `<img height="32" width="32" src="${stamm.icon}">${txt}`;
  }

  public veranstalterToId(stamm: IStamm) {
    return stamm.id;
  }

  private updateStaemmeToAdd() {
    if (this.event === undefined) {
      console.warn("Unable to filter Stämme to Add, event is undefined");
      this.staemmeToAdd = [];
    }
    this.staemmeToAdd = this.stammService.getStammListe().filter(stamm => {
      if (this.event === undefined) {
        //Sollte nicht vorkommen
        return false;
      }

      return !this.event.staemme.some(s2 => {
        return s2.id === stamm.id;
      });
    });
  }

  public setVeranstallter(stamm: IStamm) {
    if (this.event === undefined) {
      console.warn("Failed to update Veranstalter, Event is undefined");
      return;
    }

    this.event.veranstalter = stamm;
  }



  public addStamm(stamm: IStamm) {
    if (this.event === undefined) {
      console.warn("Failed to add Stamm, Event is undefined");
      return;
    }

    if (stamm) {
      this.event.staemme.push(stamm);
    }
    this.updateStaemmeToAdd();
  }

  public dropStamm(stamm: IStamm) {
    if (this.event === undefined) {
      console.warn("Failed to add Stamm, Event is undefined");
      return;
    }

    if (stamm) {
      this.event.staemme.splice(this.event.staemme.indexOf(stamm), 1);
    }
    this.updateStaemmeToAdd();
  }

  public getPossibleVams(): INutzer[]{
    return this.nutzerService.getNutzerListe().filter(n => {
      if(this.event.veranstaltungstyp === "2") {
        return true;
      }
      if(this.event.staemme.some(s => n.stamme.some(ns => ns.id === s.id))){
        return true;
      }
      if(n.stamme.some(ns => ns.id === this.event.veranstalter.id)) {
        return true;
      }
      return false;
    });
  }
}
