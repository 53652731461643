import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Icon, IconMeta} from "../icon/icon.component";


export interface IconOption {
  icon:Icon
  strike:boolean
  value:any
}

export function binaryIconOptions(icon:Icon, invert = false) : IconOption[]{
  return [
    {icon:icon,strike:false,value:!invert},
    {icon:icon,strike:true,value:invert}
  ]
}


@Component({
  selector: 'app-icon-switch',
  templateUrl: './icon-switch.component.html',
  styleUrls: ['./icon-switch.component.scss']
})
export class IconSwitchComponent implements OnChanges {
  @Input() options : IconOption[];
  @Input() value : any;
  @Output() valueChange = new EventEmitter<any>();
  @Input() showHint = true;
  public left = 0
  public selectedOption = -1;
  public iconMeta = IconMeta;
  constructor() { }

  ngOnChanges(): void {
    this.selectedOption = this.options.findIndex(e=> e.value === this.value);
  }

}
