<div class="container-fluid pt-3">
  <div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 ">
    <div class="flex d-lg-none w-100 align-items-center text-center">
      <app-icon [icon]="typeIcon"></app-icon>
    </div>
    <h1 class="text-center primaryFontColor d-none d-lg-block" >{{event.titel}}</h1>
    <h3 class="text-center primaryFontColor d-block d-lg-none" >{{event.titel}}</h3>
    <hr>
    <div class="row">
      <div class="col-3 d-none d-lg-flex align-items-center text-center">
        <app-icon [icon]="typeIcon"></app-icon>
      </div>
      <div class="col-4 col-lg-3 text-center text-lg-left">
        <span class="primaryFontColor">Vom:</span> <br class="d-block d-lg-none"> {{event.anfangsdatum | date:"dd.MM.yy"}}<br class="d-block d-lg-none"> {{event.anfangsuhrzeit}}
      </div>
      <div class="col-4 col-lg-3 text-center text-lg-left">
        <span class="primaryFontColor">Bis:</span><br class="d-block d-lg-none"> {{event.enddatum | date:"dd.MM.yy"}}<br class="d-block d-lg-none"> {{event.enduhrzeit}}
      </div>
      <div class="col-4 col-lg-3 text-center text-lg-left">
        <span class="primaryFontColor">Teilnehmer:</span><br class="d-block d-lg-none"> {{event.teilnehmer.length}}/{{event.max_teilnehmeranzahl}}
      </div>
    </div>
  </div>
  <div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 ">
    <h2 class="text-center primaryFontColor">Veranstaltungsinfos</h2>
    <hr>
    <span class="primaryFontColor">Beschreibung:</span>
    <pre>{{event.kurzbeschreibung}}</pre>
    <div class="row" *ngIf="event.kursleitung">
      <div class="col-12">
        <span class="primaryFontColor">Kursleitung: </span>{{event.kursleitung}}
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-6 text-center">
        <span class="primaryFontColor">Ort: </span><br class="d-block d-lg-none">{{event.ort}}
      </div>
      <div class="col-6 text-center">
        <span class="primaryFontColor">Anmeldung bis: </span><br class="d-block d-lg-none">{{event.anmeldeschluss | date:"dd.MM.yy"}}
      </div>
    </div>
  </div>
  <div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 ">
    <h2 class="text-center primaryFontColor">Veranstalter</h2>
    <hr>
    <div class="row">
      <div class="col-6 text-center">
        <span class="primaryFontColor">Veranstalter: </span><br class="d-block d-lg-none">{{event.veranstalter | stammName}}
      </div>
      <div class="col-6 text-center">
        <span class="primaryFontColor">Teilnehmerpreis: </span><br class="d-block d-lg-none">{{event.preis | number:'1.2-2'}} €
      </div>
    </div>
    <div class="row">
        <div class="col-6 text-center">
          <span class="primaryFontColor">Mitarbeiterpreis: </span><br class="d-block d-lg-none">{{event.mitarbeiter_preis | number:'1.2-2'}} €
        </div>
        <div class="col-6 text-center">
          <span class="primaryFontColor">Ausfallgebühr: </span><br class="d-block d-lg-none">{{event.ausfallgebuehr | number:'1.2-2'}} €
        </div>
      </div>
  </div>

  <div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 " *ngIf="event.veranstaltungstyp === '1' && event.staemme.length > 0">
    <table class="table" >
      <tr>
        <th colspan="2" class="fielDescription text-center">Eingeladene Stämme:</th>
      </tr>
      <tr *ngFor="let stamm of event.staemme">
        <td><img width=32 height=32 src="{{stamm.icon}}"/></td>
        <td>{{stamm.name + (stamm.title?(" (" + stamm.title + ")"):"")}}</td>
      </tr>
    </table>
  </div>
</div>
