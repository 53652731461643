import { Component, Input, OnChanges, NgZone, OnInit } from '@angular/core';
import { VeranstaltungenService } from 'src/app/service/veranstaltungen.service';
import { IVeranstaltung } from 'src/app/models/IVeranstaltung.interface';
import { Anmeldung } from 'src/app/models/IAnmeldung.interface';
import { ToastrService } from 'ngx-toastr';
import { FilterEngineService } from 'src/app/service/filter-engine.service';
import { ExportService } from 'src/app/service/export-service.service';
import { IActionButton } from 'src/app/utils/filter/buttons';
import { getTeilnehmerSearchCategories, ITeilnehmer } from 'src/app/models/ITeilnehmer.interface';
import { IStamm } from 'src/app/models/IStamm.interface';
import { DetailsTnComponent } from 'src/app/teilnehmer/details-tn/details-tn.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Icon} from "../../../utils/icon/icon.component";
import {PdfGeneratorService} from "../../../service/pdf-generator.service";

type AnmeldungMitEdit = Anmeldung & {edit?: boolean}

@Component({
  selector: 'app-tn-list',
  templateUrl: './tn-list.component.html',
  styleUrls: ['./tn-list.component.scss']
})
export class TnListComponent implements OnChanges, OnInit {

  @Input()
  public eventId: number;
  public event: IVeranstaltung;
  public loading: boolean;
  public readonly Icon = Icon;
  public anmeldungen: Map<IStamm, AnmeldungMitEdit[]> = new Map();
  public sortetStaemme: IStamm[] = [];
  public useUrlBarInSearch = false;
  public categories: any;
  public query = "";
  private fullList: AnmeldungMitEdit[] = [];
  actionButtons: IActionButton[] = [
    { title: 'Export', value: '<i class="fas fa-file-export"></i>', callback: () => this.export(), mobile:false },
    { title: 'Aktualisieren', value: '<i class="fas fa-sync-alt"></i>', callback: () => this.ngOnInit(), mobile:true },
    { title: 'Preise zurücksetzen', value: '<i class="fas fa-euro-sign"></i>', callback: () => this.resetPrices(), mobile:true },
    { title: 'Teilnehmerliste', value: '<i class="fas fa-clipboard-list"></i>', callback: () => this.printTeilnehmerliste(), mobile:true, position: 'right'}
  ];


  constructor(
    private eventService: VeranstaltungenService,
    private filter: FilterEngineService,
    private zone: NgZone,
    private toastr: ToastrService,
    private exportService: ExportService,
    private ngbModal: NgbModal,
    private pdfService: PdfGeneratorService) {

    this.categories = getTeilnehmerSearchCategories();
    this.loading = true;
  }

  public async ngOnInit() {
    if (this.event) {
      this.loading = true;
      this.fullList = (await this.eventService.getPricesToEvent(this.event)).sort((a1,a2) => {

          const d = a1.teilnehmer.nachname.localeCompare(a2.teilnehmer.nachname);
          if(d === 0){
            return a1.teilnehmer.vorname.localeCompare(a2.teilnehmer.vorname);
          }
          return d;
      });
      this.onFilterChange(this.query);
      this.loading = false;
    }
  }

  public async savePrices() {
    console.log('Preise Speichern');
    try {
      this.loading = true;
      const msg = await this.eventService.setPricesToEvent(this.fullList);
      this.toastr.success(msg, 'Erfolgreich');
    }
    catch (msg) {
      this.toastr.error(msg, 'Fehler');
    }
    this.anmeldungen = new Map();
    this.fullList = [];
    this.ngOnInit();
  }

  public async onFilterChange(newQuery: string) {
    if (typeof newQuery !== "string") {
      return;
    }
    this.query = newQuery;
    try {
      const tnList = this.fullList.map(a => a.teilnehmer);
      const filteredList = await this.filter.filter(newQuery, tnList);
      this.zone.run(async () => {
        this.anmeldungen = this.groupAnmeldungen(filteredList.map(tn => this.fullList[tnList.indexOf(tn)]));
        this.sortetStaemme = Array.from(this.anmeldungen.keys()).sort((s1,s2) => {
          return s1.name.localeCompare(s2.name);
        });
      });
    } catch (error) {
      console.log('Failed to filter: ', error);
    }
  }

  public setSort(newSortKey: string) {
    const elements = this.query.split(" ");
    const withoutSearch = elements.filter(e => !e.startsWith("$"));
    this.query = withoutSearch.join(" ") + " " + newSortKey;
  }

  private groupAnmeldungen(anmeldungen: Anmeldung[]): Map<IStamm, Anmeldung[]> {
    const map = new Map();
    anmeldungen.forEach(a => {
      if(!map.get(a.teilnehmer.stamm)){
        map.set(a.teilnehmer.stamm, []);
      }
      map.get(a.teilnehmer.stamm).push(a);
    });
    return map;
  }

  public ngOnChanges() {
    this.event = this.eventService.getEvent(this.eventId);
    this.ngOnInit();
  }

  public openDetails(tn: ITeilnehmer): void{
    const activeModal = this.ngbModal.open(DetailsTnComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.tnId = tn.id;
  }

  private export() {
    const visibleTns = [];
     this.anmeldungen.forEach(subList => {
      subList.forEach(an => {
        const anExport = {};
        Object.assign(anExport, {
          preis: an.preis,
          isMitarbeiter: an.mitarbeiter,
          isAusgefallen: an.ausgefallen
        }, an.teilnehmer);
        visibleTns.push(anExport);
      });
    });

    this.exportService.export(visibleTns, "Angemeldete Teilnehmer - " + this.event.titel);
  }

  private resetPrices() {
    this.fullList.forEach(a => {
      let newPrice = a.mitarbeiter ? this.event.mitarbeiter_preis : this.event.preis;
      newPrice = a.ausgefallen ? this.event.ausfallgebuehr : newPrice;
      if(a.preis !== newPrice) {
        a.preis = newPrice;
        a.edit = true;
      }
    });
  }

  private printTeilnehmerliste(){
    const pdf = this.pdfService.generateTeilnehmerliste(this.event, Array.from(this.anmeldungen.values()).reduce((acc,val) => {
      return acc.concat(val);
    },[]));
    pdf.download(`Teilnehmerliste_${this.event.id}.pdf`);
  }

}
