import { Component } from '@angular/core';
import { ProductionService } from '../service/production.service';
import { SessionService } from '../service/session.service';

@Component({
  selector: 'app-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss']
})
export class HeadlineComponent{

  public dev = ProductionService.IS_DEVELOP;

  constructor(public sessionService: SessionService) { }

}
