<button class="btn" (click)="download()">Excel download</button>
<table class="table">
  <thead>
    <th>eventOrt</th>
    <th>eventTitle</th>
    <th>kurzbeschreibung</th>
    <th>anfangsdatum</th>
    <th>max_teilnehmeranzahl</th>
    <th>veranstaltungstyp</th>
    <th>stammTitle</th>
    <th>stammName</th>
    <th>teilgenommen</th>
    <th>ausgefallen</th>
    <th>mitarbeiter</th>
  </thead>
  <tbody>
    <tr *ngFor="let s of stats">
      <td>{{s.eventOrt}}</td>
      <td>{{s.eventTitle}}</td>
      <td>{{s.kurzbeschreibung}}</td>
      <td>{{s.anfangsdatum}}</td>
      <td>{{s.max_teilnehmeranzahl}}</td>
      <td>{{s.veranstaltungstyp}}</td>
      <td>{{s.stammTitle}}</td>
      <td>{{s.stammName}}</td>
      <td>{{s.teilgenommen}}</td>
      <td>{{s.ausgefallen}}</td>
      <td>{{s.mitarbeiter}}</td>
    </tr>
  </tbody>
</table>