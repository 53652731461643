<div class="container">
  <div class="row mt-5">
    <div class="col-2"></div>
    <div class="col-8 anmeldeDiv p-3 mt-6 rounded shadow-lg">
      <h4>Passwort zurücksetzen</h4>
      <form (keyup.enter)="setPassword()" >

        <input class="m-1 form-control" [(ngModel)]="username" name="username" type="text" placeholder="Benutzername">
        <input class="m-1 form-control" [(ngModel)]="password" name="password" type="password" placeholder="Passwort">
        <input class="m-1 form-control" [(ngModel)]="passwordCheck" name="password" type="password" placeholder="Passwort Wiederholen">
        <div class="ml-2">
          <app-password-check [password]="password" (passwordValid)="passwordValid = $event"></app-password-check>
        </div>
        <input class="mx-auto d-block btn pcButton" type="button" value="Passwort setzen" (click)="setPassword()">

      </form>
    </div>
    <div class="col-2"></div>
  </div>
</div>


