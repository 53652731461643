<div class="container">
    <h2 class="mt-2">Vorausgefüllte Teilnehmer auswählen</h2>

    <div class="w-100 d-flex">
        <button (click)="selectAll()" class="m-2 ml-0 btn scButton">Alle auswählen</button>
        <button (click)="selectNone()" class="m-2 btn scButton">Nichts auswählen</button>

        <button (click)="accept()" class="ml-auto m-2 btn pcButton">Download</button>
    </div>
    
    <table class="table">
        <tr>
            <th></th>
            <th>
                Name
            </th>
            <th>
                Alter
            </th>
        </tr>
        <tr *ngFor="let tn of tns; let i = index">
            <td>
                <input type="checkbox" [(ngModel)]="checkboxStates[i]" (change)="onCbChange($event, tn)" style="transform: scale(2);">
            </td>
            <td>
                {{tn.vorname}} {{tn.nachname}}
            </td>
            <td>
                {{tn.geburtsdatum | ageFilter}}
            </td>
        </tr>
    </table>

    <div class="w-100 d-flex">
        <button (click)="selectAll()" class="m-2 ml-0 btn scButton">Alle auswählen</button>
        <button (click)="selectNone()" class="m-2 btn scButton">Nichts auswählen</button>

        <button (click)="cancel()" class="ml-auto m-2 btn scButton">Abbrechen</button>
        <button (click)="accept()" class="m-2 btn pcButton">Download</button>
    </div>
</div>