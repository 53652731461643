<div class="container">
  <h1>Impressum</h1>

  <h2>Angaben gemäß § 5 TMG</h2>
  <p>Urban Solutions<br />
    Baggerwald 54<br />
    24976 Handewitt</p>

  <p><strong>Vertreten durch:</strong><br />
    Björn Urban</p>

  <h2>Kontakt</h2>
  <p>Telefon: +49 173 48 127 24<br />
    E-Mail: datenschutz@urban-it-solutions.de</p>

  <h2>Wirtschafts­identifikations­nummer</h2>
  <p>15/189/00466</p>

  <h2>Redaktionell verantwortlich</h2>
  <p>Ring evangelischer Gemeindepfadfinder e.V.<br />
    Plöner Chaussee 49<br />
    24326 Ascheberg<br />
    <br />
    vorstand@regp.de</p>

  <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
  <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

</div>
