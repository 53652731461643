import { IStamm } from 'src/app/models/IStamm.interface';
import { IVeranstaltung } from './IVeranstaltung.interface';

export interface IRechnungItem {
  stamm: IStamm;
  betrag: number;
  tnTeilgenommen: number;
  tnAusgefallen: number;
  tnMitarbeiter: number;
  rechnungsNummer: string;
  rechnungsStatus: string;
  manungsanzahl: number;
  veranstaltung: IVeranstaltung;
}

export function getRechnungsItemSearchCategories(): {display: string, key: string, type: string}[] {
  return [
    { display: "Allgemein", key: "Allgemein", type: "CAT" },
    { display: "Stamm", key: "stamm", type: "IStamm" },
    { display: "Veranstaltung", key: "veranstaltung", type: "IVeranstaltung" },
    { display: "Betrag", key: "betrag", type: "number" },
    { display: "Rechnungsnummer", key: "rechnungsNummer", type: "string" },
    { display: "Rechnungsstatus", key: "rechnungsStatus", type: "string" }
  ];
}
