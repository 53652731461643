import { Component, OnInit } from '@angular/core';
import { TeilnehmerService } from '../service/teilnehmer.service';
import { NutzerService } from '../service/nutzer.service';
import { StammService } from '../service/stamm.service';
import { VeranstaltungenService } from '../service/veranstaltungen.service';
import { Rolle } from '../models/Rolle.enum';
import { IStamm } from '../models/IStamm.interface';
import { async } from 'rxjs/internal/scheduler/async';
import { IVeranstaltung } from '../models/IVeranstaltung.interface';
import {Icon} from "../utils/icon/icon.component";

interface VaStats {
  title: string;
  veranstaltungstyp: string;
  tnCount: number;
  tnCount18: number;
  inviteCount: number;
  teilgenommenCount: number | string;
  mitarbeiterCount: number | string;
  ausgefallenCount: number | string;
  rechnungOffenCount: number | string;
  rechnungGesamtBetrag: number | string;
}

interface SStats {
  stamm: IStamm;
  tnCount: number;
  tnCount18: number;
  mitarbeiterCount: number;
  sls: Array<string>;
  inviteRecvCount: number;
  inviteSendCount: number;
  eventCount0: number;
  eventCount1: number;
  eventCount2: number;
  rechnungOffenCountIn: number | string;
  rechnungOffenCountOut: number | string;
}

@Component({
  selector: 'app-global-stats',
  templateUrl: './global-stats.component.html',
  styleUrls: ['./global-stats.component.scss']
})
export class GlobalStatsComponent implements OnInit {


  public global: {
    tnCount: number
    tnDeleteCount: number
    userCount: number
    userSuCount: number
    userOpenCount: number
  };
  public vas: Array<VaStats>;
  public ss: Array<SStats>;
  public readonly Icon = Icon;
  private openTasks: Array<() => Promise<void>> = [];

  private openTasks2: Array<() => Promise<void>> = [];

  public typeIcon(eventType:string){
    switch(eventType){
      case "0": return Icon.VERANSTALTUNGSTYP_LOCAL;
      case "1": return Icon.VERANSTALTUNGSTYP_REGIO;
      case "2": return Icon.VERANSTALTUNGSTYP_REGP;
      default: return Icon.VERANSTALTUNGSTYP_LOCAL;
    }
  }

  constructor(
    private tnService: TeilnehmerService,
    private nutzerService: NutzerService,
    private stammService: StammService,
    private eventService: VeranstaltungenService
  ) {
    const yearsAgo = Date.now() - 18 * 356 * 24 * 60 * 60 * 1000; //Vor 18 Jahren
    this.global = {
      tnCount: tnService.getTnList().length,
      tnDeleteCount: tnService.getTnList().filter(tn1 => tn1.vorname === "deleted").length,
      userCount: nutzerService.getNutzerListe.length,
      userSuCount: nutzerService.getNutzerListe().filter(n => n.rolle === Rolle.SU).length,
      userOpenCount: nutzerService.getNutzerListe().filter(n => n.rolle === Rolle.WAIT).length
    };
    this.vas = eventService.getEvents().map(event => {
      const va: VaStats = {
        title: event.titel,
        veranstaltungstyp: event.veranstaltungstyp,
        tnCount: event.teilnehmer.length,
        tnCount18: event.teilnehmer.filter(tn2 => tn2.geburtsdatum.getTime() > yearsAgo).length,
        inviteCount: event.staemme.length,
        teilgenommenCount: "loading",
        mitarbeiterCount: "loading",
        ausgefallenCount: "loading",
        rechnungOffenCount: "loading",
        rechnungGesamtBetrag: "loading"
      };

      this.addTask(async () => {
        const data = await eventService.getPricesToEvent(event);
        va.ausgefallenCount = data.filter(p => p.ausgefallen).length;
        va.mitarbeiterCount = data.filter(p => p.mitarbeiter).length;
        va.teilgenommenCount = va.tnCount - va.ausgefallenCount - va.mitarbeiterCount;
      });
      this.addTask(async () => {
        const data = await eventService.getRechnungenToEvent(event);
        va.rechnungOffenCount = data.filter(r => r.rechnungsStatus !== "Storniert" && r.rechnungsStatus !== "Bezahlt").length;
        va.rechnungGesamtBetrag = data.reduce((sum, r) => sum + r.betrag, 0);
      });

      return va;
    });

    this.ss = stammService.getStammListe().map(stamm => {
      const myEvents = eventService.getEvents().filter(e => e.veranstalter === stamm);
      const invitesForMe = eventService.getEvents().filter(e => e.staemme.indexOf(stamm) !== -1);
      const s: SStats = {
        stamm: stamm,
        tnCount: stamm.mitglieder.length,
        tnCount18: stamm.mitglieder.filter(m => m.geburtsdatum.getTime() > yearsAgo).length,
        mitarbeiterCount: stamm.mitglieder.filter(m => m.mitarbeiterstatus).length,
        sls: stamm.stammesleiter.map(n => n.vorname + " " + n.nachname),
        inviteRecvCount: invitesForMe.length,
        inviteSendCount: myEvents.reduce((sum, e) => sum + e.staemme.length, 0),
        eventCount0: myEvents.filter(e => e.veranstaltungstyp === "0").length,
        eventCount1: myEvents.filter(e => e.veranstaltungstyp === "1").length,
        eventCount2: myEvents.filter(e => e.veranstaltungstyp === "2").length,
        rechnungOffenCountIn: "loading",
        rechnungOffenCountOut: "loading"
      };

      this.addTask2(async () => {
        let x = 0;
        for (const e of invitesForMe) {
          const rechnungen = await eventService.getRechnungenToEvent(e);
          x += rechnungen.filter(r => r.stamm === stamm && r.rechnungsStatus !== "Storniert" && r.rechnungsStatus !== "Bezahlt").length;
        }
        s.rechnungOffenCountIn = x;
      });
      this.addTask2(async () => {
        let x = 0;
        for (const e of myEvents) {
          const rechnungen = await eventService.getRechnungenToEvent(e);
          x += rechnungen.filter(r => r.rechnungsStatus !== "Storniert" && r.rechnungsStatus !== "Bezahlt").length;
        }
        s.rechnungOffenCountOut = x;
      });

      return s;
    });

  }

  async addTask(task: () => Promise<void>) {
    this.openTasks.push(task);
    if (this.openTasks.length === 1) {
      while (this.openTasks.length > 0) {
        task = this.openTasks[0];
        try {
          await task();
          this.openTasks.splice(0, 1);
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }


  async addTask2(task: () => Promise<void>) {
    this.openTasks2.push(task);
    if (this.openTasks2.length === 1) {
      while (this.openTasks2.length > 0) {
        task = this.openTasks2[0];
        try {
          await task();
          this.openTasks2.splice(0, 1);
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }

  ngOnInit() {
  }
}
