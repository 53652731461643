import { IStamm } from './IStamm.interface';

export interface ITeilnehmer {
  id: number;
  stamm: IStamm;
  vorname: string;
  nachname: string;
  geburtsdatum: Date;
  geschlecht: string;
  strasse: string;
  hausnummer: string;
  postleitzahl: number;
  ort: string;
  zusatz: string;
  weitere_bemerkungen: string;
  handynummer: string;
  e_mail_adresse: string;
  schwimmabzeichen: string;
  medizinische_hinweise: string;
  ernaehrungsbesonderheiten: string;
  vegetarier: boolean;
  veganer: boolean;
  krankheiten: string;
  darf_unter_aufsicht_schwimmen: boolean;
  tetanus_impfung: boolean;
  lebensmittelunvertraeglichkeiten: string;
  per_vorname: string;
  per_nachname: string;
  per_strasse: string;
  per_hausnummer: string;
  per_postleitzahl: number;
  per_ort: string;
  per_zusatz: string;
  per_telefonnummer: string;
  per_handynummer: string;
  per_e_mail_adresse: string;
  mitarbeiterstatus: boolean;
  zecken: boolean;
  besondere_aktivitaeten: boolean;
  krankenversicherung: boolean;
  medi_verabreichen: boolean;
}

export function isTeilnehmer(obj: Object): obj is ITeilnehmer {
  function d(value: string): boolean {
    return value !== undefined;
  }

  return obj &&
    d(obj['id']) &&
    d(obj['stamm']) &&
    d(obj['vorname']) &&
    d(obj['nachname']) &&
    d(obj['geburtsdatum']) &&
    d(obj['geschlecht']) &&
    d(obj['strasse']) &&
    d(obj['hausnummer']) &&
    d(obj['postleitzahl']) &&
    d(obj['ort']) &&
    d(obj['zusatz']) &&
    d(obj['weitere_bemerkungen']) &&
    d(obj['handynummer']) &&
    d(obj['e_mail_adresse']) &&
    d(obj['schwimmabzeichen']) &&
    d(obj['medizinische_hinweise']) &&
    d(obj['ernaehrungsbesonderheiten']) &&
    d(obj['vegetarier']) &&
    d(obj['veganer']) &&
    d(obj['krankheiten']) &&
    d(obj['darf_unter_aufsicht_schwimmen']) &&
    d(obj['tetanus_impfung']) &&
    d(obj['lebensmittelunvertraeglichkeiten']) &&
    d(obj['per_vorname']) &&
    d(obj['per_nachname']) &&
    d(obj['per_strasse']) &&
    d(obj['per_hausnummer']) &&
    d(obj['per_postleitzahl']) &&
    d(obj['per_ort']) &&
    d(obj['per_zusatz']) &&
    d(obj['per_telefonnummer']) &&
    d(obj['per_handynummer']) &&
    d(obj['per_e_mail_adresse']) &&
    d(obj['zecken']) &&
    d(obj['besondere_aktivitaeten']) &&
    d(obj['krankenversicherung']) &&
    d(obj['mitarbeiterstatus']) &&
    d(obj['medi_verabreichen']);
}

export function getTeilnehmerSearchCategories() {
  return [
    { display: "Allgemein", key: "Allgemein", type: "CAT" },
    { display: "Stamm", key: "Stamm", type: "IStamm" },
    { display: "Vorname", key: "Vorname", type: "string" },
    { display: "Nachname", key: "Nachname", type: "string" },
    { display: "Geschlecht", key: "Geschlecht", type: "string" },
    { display: "Mitarbeiterstatus", key: "Mitarbeiterstatus", type: "boolean" },

    { display: "Adresse", key: "Allgemein", type: "CAT" },
    { display: "Strasse", key: "Strasse", type: "string" },
    { display: "Hausnummer", key: "Hausnummer", type: "string" },
    { display: "Postleitzahl", key: "Postleitzahl", type: "number" },
    { display: "Ort", key: "Ort", type: "string" },
    { display: "Zusatz", key: "Zusatz", type: "string" },

    { display: "Besonderheiten", key: "Besonderheiten", type: "CAT" },
    { display: "Vegetarier", key: "Vegetarier", type: "boolean" },
    { display: "Veganer", key: "Veganer", type: "boolean" },
    { display: "Darf unter Aufsicht schwimmen", key: "Darf_unter_Aufsicht_schwimmen", type: "boolean" },
    { display: "Tetanusimpfung", key: "Tetanus_Impfung", type: "boolean" },
    { display: "Zeckenentfernung", key: "zecken", type: "boolean" },
    { display: "Besondere Aktivitäten", key: "besondere_aktivitaeten", type: "boolean" },
    { display: "Auslandskrankenversicherung", key: "krankenversicherung", type: "boolean" },
    { display: "Medikamentenverabreichung", key: "medi_verabreichen", type: "boolean" },

    { display: "Weitere Daten", key: "Weitere Daten", type: "CAT" },
    { display: "Geburtsdatum", key: "Geburtsdatum", type: "Date" },
    { display: "Weitere Bemerkungen", key: "Weitere_Bemerkungen", type: "string" },
    { display: "Handynummer", key: "Handynummer", type: "string" },
    { display: "E-Mail", key: "E_Mail_Adresse", type: "string" },
    { display: "Schwimmabzeichen", key: "Schwimmabzeichen", type: "string" },
    { display: "Medizinische Hinweise", key: "Medizinische_Hinweise", type: "string" },
    { display: "Ernährungsbesonderheiten", key: "Ernaehrungsbesonderheiten", type: "string" },
    { display: "Krankheiten", key: "Krankheiten", type: "string" },
    { display: "Lebensmittelunverträglichkeiten", key: "Lebensmittelunvertraeglichkeiten", type: "string" },
    { display: "Id", key: "Id", type: "number" },

    { display: "Personensorgeberechtigter", key: "Personensorgeberechtigter", type: "CAT" },
    { display: "Vorname", key: "Per_Vorname", type: "string" },
    { display: "Nachname", key: "Per_Nachname", type: "string" },
    { display: "Strasse", key: "Per_Strasse", type: "string" },
    { display: "Hausnummer", key: "Per_Hausnummer", type: "string" },
    { display: "Postleitzahl", key: "Per_Postleitzahl", type: "number" },
    { display: "Ort", key: "Per_Ort", type: "string" },
    { display: "Zusatz", key: "Per_Zusatz", type: "string" },
    { display: "Telefonnummer", key: "Per_Telefonnummer", type: "string" },
    { display: "Handynummer", key: "Per_Handynummer", type: "string" },
    { display: "E-Mail", key: "Per_E_Mail_Adresse", type: "string" },
  ];
}


export class RegisterField {
  public constructor(
    public readonly print: "ja" | "manchmal",
    public readonly pflichtfeldMoeglich: boolean,
    public readonly type: "string" | "boolean",
    public readonly cols: number,
    public readonly key: keyof ITeilnehmer,
    public readonly title: string,
    public selected: boolean = true,
    public pflichtfeld: boolean = false,
    public helptext?: string) {
  }
}

export class RegisterLine {
  public constructor(
    public readonly msg: string,
    public readonly size: "lg" | "sm",
    public readonly pflichtfeldMoeglich: boolean = true,
  ) {}

  public static isRegisterLine(x: any): x is RegisterLine {
    return !!x.size;
  }
}

export function getTeilnehmerRegisterFields() {
  return [
    new RegisterLine("Persönliche Daten (Teilnehmer):", "lg"),
    new RegisterField("ja",       true, "string",  1, "vorname",                           "Vorname"                                ),
    new RegisterField("ja",       true, "string",  1, "nachname",                          "Nachname"                               ),
    new RegisterField("ja",       true, "string",  1, "geburtsdatum",                      "Geburtsdatum"                           ),
    new RegisterField("manchmal", true, "string",  1, "geschlecht",                        "Geschlecht"                             ),
    new RegisterField("manchmal", true, "string",  1, "strasse",                           "Straße"                                 ),
    new RegisterField("manchmal", true, "string",  1, "hausnummer",                        "Hausnummer"                             ),
    new RegisterField("manchmal", true, "string",  1, "postleitzahl",                      "Postleitzahl"                           ),
    new RegisterField("manchmal", true, "string",  1, "ort",                               "Ort"                                    ),
    new RegisterField("manchmal", false, "string",  2, "zusatz",                            "Adresszustatz"                          ),
    new RegisterField("manchmal", false, "string",  2, "weitere_bemerkungen",               "Bemerkungen"                            ),
    new RegisterField("manchmal", true, "string",  1, "handynummer",                       "Handynummer"                            ),
    new RegisterField("manchmal", true, "string",  1, "e_mail_adresse",                    "E-Mail"                                 ),
    new RegisterField("manchmal", true, "string",  1, "schwimmabzeichen",                  "Schwimmabzeichen"                       ),
    new RegisterField("ja",       false, "string",  4, "medizinische_hinweise",             "Medizin"                                ),
    new RegisterField("manchmal", false, "string",  4, "ernaehrungsbesonderheiten",         "Ernährungsbesonderheiten"               ),
    new RegisterField("ja",       false, "string",  4, "krankheiten",                       "Krankheiten"                            ),
    new RegisterField("manchmal", false, "string",  2, "lebensmittelunvertraeglichkeiten",  "Lebensmittelunverträglichkeiten"        ),
    new RegisterField("manchmal", false, "boolean", 1, "vegetarier",                        "Vegetarier"                             ),
    new RegisterField("manchmal", false, "boolean", 1, "veganer",                           "Veganer"                                ),
    new RegisterField("manchmal", true, "boolean", 1, "darf_unter_aufsicht_schwimmen",     "Darf unter Aufsicht schwimmen"          ),
    new RegisterField("manchmal", true, "boolean", 1, "zecken",     "Zecken dürfen entfernt werden"          ),
    new RegisterField("manchmal", true, "boolean", 1, "besondere_aktivitaeten",     "Darf an besonderen Aktivitäten (Kanu, Klettern, ...) teilnehmen"          ),
    new RegisterField("manchmal", true, "boolean", 1, "krankenversicherung",     "Auslandskrankenversicherung"          ),
    new RegisterField("manchmal", true, "boolean", 1, "tetanus_impfung",                   "Tetanus Impfung"                        ),
    new RegisterField("manchmal", true, "boolean", 2, "medi_verabreichen",                  "Medikamentenverabreichung",undefined, undefined, "Bei Bedarf können wir Ihrer Tochter/Ihrem Sohn rezeptfreie Medikamente wie Kopfschmerztabletten, Halstabletten, Wund- oder Stichsalbe " +
      "verabreichen, bedürfen hierzu aber Ihrer ausdrücklichen Zustimmung. Hinweis: Bei größeren Problemen nehmen wir in jedem Fall Kontakt mit Ihnen " +
      "oder einer Ärztin/einem Arzt vor Ort auf. Wenn Sie uns keine Zustimmung geben, müssten wir Ihre Tochter/Ihren Sohn bei jeder Verletzung (z. B. " +
      "Wespenstich) zur Ärztin/zum Arzt bringen."),
    new RegisterLine("Bei Teilnehmern unter 18 Jahren: Persönliche Daten (Personensorgeberechtigter):", "lg", false),
    new RegisterField("ja",       false, "string",  1, "per_vorname",                       "Vorname"                                ),
    new RegisterField("ja",       false, "string",  1, "per_nachname",                      "Nachname"                               ),
    new RegisterField("ja",       false, "string",  1, "per_strasse",                       "Straße"                                 ),
    new RegisterField("ja",       false, "string",  1, "per_hausnummer",                    "Hausnummer"                             ),
    new RegisterField("ja",       false, "string",  1, "per_postleitzahl",                  "Postleitzahl"                           ),
    new RegisterField("ja",       false, "string",  1, "per_ort",                           "Ort"                                    ),
    new RegisterField("ja",       false, "string",  2, "per_zusatz",                        "Zusatz"                                 ),
    new RegisterLine("Mindestens eine Erreichbarkeit", "sm", false),
    new RegisterField("ja",       false, "string",  1, "per_telefonnummer",                 "Telefonnummer"                          ),
    new RegisterField("ja",       false, "string",  1, "per_handynummer",                   "Handynummer"                            ),
    new RegisterField("ja",       false, "string",  1, "per_e_mail_adresse",                "E-Mail"                                 ),
  ];
}
