<div class="container">
  <h1 class="text-center">Stammesleiter für</h1>
  <h2 class="text-center"><img src="{{stamm.icon}}" width=40 heiht=40 class="mr-2 mb-1">{{stamm.name + (stamm.title?(" (" + stamm.title + ")"):"")}}</h2>
  <input style="opacity: 0;width: 0; height: 0;"><!-- Damit der Fokus nicht automatisch auf die Liste gesetzt wird, kleiner Hack -->
  <div class="mb-2">
    <app-nutzer-multi-data-list  [fullList]="nutzerService.getNutzerListe()" [listTitle]="'Stammesleiter'"
    [dropDownTitle]="'Stammesleiter hinzufügen:'" [partialList]="stamm.stammesleiter"
    [toggleTitle]="'Stammesleiter für den Stamm '+stamm.title">
  </app-nutzer-multi-data-list>
  <button class="btn pcButton" (click)="save()">Speichern</button>
</div>
  
</div>