import {Component, Input, OnInit} from '@angular/core';
import {IVeranstaltung} from 'src/app/models/IVeranstaltung.interface';
import {VeranstaltungenService} from 'src/app/service/veranstaltungen.service';
import {IRechnungItem} from '../../../models/IRechnungItem';
import {PdfGeneratorService} from 'src/app/service/pdf-generator.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmWindowComponent} from 'src/app/utils/confirm-window/confirm-window.component';
import {ToastrService} from 'ngx-toastr';
import {EventQueueService} from 'src/app/service/event-queue-service.service';
import {DownloadService} from "../../../service/download.service";
import {IStamm} from "../../../models/IStamm.interface";

enum RechnungsStatus {
  ERSTELLT = "Erstellt",
  GESENDET = "Gesendet",
  BEZAHLT = "Bezahlt",
  STORNIERT = "Storniert",
  GEMAHNT = "Gemahnt"
}

@Component({
  selector: 'app-rechnung',
  templateUrl: './rechnung.component.html',
  styleUrls: ['./rechnung.component.scss']
})
export class RechnungComponent implements OnInit {

  @Input() public eventId: number;
  public event: IVeranstaltung;
  public rechnungen: IRechnungItem[];
  public loading = false;

  constructor(
    private eventService: VeranstaltungenService,
    private pdfGenerator: PdfGeneratorService,
    private ngbModal: NgbModal,
    private toastr: ToastrService,
    private eventQueue: EventQueueService,
    private downloadService: DownloadService
  ) {
    this.eventQueue.getObservable().subscribe(msg => {
      if (msg === "initDone") {
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    this.event = this.eventService.getEvent(this.eventId);
    this.eventService.getRechnungenToEvent(this.event).then(data => {
      this.loading = false;
      this.rechnungen = data;
      //@ts-ignore
      this.rechnungen.forEach(r => r.statusOld = r.rechnungsStatus);
    });
  }

  public updateRechnung(rechnung: IRechnungItem) {
    if (!this.canCreateRechnung()) {
      return;
    }
    const activeModal = this.ngbModal.open(ConfirmWindowComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.titel = "Rechnung ersetzen?";
    activeModal.componentInstance.text = "Soll die bestehende Rechnung gelöscht und durch eine neue Rechnung ersetzt werden?";
    activeModal.result.then((result) => {
      if (result) {
        this.toastr.warning("Rechnung wird gelöscht und durch eine neue ersetzt", "Achtung");
        this.createRechnung(rechnung);
      }
    }).catch(err => {
      //Nothing to do here
    });
  }

  private canCreateRechnung(): boolean {
    let err = false;
    if (!this.event.veranstalter.bank) {
      this.toastr.warning("Es ist keine Bank für den Stamm des Veranstalters hinterlegt");
      err = true;
    }
    if (!this.event.veranstalter.bic) {
      this.toastr.warning("Es ist keine BIC für den Stamm des Veranstalters hinterlegt");
      err = true;
    }
    if (!this.event.veranstalter.iban) {
      this.toastr.warning("Es ist keine IBAN für den Stamm des Veranstalters hinterlegt");
      err = true;
    }
    if (!this.event.veranstalter.kontoinhaber) {
      this.toastr.warning("Es ist kein Kontoinhaber für den Stamm des Veranstalters hinterlegt");
      err = true;
    }

    return !err;
  }

  public async createRechnung(rechnung: IRechnungItem) {
    if (!this.canCreateRechnung()) {
      return;
    }
    this.loading = true;
    const tnZustand = await this.eventService.getPricesToEvent(this.event);
    const tnAufDieserRechnung = tnZustand.filter(z => z.teilnehmer.stamm === rechnung.stamm);

    tnAufDieserRechnung.sort((z1, z2) => {
      if (z1.ausgefallen && !z2.ausgefallen) {
        return -1;
      }
      if (!z1.ausgefallen && z2.ausgefallen) {
        return 1;
      }
      if (z1.mitarbeiter && !z2.mitarbeiter) {
        return -1;
      }
      if (!z1.mitarbeiter && z2.mitarbeiter) {
        return 1;
      }
      return z1.teilnehmer.nachname.localeCompare(z2.teilnehmer.nachname);
    });

    const nexNumber = rechnung.rechnungsNummer ? (parseInt(/^\d+-\d+-\d+-(\d+)$/.exec(rechnung.rechnungsNummer)[1], 10) + 1) : 1;
    const rechnungsnummer = this.event.veranstalter.id + "-" + rechnung.stamm.id + "-" + this.event.id + "-" + nexNumber;
    const verwendungszweck = rechnungsnummer + " " + this.event.titel.substring(0, 10) + " " + this.event.anfangsdatum.getFullYear();
    const alleTN = tnZustand.length;
    const pdf = this.pdfGenerator.generateRechnung(this.event.veranstalter, rechnung.stamm, tnAufDieserRechnung, rechnungsnummer, verwendungszweck, this.event, alleTN, this.useAltRechnungAddr(rechnung));
    const betrag = tnAufDieserRechnung.reduce((sum, current) => sum + current.preis, 0);

    const pdfFile = await new Promise<string>((resolve, reject) => {
      pdf.getDataUrl(result => {
        resolve(result);
      });
    });

    this.loading = true;
    this.eventService.updateRechnung(this.event, rechnung.stamm, rechnungsnummer, betrag, RechnungsStatus.ERSTELLT, rechnung.manungsanzahl, pdfFile);
    this.toastr.info("Rechnung mit der Rechnungsnummer " + rechnungsnummer + " wurde erstellt", "Erfolgreich");
  }

  public async showRechnung(rechnung: IRechnungItem) {
    const pdf = await this.eventService.getRechnungPdf(this.event, rechnung.stamm);
    this.downloadService.downloadURI(pdf, rechnung.rechnungsNummer + ".pdf");

  }

  public async saveStatus(rechnung: IRechnungItem) {
    this.loading = true;
    this.eventService.updateRechnung(this.event, rechnung.stamm, rechnung.rechnungsNummer, rechnung.betrag, rechnung.rechnungsStatus, rechnung.manungsanzahl, null);
  }


  public async sendRechnung(rechnung: IRechnungItem) {
    this.loading = true;
    const sendResult = await this.eventService.sendRechnung(this.event, rechnung.stamm);
    if (sendResult.error) {
      this.toastr.error(sendResult.error, "Fehler");
    } else {
      this.toastr.success("Rechnung gesendet", "Erfolgreich");
    }
  }

  public incrementMahnung(rechnung: IRechnungItem) {
    this.loading = true;
    this.eventService.updateRechnung(this.event, rechnung.stamm, rechnung.rechnungsNummer, rechnung.betrag, rechnung.rechnungsStatus, rechnung.manungsanzahl + 1, null);

  }

  public useAltRechnungAddr(rechnung: IRechnungItem): boolean {
    return rechnung.veranstaltung.veranstaltungstyp === "2" && this.hasAltRechnungAddr(rechnung.stamm);
  }

  private hasAltRechnungAddr(stamm: IStamm): boolean {
    return !!(stamm.alt_rechnung_empfaenger ||
      stamm.alt_rechnung_hausnummer ||
      stamm.alt_rechnung_postleitzahl ||
      stamm.alt_rechnung_strasse ||
      stamm.alt_rechnung_ort ||
      stamm.alt_rechnung_land);
  }
}
