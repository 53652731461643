<div class="container">
  <div class="d-none d-lg-block">
    <div class="row rowUeberschrift">
      <div class="col-12">
        <h1 class="text-center">Benutzereinstellungen</h1>
        <p class="text-center">Hier können Sie Kontaktdaten und Zugangsdaten ändern.</p>
        <div class="role bg-danger rounded roleADM text-center shadow" *ngIf="nutzer.rolle === Rolle.ADM ">
          Admin
        </div>
        <div class="role bg-danger rounded roleSU text-center shadow" *ngIf="nutzer.rolle === Rolle.SU ">
          Super User
        </div>
        <div class="role bg-primary rounded roleSL text-center shadow" *ngIf="nutzer.rolle === Rolle.SL ">
          Stammesleiter
        </div>
        <div class="role bg-warning rounded roleW text-center shadow" *ngIf="nutzer.rolle === Rolle.WAIT ">
          Wartend
        </div>


      </div>
    </div>


    <hr>


    <div class="row rowSpace">
      <div class="col-6 input-group">
        <div class="input-group-append ">
          <span class="input-group-text fixWidth pflichtfeld">Vorname</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="nutzer.vorname">
      </div>
      <div class="col-6 input-group">
        <div class="input-group-append ">
          <span class="input-group-text fixWidth pflichtfeld">Nachname</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="nutzer.nachname">
      </div>
    </div>

    <div class="row rowSpace">
      <div class="col-6 input-group">
        <div class="input-group-append ">
          <span class="input-group-text fixWidth">Straße</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="nutzer.strasse">
      </div>
      <div class="col-6 input-group">
        <div class="input-group-append">
          <span class="input-group-text fixWidth">Hausnummer</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="nutzer.hausnummer">
      </div>
    </div>

    <div class="row rowSpace">
      <div class="col-6 input-group">
        <div class="input-group-append">
          <span class="input-group-text fixWidth">Postleitzahl</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="nutzer.postleitzahl">
      </div>
      <div class="col-6 input-group">
        <div class="input-group-append">
          <span class="input-group-text fixWidth">Ort</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="nutzer.ort">
      </div>
    </div>

    <div class="row rowSpace">
      <div class="input-group col-6 ">
        <div class="input-group-append">
          <span class="input-group-text fixWidth">Geburtstag</span>
        </div>
        <input class="form-control " type="text" placeholder="tt.mm.jjjj" [(ngModel)]="gebDate" ngbDatepicker
               #f="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="f.toggle()" type="button"><i
            class="far fa-calendar-alt"></i></button>
        </div>
      </div>
      <div class="col-6 input-group">
        <div class="input-group-append">
          <span class="input-group-text fixWidth pflichtfeld">Geschlecht</span>
        </div>
        <select class="form-control" [(ngModel)]="nutzer.geschlecht">
          <option *ngFor="let g of Object.values(Gender)" value="{{g}}">{{g}}</option>
        </select>
      </div>
    </div>

    <div class="row rowSpace">
      <div class="col-12 text-right">
        <button class="btn pcButton" (click)="saveData(0)">Speichern</button>
        <button class="ml-2 btn btn-danger" (click)="showDelete=true">Benutzer löschen</button>
      </div>
    </div>
    <div *ngIf="showDelete" class="row">
      <div class="col-12 text-right">
        Wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.
      </div>
      <div class="col-12 text-right my-2">
        <button class="btn pcButton" (click)="showDelete=false">Nein</button>
        <button class="ml-2 btn btn-danger" (click)="delete()">Löschen</button>
      </div>
    </div>

    <hr>


    <div class="row ueberschriftRow rowSpace">
      <div class="col-12 text-center">
        <h3>Zugangsdaten</h3>
      </div>
    </div>


    <hr>


    <div class="row rowSpace">
      <div class="col-6 input-group">
        <div class="input-group-append ">
          <span class="input-group-text fixWidth">Benutzername</span>
        </div>
        <input *ngIf="currentUser.id !== nutzer.id && currentRolle !==Rolle.ADM;else hasPermission" type="password" class="form-control" value="Hallo Welt" disabled=true>
        <ng-template #hasPermission>
          <input type="text" class="form-control" value="{{nutzer.username}}" disabled=true>
        </ng-template>
      </div>
      <div class="col-6 input-group" ngbTooltip="E-Mail kann ohne Eingabe des Passworts geändert werden">
        <div class="input-group-append ">
          <span class="input-group-text fixWidth pflichtfeld">E-Mail</span>
        </div>
        <input [disabled]="currentUser.id !== nutzer.id && currentRolle !==Rolle.ADM" type="email" class="form-control"
               [(ngModel)]="nutzer.e_mail_adresse">
      </div>
    </div>

    <div *ngIf="currentUser.id === nutzer.id || currentRolle===Rolle.ADM" class="row rowSpace">
      <div class="col-6 input-group" ngbTooltip="E-Mail kann ohne Eingabe des Passworts geändert werden">
        <div class="input-group-append ">
          <span class="input-group-text fixWidth">Passwort</span>
        </div>
        <input (click)="passwordCheckVisible=true" type="password" class="form-control" [(ngModel)]="pw1">
      </div>
      <div class="col-6 input-group">
        <div class="input-group-append ">
          <span class="input-group-text fixWidth">Wiederholen</span>
        </div>
        <input type="password" class="form-control" [(ngModel)]="pw2">
      </div>
    </div>
    <div class="row rowSpace" *ngIf="passwordCheckVisible">
      <app-password-check [password]="pw1" (passwordValid)="passwordValid = $event"></app-password-check>
    </div>

    <div *ngIf="currentRolle === Rolle.ADM || nutzer.rolle !== Rolle.ADM" class="row rowSpace">
      <div class="col-6 input-group" *ngIf="currentUser.rolle === Rolle.ADM || currentUser.rolle === Rolle.SU">
        <div class="input-group-append">
          <span class="input-group-text fixWidth pflichtfeld">Rolle</span>
        </div>
        <select class="form-control" [(ngModel)]="nutzer.rolle">
          <option value="admin" *ngIf="currentUser.rolle === Rolle.ADM">Admin</option>
          <option value="super user">Super User</option>
          <option value="stammesleiter">Stammesleiter</option>
          <option value="waiting">Wartend</option>
        </select>
      </div>
    </div>

    <div class="row rowSpace">
      <div class="col-12 text-right">
        <button class="btn pcButton" (click)="saveData(1)">Speichern</button>
      </div>
    </div>


    <hr>


    <div class="row ueberschriftRow rowSpace">
      <div class="col-12 text-center">
        <h3>Zugehörige Stämme</h3>
      </div>
    </div>
  </div>
  <!-- MOBILE -->
  <div class="d-lg-none">
    <h1 class="text-center">Benutzer­einstellungen</h1>
    <p class="text-center">Hier können Sie Kontaktdaten und Zugangsdaten ändern.</p>
    <div class="role bg-danger rounded roleADM text-center shadow" *ngIf="nutzer.rolle === Rolle.ADM ">
      Admin
    </div>
    <div class="role bg-danger rounded roleSU text-center shadow" *ngIf="nutzer.rolle === Rolle.SU ">
      Super User
    </div>
    <div class="role bg-primary rounded roleSL text-center shadow" *ngIf="nutzer.rolle === Rolle.SL ">
      Stammesleiter
    </div>
    <div class="role bg-warning rounded roleW text-center shadow" *ngIf="nutzer.rolle === Rolle.WAIT ">
      Wartend
    </div>
    <hr>
    <div class=" form-group my-4 my-lg-2">
      <label class="primaryFontColor pflichtfeld">Vorname</label>
      <input class="form-control" type="text" [(ngModel)]="nutzer.vorname"
             placeholder="">
    </div>
    <div class=" form-group my-4 my-lg-2">
      <label class="primaryFontColor pflichtfeld">Nachname</label>
      <input class="form-control" type="text" [(ngModel)]="nutzer.nachname"
             placeholder="">
    </div>
    <div class=" form-group my-4 my-lg-2">
      <label class="primaryFontColor">Straße</label>
      <input class="form-control" type="text" [(ngModel)]="nutzer.strasse"
             placeholder="">
    </div>
    <div class=" form-group my-4 my-lg-2">
      <label class="primaryFontColor">Hausnummer</label>
      <input class="form-control" type="text" [(ngModel)]="nutzer.hausnummer"
             placeholder="">
    </div>
    <div class=" form-group my-4 my-lg-2">
      <label class="primaryFontColor">Postleitzahl</label>
      <input class="form-control" type="number" step="1" inputmode="numeric" max="99999"
             [(ngModel)]="nutzer.postleitzahl"
             placeholder="">
    </div>
    <div class=" form-group my-4 my-lg-2">
      <label class="primaryFontColor">Ort</label>
      <input class="form-control" type="text" [(ngModel)]="nutzer.ort"
             placeholder="">
    </div>
    <div class=" form-group my-4 my-lg-2">
      <label class="primaryFontColor">Geburtstag</label>
      <input class="form-control " type="text" placeholder="tt.mm.jjjj"
             [(ngModel)]="gebDate" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()">
    </div>

    <div class=" form-group my-4 my-lg-2">
      <label class="primaryFontColor pflichtfeld">Geschlecht</label>
      <select class="form-control" [(ngModel)]="nutzer.geschlecht">
        <option *ngFor="let g of Object.values(Gender)" value="{{g}}">{{g}}</option>
      </select>
    </div>

    <div class="d-flex justify-content-center">

      <button class="btn pcButton" (click)="saveData(0)">Speichern</button>
      <button class="ml-2 btn btn-danger" (click)="showDelete=true">Benutzer löschen</button>
    </div>
    <hr>
    <h3 class="text-center">Zugangsdaten</h3>
    <hr>
    <div *ngif="currentUser.id === nutzer.id || currentRolle ===Rolle.ADM" class=" form-group my-4 my-lg-2">
      <label class="primaryFontColor">Benutzername</label>
      <input  class="form-control" type="text" value="{{nutzer.username}}" disabled=true>

    </div>
    <div class=" form-group my-4 my-lg-2">
      <label class="primaryFontColor pflichtfeld">E-Mail</label>
      <input class="form-control" type="text" [(ngModel)]="nutzer.e_mail_adresse"
             placeholder="" [disabled]="currentUser.id !== nutzer.id && currentRolle !==Rolle.ADM">
    </div>
    <div class=" form-group my-4 my-lg-2">
      <label class="primaryFontColor">Passwort</label>
      <input (click)="passwordCheckVisible=true" type="password" class="form-control" [(ngModel)]="pw1"
             placeholder="">
    </div>
    <div class="row rowSpace" *ngIf="passwordCheckVisible">
      <app-password-check [password]="pw1" (passwordValid)="passwordValid = $event"></app-password-check>
    </div>
    <div class=" form-group my-4 my-lg-2">
      <label class="primaryFontColor">Wiederholen</label>
      <input type="password" class="form-control" [(ngModel)]="pw2">
    </div>
    <div class=" form-group my-4 my-lg-2" *ngIf="currentRolle === Rolle.ADM || nutzer.rolle !== Rolle.ADM">

      <div *ngIf="currentUser.rolle === Rolle.ADM || currentUser.rolle === Rolle.SU">

        <label class="primaryFontColor pflichtfeld">Rolle</label>

        <select class="form-control" [(ngModel)]="nutzer.rolle">
          <option value="admin" *ngIf="currentUser.rolle === Rolle.ADM">Admin</option>
          <option value="super user">Super User</option>
          <option value="stammesleiter">Stammesleiter</option>
          <option value="waiting">Wartend</option>
        </select>
      </div>
    </div>
    <div class="d-flex justify-content-center">

      <button class="btn pcButton" (click)="saveData(1)">Speichern</button>
    </div>
  <hr>
  <h3 class="text-center mb-2">Zugehörige Stämme</h3>

  </div>
  <table class="table text-center">
    <tr *ngFor="let stamm of nutzer.stamme">
      <td><img src="{{stamm.icon}}" width="32px" height="32px"></td>
      <td>{{stamm.name + (stamm.title ? (" (" + stamm.title + ")") : "")}}</td>
    </tr>
  </table>

</div>
<a class="closeIcon text-danger" *ngIf="isDialog()" (click)="close()"><i class="far fa-2x fa-times-circle"></i></a>
