import { Component, OnInit, Input } from '@angular/core';
import { VeranstaltungenService } from 'src/app/service/veranstaltungen.service';
import { ActivatedRoute } from '@angular/router';
import { IVeranstaltung } from 'src/app/models/IVeranstaltung.interface';
import { IStamm } from 'src/app/models/IStamm.interface';
import { SessionService } from 'src/app/service/session.service';
import { StammService } from 'src/app/service/stamm.service';
import { Rolle } from 'src/app/models/Rolle.enum';
import { TimePickerService } from 'src/app/service/time-picker-service.service';
import { PdfGeneratorService } from 'src/app/service/pdf-generator.service';
import { RegisterField, RegisterLine, getTeilnehmerRegisterFields } from 'src/app/models/ITeilnehmer.interface';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnmeldungSelectTnComponent } from './anmeldung-select-tn/anmeldung-select-tn.component';

@Component({
  selector: 'app-anmeldung-drucken',
  templateUrl: './anmeldung-drucken.component.html',
  styleUrls: ['./anmeldung-drucken.component.scss']
})
export class AnmeldungDruckenComponent implements OnInit {
  @Input() public eventId!: number;
  public event: IVeranstaltung;
  public zusatzbemerkung: string;
  public nutzerStaemme: IStamm[];
  public selectedStamm: IStamm;
  public data: string;
  public html: string;
  public registerForm: Array<RegisterField | RegisterLine> = getTeilnehmerRegisterFields();
  public canEditEvent: boolean;

  constructor(
    private eventService: VeranstaltungenService,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private stammService: StammService,
    private timePickerService: TimePickerService,
    private ngbModal: NgbModal,
    private pdfGenerator: PdfGeneratorService,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    this.event = this.eventService.getEvent(this.eventId);
    if(this.sessionService.session.currentPermission === Rolle.ADM){
      this.nutzerStaemme = this.stammService.getStammListe();
    }else{
      this.nutzerStaemme = [...this.event.staemme];
      this.nutzerStaemme.push(this.event.veranstalter);
    }
    this.registerForm.forEach(rf => {
      if(RegisterLine.isRegisterLine(rf)){
        //Mir egal
      } else {
        rf.pflichtfeld = new RegExp("(^|;)" + rf.key + "($|;)").test(this.event.pflichtfelder || "");
        const selectedInDb = new RegExp("(^|;)" + rf.key + "($|;)").test(this.event.registerForm || "");
        const printIsJa = rf.print === "ja";
        rf.selected = selectedInDb || printIsJa || rf.pflichtfeld;
      }
    });

    this.selectedStamm = this.event.veranstalter;
    this.canEditEvent = this.eventService.canEditEvent(this.event);
  }

  public genPDF(){
    const pdf = this.pdfGenerator.generateAnmeldung(this.event,this.selectedStamm);
    pdf.download("Anmeldung.pdf");
  }

  public async genPdfFilled() {
    const activeModal = this.ngbModal.open(AnmeldungSelectTnComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.tns = this.selectedStamm.mitglieder.filter(tn => tn.vorname !== "deleted");

    const selectedTns = await activeModal.result;

    const pdf = this.pdfGenerator.generateAnmeldung(this.event,this.selectedStamm, selectedTns);
    pdf.download("Anmeldung_vorausgefüllt.pdf");
  }

  public async save(){
    this.event.registerForm = this.registerForm.reduce((sum, current) => {
      if(RegisterLine.isRegisterLine(current)){
        return sum;
      }
      if(!current.selected){
        return sum;
      }
      return sum + current.key + ";";
    }, "");

    try {
      await this.eventService.updateVeranstaltung(this.event);
      this.toastrService.success("Speichern erfolgreich");
    } catch(e){
      console.error(e);
      this.toastrService.error(e, "Fehler beim Speichern");
    }
  }
}
