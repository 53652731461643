<div class="p-3 text-center">
  <h3>Neuer Stamm</h3>
  <p>Hier kann ein neuer Stamm angelegt werden.</p>

  <div class="input-group my-2">
    <div class="input-group-append hover w-25">
      <span class="input-group-text fixedWidth w-100 pflichtfeld">Icon</span>
    </div>
    <div class="custom-file w-75">
      <input type="file" class="custom-file-input" (change)="fileUpload($event)" accept="image/*">
      <label *ngIf="!stamm.icon" class="custom-file-label" for="customFile">Datei auswählen</label>
      <label *ngIf="stamm.icon" class="custom-file-label" for="customFile"><img src={{stamm.icon}} width="32" height="32"></label>
    </div>
  </div>
  <div class=" input-group my-2" *ngFor="let line of fields">
    <div class="input-group-append w-25">
      <span class="input-group-text w-100"  [ngClass]="{'pflichtfeld':line.pflichtfeld}" >{{line.label}}</span>
    </div>
    <div class="w-75">
      <input  class="form-control" type="{{line.type}}" [(ngModel)]="stamm[line.key]" placeholder="{{line.placeholder}}">
    </div>
  </div>

  <div class="my-3 p-2 text-left rounded secondaryBgColor ">
    Folgende alternative Anschrift wird für <app-icon [icon]="Icon.VERANSTALTUNGSTYP_REGP"></app-icon>REGP-Veranstaltungen verwendet.
    Diese Felder müssen nur ausgefüllt werden, wenn die Rechnung in diesem Fall von der
    regulären Anschrift abweicht.
    Bei allen anderen (<app-icon [icon]="Icon.VERANSTALTUNGSTYP_LOCAL"></app-icon><app-icon [icon]="Icon.VERANSTALTUNGSTYP_REGIO"></app-icon>) Veranstaltungen wird die Anschrift des Stammes als Rechnungsadresse verwendet.
  </div>
  <div class=" input-group my-2" *ngFor="let line of fieldsAltRechnung">
    <div class="input-group-append w-25">
      <span class="input-group-text w-100"  [ngClass]="{'pflichtfeld':line.pflichtfeld}" >{{line.label}}</span>
    </div>
    <div class="w-75">
      <input  class="form-control" type="{{line.type}}" [(ngModel)]="stamm[line.key]" placeholder="{{line.placeholder}}">
    </div>
  </div>

  <button class="btn pcButton" (click)="send()">Erstellen</button>
</div>
