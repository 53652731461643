import { IStamm } from './IStamm.interface';
import { Rolle } from './Rolle.enum';

export interface INutzer {
  id: number;
  username: string;
  vorname: string;
  nachname: string;
  e_mail_adresse: string;
  passworthash: string;
  rolle: Rolle;
  geburtsdatum: Date;
  last_password_change: Date;
  geschlecht: string;
  strasse: string;
  hausnummer: string;
  postleitzahl: number;
  ort: string;
  zusatz: string;
  stamme: IStamm[];
}

export function isNutzer(obj: Object): obj is INutzer {
  function d(value: string): boolean{
    return value !== undefined;
  }

  return obj &&
  d(obj["id"]) &&
  d(obj["username"]) &&
  d(obj["vorname"]) &&
  d(obj["nachname"]) &&
  d(obj["e_mail_adresse"]) &&
  d(obj["passworthash"]) &&
  d(obj["rolle"]) &&
  d(obj["geburtsdatum"]) &&
  d(obj["geschlecht"]) &&
  d(obj["strasse"]) &&
  d(obj["hausnummer"]) &&
  d(obj["postleitzahl"]) &&
  d(obj["ort"]) &&
  d(obj["zusatz"]) &&
  d(obj["stamme"])
}

export function getNutzerSearchCategories() {
  return [
    { display: "Allgemein", key: "Allgemein", type: "CAT" },
    { display: "Benutzername", key: "username", type: "string" },
    { display: "Vorname", key: "vorname", type: "string" },
    { display: "Nachname", key: "nachname", type: "string" },
    { display: "Rolle", key: "rolle", type: "Rolle" },
    { display: "Stämme", key: "stamme", type: "IStamm[]" },

    { display: "Adresse", key: "Allgemein", type: "CAT" },
    { display: "Strasse", key: "strasse", type: "string" },
    { display: "Hausnummer", key: "hausnummer", type: "string" },
    { display: "Postleitzahl", key: "postleitzahl", type: "number" },
    { display: "Ort", key: "ort", type: "string" },
    { display: "Zusatz", key: "zusatz", type: "string" },

    { display: "Sonstiges", key: "Allgemein", type: "CAT" },
    { display: "E-Mail", key: "e_mail_adresse", type: "string" },
    { display: "Geburtsdatum", key: "geburtsdatum", type: "Date" },
    { display: "Geschlecht", key: "geschlecht", type: "string" },
    { display: "ID", key: "id", type: "number" },
  ];
}
