import { Injectable } from '@angular/core';
import { IStamm } from '../models/IStamm.interface';
import { Anmeldung } from '../models/IAnmeldung.interface';
//@ts-ignore
import { TDocumentDefinitions, TCreatedPdf, Content, PageSize } from 'pdfmake/build/pdfmake';
import { TimePickerService } from './time-picker-service.service';
import { IVeranstaltung } from '../models/IVeranstaltung.interface';
import { ContentTable, ContentText, TableCell } from 'pdfmake/interfaces';
import { RegisterField, RegisterLine, getTeilnehmerRegisterFields, ITeilnehmer } from '../models/ITeilnehmer.interface';
import {AgeFilterPipe} from "../filters/age-filter.pipe";


declare const createPdf: (
  documentDefinitions: TDocumentDefinitions,
  tableLayouts?: any,
  fonts?: any,
  vfs?: any
) => TCreatedPdf;

const DESC_PFLICHTFELD = `* Pflichtfeld | Ja/Nein Felder müssen mit Ja beantwortet werden, um teilzunehmen.`

const AGB_FOTO = `Während der Veranstaltung werden Fotos und teilweise Videos durch Mitarbeiter des Veranstalters und Teilnehmer der Veranstaltung gemacht, auf denen ggf. auch Ihre Tochter/Ihr Sohn zu sehen ist. Vereinzelt werden Fotos vom Veranstalter in seinen Publikationen abgedruckt und im Internet Fotos und kurze Videos verwendet. Wir wählen die Fotos und Videos sorgfältig und gewissenhaft aus. Sie erteilen mit Ihrer Unterschrift die ausdrückliche, jederzeit widerrufliche, ansonsten jedoch unbefristete, Zustimmung zur entsprechenden Verwendung von Bildern und Videos, auf denen Ihre Tochter/Ihr Sohn abgebildet ist. Eine Verwendung ohne ihre Zustimmung ist darüber hinaus in den gesetzlich geregelten Fällen zulässig. Ihnen ist dabei bekannt, dass digitale Bilder und Videos aus dem Internet kopiert, woanders verwendet oder auch verändert werden können, ohne dass der Veranstalter darauf Einfluss hätte. Einer Veröffentlichung können Sie jederzeit widersprechen. Der Veranstalter wird im Falle eines Widerspruchs das Bild oder das Video zeitnah aus dem von ihm verantworteten Bereich im Internet (Homepage des Veranstalters) entfernen. Eine Verpflichtung zur Veranlassung der Beseitigung in Suchmaschinen, Social- Media-Portalen, Bildportalen oder sonstigen digitalen Medien (z.B. Facebook, Twitter, Instagram, WhatsApp) besteht jedoch nicht, soweit Der Veranstalter die Einstellung dort nicht selbst vorgenommen oder aktiv veranlasst hat. Auf eine Vergütung für die Veröffentlichung eines Bildes oder einer Videosequenz verzichten Sie hiermit ausdrücklich. Auf die Fotos oder Videos, die die Teilnehmer machen, hat der Veranstalter keinen Einfluss; er ist nicht verpflichtet, diesbezüglich Verbote oder Gebote auszusprechen bzw. Kontrollen vorzunehmen.`;

const AGB_DSGVO = `Hiermit versichert sich der Unterzeichnende der Erhebung und der Verarbeitung seiner Daten und des \
Teilnehmers für den er erziehungsberechtigt ist durch die Urban Solutions als Dienstleister des Ring Evangelischer Gemeindepfadfinder (REGP) \
zuzustimmen und über seine Rechte belehrt worden zu sein. Alle Informationen zum Datenschutz finden Sie unter https://anmeldung.regp.de/datenschutz.`;

const AGB_AUFSICHT = `Mir ist bekannt, dass die Teilnehmer während der Veranstaltung im Rahmen des Programms und ihrem Alter \
entsprechend freie Zeit haben, in der sie selbstständig und ohne direkte Aufsicht unterwegs sein dürfen.`;
@Injectable({
  providedIn: 'root'
})
export class PdfGeneratorService {
  private readonly docW = 595;
  private readonly docH = 841.5;
  private readonly cx = x => x / 100 * this.docW;
  private readonly cy = y => y / 100 * this.docH;
  private readonly xmm = x => x / 210 * this.docW;
  private readonly ymm = y => y / 297 * this.docH;

  constructor(
    private timePickerService: TimePickerService,
    private agePipe: AgeFilterPipe
  ) { }

  public generateAnmeldung(event: IVeranstaltung, stamm: IStamm, prefilledTns?: ITeilnehmer[]): TCreatedPdf {
    if(!prefilledTns) {
      return this.generateAnmeldungEmpty(event, stamm);
    }

    const contents = prefilledTns.map((tn, index) => this.generateContent(event, stamm, tn, index === 0));

    const combined = [];
    contents.forEach(c => combined.push(...c));

    const cx = this.cx, cy = this.cy, xmm = this.xmm, ymm = this.ymm;
    const pdf = createPdf({
      background: this.generateBackground(stamm),
      footer: this.generateFooterAnmeldung(stamm, event),
      content: combined,
      pageMargins: [cx(10), cy(7), cx(27), cy(10)]
    });

    return pdf;
  }

  public generateContent(event: IVeranstaltung, stamm: IStamm, tn: ITeilnehmer, first: boolean): any {
    const cx = this.cx, cy = this.cy, xmm = this.xmm, ymm = this.ymm;

    const content = [
      { text: `Anmeldebogen zur Veranstaltung`, fontSize: 20, bold: true, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'center' } },
      { text: `${event.titel}`, fontSize: 16, italic: true, color: "#000", margin: [0, cx(0), 0, cx(4)], style: { alignment: 'center' } },
      { text: `Informationen zur Veranstaltung:`, fontSize: 12, bold: true, color: "#000", style: { alignment: 'left' } },
      {
        table: {
          headerRows: 0,
          dontBreakRows: true,
          widths: ['*', '*'],
          style: "tableExample",
          body: [[
            {
              text: `Beginn: ${this.timePickerService.toDateString(event.anfangsdatum)}`,
              fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
            },
            {
              text: `Ende: ${this.timePickerService.toDateString(event.enddatum)}`,
              fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
            }
          ],
          [
            {
              text: `Ort: ${event.ort}`,
              fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
            },
            {
              text: `Anmeldeschluss: ${this.timePickerService.toDateString(event.anmeldeschluss)}`,
              fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
            }
          ],
          [
            {
              text: `Preis: ${event.preis.toFixed(2)} €`,
              fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
            },
            {
              text: `Veranstalter: ${event.veranstalter.name}`,
              fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
            }
          ]]
        }
      },
      { text: `Hiermit melde ich/melden wir unseren Sohn/unsere Tochter zur Veranstaltung "${event.titel}" verbindlich an.`, fontSize: 10, bold: false, color: "#000", margin: [0, cx(1), 0, cx(1)], style: { alignment: 'left' } },
      ,
      ...this.generateFormFields(event, tn)
      ,
      {
        text: DESC_PFLICHTFELD,
        fontSize: 8, bold: false, color: "#000", margin: [0, cx(2), 0, cx(0)], style: { alignment: 'left' }
      },
      {
        text: `Zustimmung durch den Unterzeichner:`, fontSize: 12, bold: true, color: "#000", style: { alignment: 'left' }, margin: [0, cx(2), 0, cx(2)]
      },
      {
        text: AGB_FOTO,
        fontSize: 8, bold: false, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'left' }
      },{
        text: AGB_AUFSICHT,
        fontSize: 8, bold: false, color: "#000", margin: [0, cx(2), 0, cx(2)], style: { alignment: 'left' }
      },
      {
        text: AGB_DSGVO,
        fontSize: 8, bold: false, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'left' }
      },
      {
        text: "__________________________________________________________", fontSize: 10, bold: false, color: "#000", margin: [0, cx(4), 0, cx(0)], style: { alignment: 'left' }
      },
      {
        text: "(Datum, Unterschrift)", fontSize: 10, bold: false, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'left' },
      }
    ];

    if(!first) {
      //@ts-ignore
      content[0].pageBreak = "before";
    }
    return content;
  }

  public generateAnmeldungEmpty(event: IVeranstaltung, stamm: IStamm): TCreatedPdf {
    const cx = this.cx, cy = this.cy, xmm = this.xmm, ymm = this.ymm;

    const pfd = createPdf({
      background: this.generateBackground(stamm),
      footer: this.generateFooterAnmeldung(stamm, event),
      content: [
        { text: `Anmeldebogen zur Veranstaltung`, fontSize: 20, bold: true, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'center' } },
        { text: `${event.titel}`, fontSize: 16, italic: true, color: "#000", margin: [0, cx(0), 0, cx(4)], style: { alignment: 'center' } },
        { text: `Informationen zur Veranstaltung:`, fontSize: 12, bold: true, color: "#000", style: { alignment: 'left' } },
        {
          table: {
            headerRows: 0,
            dontBreakRows: true,
            widths: ['*', '*'],
            style: "tableExample",
            body: [[
              {
                text: `Beginn: ${this.timePickerService.toDateString(event.anfangsdatum)}`,
                fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
              },
              {
                text: `Ende: ${this.timePickerService.toDateString(event.enddatum)}`,
                fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
              }
            ],
            [
              {
                text: `Ort: ${event.ort}`,
                fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
              },
              {
                text: `Anmeldeschluss: ${this.timePickerService.toDateString(event.anmeldeschluss)}`,
                fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
              }
            ],
            [
              {
                text: `Preis: ${event.preis.toFixed(2)} €`,
                fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
              },
              {
                text: `Veranstalter: ${event.veranstalter.name}`,
                fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, border: [false, false, false, false]
              }
            ]]
          }
        },
        { text: `Hiermit melde ich/melden wir unseren Sohn/unsere Tochter zur Veranstaltung "${event.titel}" verbindlich an.`, fontSize: 10, bold: false, color: "#000", margin: [0, cx(1), 0, cx(1)], style: { alignment: 'left' } },
        ,
        ...this.generateFormFields(event)
        ,
        {
          text: DESC_PFLICHTFELD,
          fontSize: 8, bold: false, color: "#000", margin: [0, cx(2), 0, cx(0)], style: { alignment: 'left' }
        },
        {
          text: `Zustimmung durch den Unterzeichner:`, fontSize: 12, bold: true, color: "#000", style: { alignment: 'left' }, margin: [0, cx(2), 0, cx(2)]
        },
        {
          text: AGB_FOTO,
          fontSize: 8, bold: false, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'left' }
        },
        {
          text: AGB_AUFSICHT,
          fontSize: 8, bold: false, color: "#000", margin: [0, cx(2), 0, cx(2)], style: { alignment: 'left' }
        },
        {
          text: AGB_DSGVO,
          fontSize: 8, bold: false, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'left' }
        },
        {
          text: "__________________________________________________________", fontSize: 10, bold: false, color: "#000", margin: [0, cx(4), 0, cx(0)], style: { alignment: 'left' }
        },
        {
          text: "(Datum, Unterschrift)", fontSize: 10, bold: false, color: "#000", margin: [0, cx(0), 0, cx(0)], style: { alignment: 'left' },
        }
      ],
      pageMargins: [cx(10), cy(7), cx(27), cy(10)]
    });

    return pfd;

  }

  private generateFormFields(event: IVeranstaltung, tn?: ITeilnehmer): Array<ContentTable | ContentText> {
    let registerForm: Array<RegisterField | RegisterLine> = getTeilnehmerRegisterFields();
    registerForm.forEach(rf => {
      if (RegisterLine.isRegisterLine(rf)) {
        //Mir egal
      } else {
        rf.pflichtfeld = new RegExp("(^|;)" + rf.key + "($|;)").test(event.pflichtfelder || "");
        const selectedInDb = new RegExp("(^|;)" + rf.key + "($|;)").test(event.registerForm || "");
        const printIsJa = rf.print === "ja";
        rf.selected = selectedInDb || printIsJa || rf.pflichtfeld;
      }
    });

    registerForm = registerForm.filter(rf => {
      if (RegisterLine.isRegisterLine(rf)) {
        return true;
      }
      return rf.selected;
    });

    const result: Array<ContentTable | ContentText> = [];
    for (let i = 0; i < registerForm.length; i++) {
      const rf: RegisterLine | RegisterField = registerForm[i];
      const next: RegisterField = registerForm[i+1] as RegisterField;
      if (RegisterLine.isRegisterLine(rf)) {
        result.push({
          text: rf.msg,
          fontSize: rf.size === "sm" ? 8 : 12, bold: false, color: "#000", margin: [0, this.cy(1), 0, this.cy(0)]
        });
        continue;
      }
      if(rf.cols === 1 && next?.cols === 1){
        //Merge 2 Felder in ein col;
        result.push({table: {
          headerRows: 0,
          dontBreakRows: true,
          widths: ['*', '*'],
          //style: "tableExample",
          body: [[
            this.generateCell(rf, tn),
            this.generateCell(next, tn)
          ]]
        }});
        i++;
        continue;
      } else if(rf.cols < 3){
        //Als eine Zeile bauen
        result.push({table: {
          headerRows: 0,
          dontBreakRows: true,
          widths: ['*'],
          //style: "tableExample",
          body: [[
            this.generateCell(rf, tn)
          ]]
        }});
      } else {
        //Als 2 Zeilen
        result.push({table: {
          headerRows: 0,
          dontBreakRows: true,
          widths: ['*'],
          //style: "tableExample",
          body: [[
            this.generateCell(rf, tn)
          ],[
            {
              text: " ",
              fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, margin: [0, this.cy(1), 0, this.cy(0)], border: [false, false, false, true]
            }
          ]]
        }});
      }
    }
    return result;
  }

  public generateCell(rf: RegisterField, tn?: ITeilnehmer): TableCell{
    let value = tn ? tn[rf.key] : "";
    if(typeof value === "boolean"){
      value = value ? "(Ja)" : "(Nein)";
    }
    //@ts-ignore
    if(typeof value.getMonth === "function"){
      //@ts-ignore
      value = this.timePickerService.toDateString(value);
    }
    if(rf.type === "string"){
      return {stack:[{
        text: rf.title + (rf.pflichtfeld ? ":* " : ": "),
        fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, margin: [0, this.cy(1), 0, this.cy(0)]
      },{
        text: value,
        fontSize: 10, bold: false, color: "#444", style: { alignment: 'left:' }, margin: [0, this.cy(1), 0, this.cy(0)]
      }],
      border: [false, false, false, true]};
    }
    const helptext = []
    if (rf.helptext !== undefined){
      helptext.push({
        text: rf.helptext + ":\n",
        fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, margin: [0, this.cy(1), 0, this.cy(0)]
      })
    }
    return {stack: [
        ...helptext,
      {
        text: rf.title + (rf.pflichtfeld ? ":*" : ":") + "\n   [   ] Ja   [   ] Nein ",
        fontSize: 10, bold: false, color: "#000", style: { alignment: 'left:' }, margin: [0, this.cy(1), 0, this.cy(0)]
      }, {
          text: value,
          fontSize: 10, bold: false, color: "#444", style: {alignment: 'left:'}, margin: [0, this.cy(1), 0, this.cy(0)]
      }],
     border: [false, false, false, false]};
  }


  public generateRechnung(
    stammAbsender: IStamm,
    stammEmpfanger: IStamm,
    tnList: Anmeldung[],
    rechnungsnummer: string,
    verwendungszweck: string,
    veranstaltung: IVeranstaltung,
    anzahlTeilehmer: number,
    useAltRechnungAddr: boolean
  ): TCreatedPdf {
    const now = new Date();
    const cx = this.cx, cy = this.cy, xmm = this.xmm, ymm = this.ymm;

    const pdf = createPdf({
      background: this.generateBackground(stammAbsender),
      footer: this.generateFooterRechnung(),
      content: [
        { text: `${stammAbsender.name}`, fontSize: 20, bold: true, color: "#000", margin: [0, cx(2), 0, cx(25)], style: { alignment: 'center' } },
        {
          text: `${stammAbsender.name}\n${stammAbsender.strasse} ${stammAbsender.hausnummer}, ${stammAbsender.postleitzahl} ${stammAbsender.ort}`,
          fontSize: 7, bold: false, color: "#000", absolutePosition: { x: xmm(22), y: ymm(47) }
        },
        {
          text: `${useAltRechnungAddr ? stammEmpfanger.alt_rechnung_empfaenger : stammEmpfanger.name},\n${useAltRechnungAddr ? stammEmpfanger.alt_rechnung_strasse : stammEmpfanger.strasse} ${useAltRechnungAddr ? stammEmpfanger.alt_rechnung_hausnummer : stammEmpfanger.hausnummer}\n${useAltRechnungAddr ? stammEmpfanger.alt_rechnung_postleitzahl : stammEmpfanger.postleitzahl} ${useAltRechnungAddr ? stammEmpfanger.alt_rechnung_ort : stammEmpfanger.ort}\n ${useAltRechnungAddr ? stammEmpfanger.alt_rechnung_land : stammEmpfanger.land}`,
          fontSize: 10, bold: false, color: "#000", absolutePosition: { x: xmm(22), y: ymm(54.7) }
        },
        {
          text: `Rechnungsnummer: ${rechnungsnummer}\n${stammAbsender.ort}, ${this.timePickerService.toDateString(now)}`,
          fontSize: 10, bold: false, color: "#000", style: { alignment: 'right' },
        },
        {
          text: `Rechnung ${veranstaltung.titel}\n\n`,
          fontSize: 12, bold: true, color: "#000", style: { alignment: 'left' },
        },
        {
          text: `
Sehr geehrte Damen und Herren,

wir möchten Sie bitten, für die Teilnehmer aus Ihrem Pfadfinderstamm an der Veranstallung ${veranstaltung.titel} in ${veranstaltung.ort} vom ${this.timePickerService.toDateString(veranstaltung.anfangsdatum)} bis ${this.timePickerService.toDateString(veranstaltung.enddatum)} die unten genannte Summe auf das Konto des`,
          fontSize: 12, bold: false, color: "#000", style: { alignment: 'left' },
        },
        {
          text: `
${stammAbsender.kontoinhaber}
${stammAbsender.bank}
${stammAbsender.iban}
${stammAbsender.bic}
Verwendungszweck: ${verwendungszweck}
`,
          fontSize: 12, bold: true, color: "#000", style: { alignment: 'center' },
        },
        {
          text: `\nanzuweisen.\n\n`,
          fontSize: 12, bold: false, color: "#000", style: { alignment: 'left' },
        },
        {
          table: {
            headerRows: 1,
            dontBreakRows: true,
            widths: ['*', '*', '*'],
            body: [[
              {
                text: "Name",
                fontSize: 10, bold: true, color: "#000", style: { alignment: 'center' },
              },
              {
                text: "Status",
                fontSize: 10, bold: true, color: "#000", style: { alignment: 'center' },
              },
              {
                text: "Preis",
                fontSize: 10, bold: true, color: "#000", style: { alignment: 'center' },
              }
            ]].concat(
              tnList.map(entry => {
                return [
                  {
                    text: entry.teilnehmer.vorname + " " + entry.teilnehmer.nachname,
                    fontSize: 10, bold: false, color: "#000", style: { alignment: 'left' },
                  },
                  {
                    text: entry.ausgefallen ? "Ausgefallen" : entry.mitarbeiter ? "Mitarbeiter" : "",
                    fontSize: 10, bold: false, color: "#000", style: { alignment: 'center' },
                  },
                  {
                    text: entry.preis.toFixed(2) + " €",
                    fontSize: 10, bold: false, color: "#000", style: { alignment: 'right' },
                  }
                ];
              })).concat([[
                {
                  text: "",
                  fontSize: 10, bold: false, color: "#000", style: { alignment: 'left' },
                },
                {
                  text: "",
                  fontSize: 10, bold: false, color: "#000", style: { alignment: 'center' },
                },
                {
                  text: "",
                  fontSize: 10, bold: false, color: "#000", style: { alignment: 'right' },
                }
              ], [
                {
                  text: "Summe",
                  fontSize: 10, bold: true, color: "#000", style: { alignment: 'center' },
                },
                {
                  text: "",
                  fontSize: 10, bold: false, color: "#000", style: { alignment: 'center' },
                },
                {
                  text: tnList.reduce((sum, tn) => tn.preis + sum, 0).toFixed(2) + " €",
                  fontSize: 10, bold: true, color: "#000", style: { alignment: 'right' },
                }
              ]])
          }
        },
        {
          text: `\nHiermit bestätigen wir, dass an der oben genannten Veranstalltung ${anzahlTeilehmer} Personen angemeldet waren.\n`,
          fontSize: 12, bold: false, color: "#000", style: { alignment: 'left' },
        },
        {
          text: `\n\nDieses Schreiben wurde maschinell erstellt und ist ohne Unterschrift gültig.`,
          fontSize: 10, bold: false, color: "#000", style: { alignment: 'left' },
        },
      ],
      pageMargins: [cx(10), cy(7), cx(27), cy(10)]
    });

    return pdf;
  }

  private generateFooterRechnung() {
    return (currentPage, pageCount) => {
      return {
        text: `Seite ${currentPage.toString()} von ${pageCount}`, fontSize: 8,
        absolutePosition: { x: this.cx(60), y: this.cy(7) }
      };
    };
  }

  private generateFooterTeilnehmerliste() {
    return (currentPage, pageCount) => {
      return {
        text: `Seite ${currentPage.toString()} von ${pageCount}`, fontSize: 8,
        style: { alignment: 'center' }
      };
    };
  }

  private generateFooterAnmeldung(stamm: IStamm, event: IVeranstaltung) {
    return (currentPage, pageCount) => {
      return [{
        text: `Seite ${currentPage.toString()} von ${pageCount}`, fontSize: 8,
        absolutePosition: { x: this.cx(83), y: this.cy(7) }, style: { alignment: "rigth" }
      }, {
        text: `ID: ${event.id}`, fontSize: 8,
        absolutePosition: { x: this.cx(10), y: this.cy(7) }, style: { alignment: "left" }
      }, {
        text: `${stamm.name} | ${stamm.strasse} ${stamm.hausnummer} | ${stamm.postleitzahl} ${stamm.ort} | ${stamm.website}`, fontSize: 8,
        absolutePosition: { x: this.cx(10), y: this.cy(5) }
      }];
    };
  }

  private generateBackground(stammAbsender: IStamm): ((currentPage: number, pageSize: PageSize) => string | Content | null) {
    const bgSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">
      <rect x="${this.cx(0)}" y="${this.cy(92)}" width="${this.cx(75)}" height="${this.cy(8)}" fill="#c0c0c0" stroke-width="0"/>
      <rect x="${this.cx(75)}" y="${this.cy(0)}" width="${this.cx(25)}" height="${this.cy(10)}" fill="#808080" stroke-width="0"/>
      <rect x="${this.cx(75)}" y="${this.cy(25)}" width="${this.cx(25)}" height="${this.cy(75)}" fill="#808080" stroke-width="0"/>

      <rect x="${this.xmm(5)}" y="${this.ymm(104.75)}" width="${this.xmm(5)}" height="${this.ymm(0.5)}" fill="#808080" stroke-width="0"/>
      <rect x="${this.xmm(5)}" y="${this.ymm(209.75)}" width="${this.xmm(5)}" height="${this.ymm(0.5)}" fill="#808080" stroke-width="0"/>
      <rect x="${this.xmm(5)}" y="${this.ymm(148.25)}" width="${this.xmm(5)}" height="${this.ymm(0.5)}" fill="#808080" stroke-width="0"/>
    </svg>`;

    const bgText = [
      { text: `${stammAbsender.name}\n`, fontSize: 11, bold: true, color: "#ffffff" },
      { text: `\n`, fontSize: 11, bold: true, color: "#ffffff" },
      { text: `Geschäftsadresse:\n`, fontSize: 11, bold: true, color: "#ffffff" },
      { text: `${stammAbsender.strasse} ${stammAbsender.hausnummer}\n`, fontSize: 10, bold: false, color: "#ffffff" },
      { text: `${stammAbsender.postleitzahl} ${stammAbsender.ort}\n`, fontSize: 10, bold: false, color: "#ffffff" },
      { text: `${stammAbsender.telefon ? "Fon " + stammAbsender.telefon : ""}\n`, fontSize: 10, bold: false, color: "#ffffff" },
      { text: `${stammAbsender.fax ? "Fax " + stammAbsender.fax : ""}\n`, fontSize: 10, bold: false, color: "#ffffff" },
      { text: `${stammAbsender.email ? stammAbsender.email : ""}\n`, fontSize: 10, bold: false, color: "#ffffff" },
      { text: `${stammAbsender.website ? stammAbsender.website : ""}\n`, fontSize: 10, bold: false, color: "#ffffff" },
      { text: `\n`, fontSize: 10, bold: false, color: "#ffffff" },
      { text: `Bankverbindungen:\n`, fontSize: 11, bold: true, color: "#ffffff" },
      { text: `${stammAbsender.bank}\n`, fontSize: 10, bold: false, color: "#ffffff" },
      { text: `\n`, fontSize: 10, bold: false, color: "#ffffff" },
      { text: `IBAN\n`, fontSize: 10, bold: false, color: "#ffffff" },
      { text: `${stammAbsender.iban}\n`, fontSize: 10, bold: false, color: "#ffffff" },
      { text: `BIC\n`, fontSize: 10, bold: false, color: "#ffffff" },
      { text: `${stammAbsender.bic}\n`, fontSize: 10, bold: false, color: "#ffffff" }
    ];

    return page => {
      return [
        {
          svg: bgSvg,
          width: 1000,
          height: 841.5
        },
        {
          image: stammAbsender.icon,
          fit: [this.cx(20), this.cy(10)],
          width: this.cx(20),
          height: this.cy(10),
          absolutePosition: { x: this.cx(77.5), y: this.cy(12.5) }
        },
        {
          text: bgText,
          absolutePosition: { x: this.cx(77.5), y: this.cy(60) }
        },
      ];
    };
  }

  public generateTeilnehmerliste(event: IVeranstaltung, tnList: Anmeldung[]): TCreatedPdf{
    const now = new Date();
    const cx = this.cx, cy = this.cy, xmm = this.xmm, ymm = this.ymm;

    const pdf = createPdf({
      footer: this.generateFooterTeilnehmerliste(),
      content: [
        { text: `Liste der Teilnehmerinnen und Teilnehmer (in Blockschrift ausfüllen)`, fontSize: 20, bold: true, color: "#000", margin: [0, cx(2), 0, cx(2)], style: { alignment: 'center' } },
        {
          table: {
            headerRows: 0,
            dontBreakRows: true,
            widths: [cx(25), '*'],
            body: [[
              {
                text: "Veranstaltung",
                fontSize: 10, bold: true, color: "#000", style: { alignment: 'left' },
              },
              {
                text: `${event.titel}`,
                fontSize: 10, bold: false, color: "#000", style: { alignment: 'left' },
              }
            ],[
              {
                text: "Ort der Kinder- u. Jugendfreizeit",
                fontSize: 10, bold: true, color: "#000", style: { alignment: 'left' },
              },
              {
                text: `${event.ort}`,
                fontSize: 10, bold: false, color: "#000", style: { alignment: 'left' },
              }
            ],[
              {
                text: "Datum der Freizeit",
                fontSize: 10, bold: true, color: "#000", style: { alignment: 'left' },
              },
              {
                text: `${this.timePickerService.toDateString(event.anfangsdatum)} ${event.enddatum == event.anfangsdatum ? "": ("- "+this.timePickerService.toDateString(event.enddatum))}`,
                fontSize: 10, bold: false, color: "#000", style: { alignment: 'left' },
              }
            ]]
          }
        },
        {
          text: "",
          margin: [0, cx(2), 0, cx(5)]
        },
        {
          table: {
            headerRows: 1,
            dontBreakRows: true,
            widths: ['*', cx(7),'*','*'],
            body: [[
              {
                text: "Vor- und Nachname",
                fontSize: 10, bold: true, color: "#000", style: { alignment: 'center' },
              },
              {
                text: "Alter",
                fontSize: 10, bold: true, color: "#000", style: { alignment: 'center' },
              },
              {
                text: "Wohnort",
                fontSize: 10, bold: true, color: "#000", style: { alignment: 'center' },
              },
              {
                text: "Eigenhändige Unterschrift",
                fontSize: 10, bold: true, color: "#000", style: { alignment: 'center' },
              }
            ]].concat(tnList.filter(a => !a.ausgefallen && a.teilnehmer.vorname !== "deleted").map(a => {
              return [
                {
                  text: `${a.teilnehmer.vorname} ${a.teilnehmer.nachname}`,
                  fontSize: 10, bold: false, color: "#000", style: { alignment: 'left' },
                },
                {
                  text: `${this.agePipe.transform(a.teilnehmer.geburtsdatum)}`,
                  fontSize: 10, bold: false, color: "#000", style: { alignment: 'center' },
                },
                {
                  text: `${a.teilnehmer.ort}`,
                  fontSize: 10, bold: false, color: "#000", style: { alignment: 'left' },
                },
                {
                  text: "",
                  fontSize: 10, bold: false, color: "#000", style: { alignment: 'center' },
                }
              ]
            })).concat([1,2,3,4,5].map(i => {
              return [
                {
                  text: ` `,
                  fontSize: 15, bold: false, color: "#000", style: { alignment: 'left' },
                },
                {
                  text: ` `,
                  fontSize: 15, bold: false, color: "#000", style: { alignment: 'center' },
                },
                {
                  text: ` `,
                  fontSize: 15, bold: false, color: "#000", style: { alignment: 'left' },
                },
                {
                  text: " ",
                  fontSize: 15, bold: false, color: "#000", style: { alignment: 'center' },
                }
              ]
            }))
          }
        },
      ],
      pageMargins: [cx(10), cy(7), cx(10), cy(5)]
    });

    return pdf;
  }

}
