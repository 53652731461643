<div class="container pt-3">
    <app-event-edit-mask [event]="event"></app-event-edit-mask>
    <div class="shadow pcBorder p-3 rounded my-5 grayBgColor" *ngIf="event.veranstaltungstyp === '1'">
        <h3 class="text-center primaryFontColor">Einladungen</h3>
        <hr>
        <app-stamm-multi-data-list [partialList]="event.staemme" [fullList]="stammService.getStammListe()"
            [listTitle]="'Einladungen'"></app-stamm-multi-data-list>
    </div>





</div>
<div style="height:55px;"></div>
<div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
  <button class="btn pcButton m-2" (click)="save()">Speichern</button>
</div>
