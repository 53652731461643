<button class="btn" (click)="download()">Excel download</button>
<table class="table">
  <tr>
  <th>Jahr</th>
    <th>VAs</th>
    <th><app-icon [icon]="Icon.VERANSTALTUNGSTYP_LOCAL"></app-icon> VAs</th>
    <th><app-icon [icon]="Icon.VERANSTALTUNGSTYP_REGIO"></app-icon> VAs</th>
    <th><app-icon [icon]="Icon.VERANSTALTUNGSTYP_REGP"></app-icon> VAs</th>
    <th>TNs</th>
    <th><app-icon [icon]="Icon.VERANSTALTUNGSTYP_LOCAL"></app-icon> TNs</th>
    <th><app-icon [icon]="Icon.VERANSTALTUNGSTYP_REGIO"></app-icon> TNs</th>
    <th><app-icon [icon]="Icon.VERANSTALTUNGSTYP_REGP"></app-icon> TNs</th>
    <th>Summe</th>
    <th>Summe <app-icon [icon]="Icon.VERANSTALTUNGSTYP_REGIO"></app-icon></th>
    <th>Summe <app-icon [icon]="Icon.VERANSTALTUNGSTYP_REGP"></app-icon></th>
  </tr>
  <tr *ngFor="let r of statsRows">
    <td>{{r.year}}</td>
    <td>{{r.VAcount}}</td>
    <td>{{r.VA0count}}</td>
    <td>{{r.VA1count}}</td>
    <td>{{r.VA2count}}</td>
    <td>{{r.TNcount}}</td>
    <td>{{r.TN0count}}</td>
    <td>{{r.TN1count}}</td>
    <td>{{r.TN2count}}</td>
    <td><b>{{r.sum}} €</b></td>
    <td>{{r.sum1}} €</td>
    <td>{{r.sum2}} €</td>
  </tr>
</table>
