import {Component, Input, NgZone, OnChanges, OnInit} from '@angular/core';
import {getNutzerSearchCategories, INutzer} from 'src/app/models/INutzer.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ViewUserSettingsComponent} from '../../view-user-settings/view-user-settings.component';
import {FilterEngineService} from 'src/app/service/filter-engine.service';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit, OnChanges {

  @Input() public filterKey!: string;
  @Input() public title: string;
  @Input() public list: INutzer[];

  public filteredList: INutzer[] = [];
  public useUrlBarInSearch = false;
  public categories: any;
  public query = "";

  constructor(
    private ngbModal: NgbModal,
    private filter: FilterEngineService,
    private zone: NgZone
  ) {
    this.categories = getNutzerSearchCategories();
  }

  ngOnInit() {
    this.onFilterChange(this.query);
  }

  ngOnChanges() {
    this.onFilterChange(this.query);
  }


  public onFilterChange(newQuery: string) {
    if (typeof newQuery !== "string") {
      return;
    }

    this.query = newQuery;
    this.filter.filter(newQuery, this.list, this.filterKey).then((filteredList) => this.zone.run(() => {
      this.filteredList = filteredList;
    })).catch(error => console.log('Failed to filter: ', error));
  }

  public setSort(newSortKey: string) {
    const elements = this.query.split(" ");
    const withoutSearch = elements.filter(e => !e.startsWith("$"));
    this.query = withoutSearch.join(" ") + " " + newSortKey;
  }


  public open(nutzer:INutzer){
    const activeModal = this.ngbModal.open(ViewUserSettingsComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.echterNutzer = nutzer;
  }
}
