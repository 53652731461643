<div class="container mt-5">
  <h1 class="text-center primaryFontColor my-3 ">Bedienungsanleitung</h1>
  <p class="text-center">
    Für die Anmeldesoftware gibt es eine Bedienungsanleitung sowie eine zusammenfassende Kurzanleitung.
  </p>
  <div class="row my-3">
    <div class="col-12 col-sm-6 text-center my-3">
      <p class="text-center"><strong>Bedingungsanleitung:</strong><br><a href="../../../assets/Anleitung.pdf" target="_blank">PDF</a></p>
    </div>
    <div class="col-12 col-sm-6 text-center my-3">
      <p class="text-center"><strong>Kurzanleitung:</strong><br><a href="../../../assets/Kurzanleitung.pdf" target="_blank">PDF</a><br><a target="_blank" href="../../../assets/Kurzanleitung.pptx">Microsoft PowerPoint</a></p>
    </div>
  </div>
</div>
