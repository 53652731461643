import { Component, OnInit } from '@angular/core';
import { NutzerService } from '../service/nutzer.service';
import { ToastrService } from 'ngx-toastr';
import { StammService } from '../service/stamm.service';
import { SessionService } from '../service/session.service';
import * as sha1 from 'js-sha1';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {



  public username = "";
  public password = "";
  public email = "";
  public mailIsSending = false;

  constructor(
    private nutzerService: NutzerService,
    private stammService: StammService,
    private toast: ToastrService,
    public sessionService: SessionService
  ) { }

  ngOnInit() {
  }
  public login() {
    if (this.username !== "" && this.password !== "") {
      this.sessionService.login(this.username, sha1(this.password));
      this.password = "";
    } else {
      this.toast.error("Keine gültigen Eingaben bei der Anmeldung!", "Üngültige Eingabe");
    }

  }

  public logout() {
    this.sessionService.logout();
    this.username = "";
  }

  public sendPasswordRecoveryMail() {
    if(this.mailIsSending){
      this.toast.warning("Es wird bereits eine E-Mail gesendet", "Bitte warten");
      return;
    }
    this.mailIsSending = true;
    this.nutzerService.sendPasswordRecoveryMail(this.email).then(() => {
      this.toast.success("Wenn die Adresse " + this.email + " im System hinterlegt ist, wurde jetzt eine E-Mail versendet.", "Passwort Wiederherstellung");
      this.email = "";
      this.mailIsSending = false;
    }
    ).catch(() => {
      this.toast.error("Es ist ein Fehler aufgetreten.", "Passwort Wiederherstellung");
      this.mailIsSending = false;
    });
  }

  public sendUsernameRecoveryMail() {
    if(this.mailIsSending){
      this.toast.warning("Es wird bereits eine E-Mail gesendet", "Bitte warten");
      return;
    }
    this.mailIsSending = true;
    this.nutzerService.sendUsernameRecoveryMail(this.email).then(() => {
        this.toast.success("Wenn die Adresse " + this.email + " im System hinterlegt ist, wurde jetzt eine E-Mail versendet.", "Benutzername Vergessen");
        this.email = "";
        this.mailIsSending = false;
      }
    ).catch(() => {
      this.toast.error("Es ist ein Fehler aufgetreten.", "Benutzername Vergessen");
      this.mailIsSending = false;
    });
  }
}
