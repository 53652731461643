import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NutzerService } from 'src/app/service/nutzer.service';
import { INutzer } from 'src/app/models/INutzer.interface';
import { SessionService } from 'src/app/service/session.service';
import {NgbDateStruct, NgbDate, NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Rolle } from 'src/app/models/Rolle.enum';
import { Gender } from 'src/app/models/gender.enum';
import * as sha1 from 'js-sha1';
import { TimePickerService } from 'src/app/service/time-picker-service.service';

@Component({
  selector: 'app-view-user-settings',
  templateUrl: './view-user-settings.component.html',
  styleUrls: ['./view-user-settings.component.scss']
})
export class ViewUserSettingsComponent implements OnInit {



  private _gebDate: NgbDateStruct;
  public showDelete: boolean = false;
  public currentUser: INutzer;
  public currentRolle: String;
  @Input() public echterNutzer?: INutzer;
  public nutzer: INutzer;
  public Rolle = Rolle;
  public Gender = Gender;
  public Object = Object;
  public pw1 = "";
  public pw2 = "";
  public passwordCheckVisible: boolean;
  public passwordValid: boolean;

  constructor(
    private toast: ToastrService,
    private nutzerServcie: NutzerService,
    private timePickerService: TimePickerService,
    private sessionService: SessionService,
    private activeDialog: NgbModal
  ) {
    this.currentUser = this.sessionService.session.nutzer;
    this.currentRolle = this.currentUser.rolle;
  }

  ngOnInit() {
    this.nutzer = Object.assign({}, this.nutzerServcie.getNutzer(this.echterNutzer.id));
  }

  public async delete() {
    try{
      await this.nutzerServcie.deleteNutzer(this.nutzer);
      this.toast.success("Nutzer erfolgreich gelöscht", "Erfolgreich");
      if(this.nutzer.id === this.sessionService.session.nutzer.id){
        this.sessionService.logout();
        location.reload();
      }
    } catch (error) {
      this.toast.error(error, "Fehler beim Löschen");
    }
  }

  public async saveData(type: number) {
    switch (type) {
      case 0:
        this.nutzerServcie.updateNutzerBasics(this.nutzer).
          then(msg => {
            this.toast.success(msg, "Erfolgreich");
            this.activeDialog.dismissAll()}).
          catch(msg => this.toast.error(msg, "Fehler"));
        break;
      case 1:
        if (this.pw1 !== this.pw2) {
          this.toast.error("Passwörter sind nicht gleich", "Fehler");
          return;
        }
        if (this.pw1 && !this.passwordValid) {
          this.toast.error("Passwort entspricht nicht den Sicherheitsanforderungen", "Fehler");
          return;
        }
        if (this.pw1) {
          this.nutzer.passworthash = sha1(this.pw1);
        }
        this.nutzerServcie.updateNutzerCredentials(this.nutzer).
          then(msg => {
          this.toast.success(msg, "Erfolgreich");
          this.activeDialog.dismissAll();
        }).
          catch(msg => this.toast.error(msg, "Fehler"));
        break;
      default:
        this.toast.error("Fehler beim speichern", "Fehler");
        break;
    }
  }

  public set gebDate(newDate: NgbDateStruct) {
    this._gebDate = newDate;
    this.nutzer.geburtsdatum = this.timePickerService.structToDate(newDate);
  }

  public get gebDate(): NgbDateStruct {
    if (!this._gebDate) {
      this._gebDate = this.timePickerService.dateToStruct(this.nutzer.geburtsdatum);
    }

    return this._gebDate;
  }
  public async close(){
    this.activeDialog.dismissAll();
  }

  public isDialog():boolean{
    return this.activeDialog.hasOpenModals();
  }
}
