import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IStamm } from 'src/app/models/IStamm.interface';

@Component({
  selector: 'app-stamm-multi-data-list',
  templateUrl: './stamm-multi-data-list.component.html',
  styleUrls: ['./stamm-multi-data-list.component.scss']
})
export class StammMultiDataListComponent implements OnInit, OnChanges {

  @Input()
  public listTitle: string;
  @Input()
  public fullList: IStamm[];
  @Input()
  public partialList: IStamm[];
  @Input()
  public required? : boolean = false;
  public addStamm = (stamm) => {this.partialList.push(stamm); this.filter()};
  public removeStamm = (stamm) => {this.partialList.splice(this.partialList.indexOf(stamm), 1); this.filter()};
  public fullListFiltered: IStamm[];

  constructor() {}

  ngOnInit() {
    this.fullListFiltered = this.fullList.filter(e=>this.partialList.indexOf(e) === -1)
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.fullList){
      this.filter();
    }
  }

  private filter(){
    this.fullListFiltered = this.fullList.filter(e=>this.partialList.indexOf(e) === -1)
  }

  public stammToText(stamm: IStamm) {
    return stamm.name + (stamm.title ? (" (" + stamm.title + ")") : "");
  }

  public stammToHtml(stamm: IStamm) {
    const txt = stamm.name + (stamm.title ? (" (" + stamm.title + ")") : "");
    return `<img class="mr-2" height="32" width="32" src="${stamm.icon}">${txt}`;
  }

  public stammToId(stamm: IStamm) {
    return stamm.id;
  }
}
