import { Component, OnInit } from '@angular/core';
import { IStatistics } from 'src/app/models/IStatistics.interface';
import { VeranstaltungenService } from 'src/app/service/veranstaltungen.service';
import { ExportService } from 'src/app/service/export-service.service';
import {Icon} from "../../utils/icon/icon.component";

interface StatsRow{
  year: number,
  VAcount: number,
  VA0count: number,
  VA1count: number,
  VA2count: number,
  TNcount: number,
  TN0count: number,
  TN1count: number,
  TN2count: number,
  sum: number,
  sum1: number
  sum2: number,
}


@Component({
  selector: 'app-view-stats',
  templateUrl: './view-stats.component.html',
  styleUrls: ['./view-stats.component.scss']
})
export class ViewStatsComponent implements OnInit {

  public readonly Icon = Icon;
  public stats: IStatistics[];
  public statsRows: StatsRow[] = [];

  constructor(
    public eventService: VeranstaltungenService,
    public exportService: ExportService
  ) { }

  async ngOnInit() {
    this.stats = await this.eventService.getStats();
    for(let s of this.stats){
      const year = new Date(s.anfangsdatum).getFullYear();
      let row = this.statsRows.find(r => r.year === year);
      if(!row){
        row =  {
          year,
          VAcount: 0,
          VA1count: 0,
          VA2count: 0,
          VA0count: 0,
          TNcount: 0,
          TN1count: 0,
          TN2count: 0,
          TN0count: 0,
          sum: 0,
          sum2: 0,
          sum1:0
        }
        this.statsRows.push(row);
      }

      let tnCount = s.teilgenommen+s.ausgefallen;
      row.TNcount += tnCount;
      row.VAcount ++;

      switch(s.veranstaltungstyp){
        case "0":
          row.VA0count ++
          row.TN0count += tnCount;
        break;
        case "1":
          row.VA1count ++
          row.TN1count += tnCount;
          row.sum1 = row.TN1count * 0.5;
          break;
        case "2":
          row.VA2count ++
          row.TN2count += tnCount;
          row.sum2 = row.TN2count;
          break;
      }
      row.sum = row.sum1 + row.sum2;
    }
    this.statsRows.sort((a, b) => a.year - b.year);
  }

  public download(){
    this.exportService.export(this.stats);
  }

}
