import { Component, OnInit } from '@angular/core';
import { IStamm } from 'src/app/models/IStamm.interface';
import { NutzerService } from 'src/app/service/nutzer.service';
import { INutzer } from 'src/app/models/INutzer.interface';
import { StammService } from 'src/app/service/stamm.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {Icon} from "../../../utils/icon/icon.component";

@Component({
  selector: 'app-new-stamm',
  templateUrl: './new-stamm.component.html',
  styleUrls: ['./new-stamm.component.scss']
})
export class NewStammComponent implements OnInit {
  public readonly Icon = Icon;
  public nutzer: INutzer[];
  public stamm:IStamm = {
    id: -1,
    mitglieder: [],
    stammesleiter: [],
    title: "",
    hausnummer: "",
    icon: StammService.DEFAULT_ICON,
    ort: "",
    postleitzahl: null,
    strasse:"",
    name: "",
    land: "Deutschland",
    website: "",
    email: "",
    telefon: "",
    fax: "",
    kontoinhaber: "",
    iban: "",
    bic: "",
    bank: "",
    alt_rechnung_empfaenger: "",
    alt_rechnung_hausnummer: "",
    alt_rechnung_postleitzahl: "",
    alt_rechnung_strasse: "",
    alt_rechnung_ort: "",
    alt_rechnung_land: ""
  };

  public readonly fields = [
    {key: "name", label: "Name", type:"text", placeholder: "Gemeindepfadfinder Weding", pflichtfeld: true},
    {key: "title", label: "Titel", type:"text", placeholder: "Die Adler", pflichtfeld: false},
    {key: "strasse", label: "Straße", type:"text", placeholder: "Musterstraße", pflichtfeld: true},
    {key: "hausnummer", label: "Hausnummer", type:"text", placeholder: "1a", pflichtfeld: true},
    {key: "ort", label: "Ort", type:"text", placeholder: "Musterstadt", pflichtfeld: true},
    {key: "postleitzahl", label: "Postleitzahl", type:"number", placeholder: "2xxxx", pflichtfeld: false},
    {key: "land", label: "Land", type:"text", placeholder: "Deutschland", pflichtfeld: true},
    {key: "website", label: "Webseite", type:"text", placeholder: "http://example.com", pflichtfeld: false},
    {key: "email", label: "E-Mail", type:"text", placeholder: "adler@regp.de", pflichtfeld: true},
    {key: "telefon", label: "Telefon", type:"text", placeholder: "+49 4630 ...", pflichtfeld: false},
    {key: "fax", label: "Fax", type:"text", placeholder: "+49 4630 ...", pflichtfeld: false},
    {key: "kontoinhaber", label: "Kontoinhaber", type:"text", placeholder: "Die Adler", pflichtfeld: false},
    {key: "iban", label: "IBAN", type:"text", placeholder: "DEXX XXXX XXXX XXXX XXXX XX", pflichtfeld: false},
    {key: "bic", label: "BIC", type:"text", placeholder: "ABCDEFGHIJK", pflichtfeld: false}
  ]
  public readonly fieldsAltRechnung = [
    {key: "alt_rechnung_empfaenger", label: "Empfänger", type:"text", placeholder: "", pflichtfeld:false},
    {key: "alt_rechnung_hausnummer", label: "Hausnummer", type:"text", placeholder: "", pflichtfeld:false},
    {key: "alt_rechnung_postleitzahl", label: "Postleitzahl", type:"text", placeholder: "", pflichtfeld:false},
    {key: "alt_rechnung_strasse", label: "Straße", type:"text", placeholder: "", pflichtfeld:false},
    {key: "alt_rechnung_ort", label: "Ort", type:"text", placeholder: "", pflichtfeld:false},
    {key: "alt_rechnung_land", label: "Land", type:"text", placeholder: "", pflichtfeld:false}
  ]

  constructor(private nutzerService: NutzerService,
    private toast: ToastrService,
    private activeModal: NgbActiveModal,
    private stammService: StammService) {
    	this.nutzer = nutzerService.getNutzerListe();
  }

  ngOnInit() {
  }

  public send(){
    this.stammService.createStamm(this.stamm).
    then(msg=>{
      this.toast.success(msg, "Erfolgreich");
      this.activeModal.close(true);
    }).
    catch(msg => this.toast.error(msg, "Fehler"));

  }

  public addStammesleiter(nutzer: INutzer){
    this.stamm.stammesleiter.push(nutzer);
  }

  public removeStammesleiter(nutzer: INutzer){
    this.stamm.stammesleiter.splice(this.stamm.stammesleiter.indexOf(nutzer), 1);
  }

  // TODO prüfen, ob wirklich ein Bild ausgewählt wurde!!!!!!!
  public fileUpload($event:any){
    const fileName = $event.target.files[0].name;
    $event.target.nextSibling.innerHTML = fileName;

    const file:File = $event.target.files[0];
    const myReader:FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.stamm.icon = myReader.result.toString();
    };
    myReader.readAsDataURL(file);

  }
}
