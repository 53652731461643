import {Component, Input, OnInit} from '@angular/core';
import {getTeilnehmerRegisterFields, RegisterField, RegisterLine} from "../../../models/ITeilnehmer.interface";
import {IVeranstaltung} from "../../../models/IVeranstaltung.interface";
import {VeranstaltungenService} from "../../../service/veranstaltungen.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-pflichtfelder',
  templateUrl: './pflichtfelder.component.html',
  styleUrls: ['./pflichtfelder.component.scss']
})
export class PflichtfelderComponent implements OnInit {
  @Input() public eventId!: number;
  public registerForm: Array<RegisterField | RegisterLine> = getTeilnehmerRegisterFields().filter(r => r.pflichtfeldMoeglich);
  public event: IVeranstaltung;

  constructor(
    private eventService: VeranstaltungenService,
    private toastrService: ToastrService
  ) {

  }

  ngOnInit(): void {
    this.event = this.eventService.getEvent(this.eventId);
    this.registerForm.forEach(rf => {
      if(RegisterLine.isRegisterLine(rf)){
        //Mir egal
      } else {
        rf.selected = new RegExp("(^|;)" + rf.key + "($|;)").test(this.event.pflichtfelder || "");
      }
    });
  }

  public async save(){
    this.event.pflichtfelder = this.registerForm.reduce((sum, current) => {
      if(RegisterLine.isRegisterLine(current)){
        return sum;
      }
      if(!current.selected){
        return sum;
      }
      return sum + current.key + ";";
    }, "");

    try {
      await this.eventService.updateVeranstaltung(this.event);
      this.toastrService.success("Speichern erfolgreich");
    } catch(e){
      console.error(e);
      this.toastrService.error(e, "Fehler beim Speichern");
    }
  }

}
