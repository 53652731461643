import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { VeranstaltungenService } from 'src/app/service/veranstaltungen.service';
import { StammService } from 'src/app/service/stamm.service';
import { IStamm } from 'src/app/models/IStamm.interface';
import { IVeranstaltung } from 'src/app/models/IVeranstaltung.interface';

@Component({
  selector: 'app-rechnungsnummer',
  templateUrl: './rechnungsnummer.component.html',
  styleUrls: ['./rechnungsnummer.component.scss']
})
export class RechnungsnummerComponent implements OnChanges {

  @Input() 
  public rechnungsnummer: string;
  public von: IStamm;
  public an: IStamm;
  public event: IVeranstaltung;
  public number: string;
  public error: boolean;

  constructor(
    private eventService: VeranstaltungenService,
    private stammService: StammService
  ) { }

  ngOnChanges() {
    const result = /^(\d+)-(\d+)-(\d+)-(\d+)$/.exec(this.rechnungsnummer);
    if(result){
      this.von    = this.stammService.getStamm(parseInt(result[1]));
      this.an     = this.stammService.getStamm(parseInt(result[2]));
      this.event  = this.eventService.getEvent(parseInt(result[3]));
      this.number = result[4];
      this.error = false;
    } else{
      this.error = true;
    }   
  }

}
