<div class="container pt-3">
  <h4 class="primaryFontColor text-center">{{title}}</h4>
  <div class="clearfix">
    <p>Über die Importfunktion können neue Daten ins System importiert werden. Bitte verwenden Sie für den Import die Importvorlage,
      die Sie <a href="{{exampleDownloadPath}}">hier</a> downloaden können. Damit der Import funktioniert, darf die erste Zeile nicht verändert werden!
      <br>Achtung! Sollten Sie ID's verwenden, wird den Teilnehmer überschrieben.
    </p>
    <a class="btn scButton float-right" href="{{exampleDownloadPath}}">Vorlage Downloaden</a>
  </div>
  <hr>
  <p><strong>Datentypen im Import</strong></p>
  <p>Im System gibt es verscheiden Datenfelder. Daher muss im Import drauf geachtet werden, dass die richten Inhalte in den jeweiligen Felder eingetragen werden. Folgende Datenfelder gibt es:</p>
  <ul>
  <li *ngFor="let feld of importFelder" [ngSwitch]="feld['type']">
    <span >
      <span *ngSwitchCase="'Text'">Text - In Textfelder können Text eingegeben werden.</span>
      <span *ngSwitchCase="'Datum'">Datum - In ein Datumsfeld muss ein Excel-Kurz-Datum eingetragen werden.</span>
      <span *ngSwitchCase="'Zahlen'">Zahlen - In ein Zahlenfeld dürfen nur Zahlen eingetragen werden.</span>
      <span *ngSwitchCase="'Status'">Status - In ein Statusfeld darf nur "=WAHR" oder "=FALSCH" eingetragen werden.</span>
    </span>
    <i> ({{feld['felder']}})</i></li>
  </ul>
  <hr>
  <p><strong>Import starten</strong></p>
  <p>Um den Import zu starten muss lediglich eine Datei ausgewählt werden. Nach der Auswahl wir der Import sofort gestartet.
    Bitte löschen Sie vor dem Start die Beispielzeilen aus dem Import! <br>
    Sollten beim Import Fehler auftreten, wird dies durch ein <i class="fas fa-times-circle fa-fw text-danger"></i> in der Importliste angezeigt.<br>
    Alle Zeilen mit dem <i class="fas fa-check-circle fa-fw primaryFontColor"></i> Symbol wurde erfolgreich importiert.<br>
    Sollten Sie den Import erneut anstoßen, müssen Sie vorher alle erfolgreichen Importe aus der Excelliste
    entfernen, damit diese nicht erneut importiert werden!
  </p>
  <hr>
  <p><strong>Optionen</strong></p>
  <!-- Optionen -->
  <div class=" input-group my-2" *ngFor="let option of options | keyvalue">
    <div class="input-group-append w-25">
      <span class="input-group-text w-100">{{option.key}}</span>
    </div>
    <div class="w-75">
      <select class="form-control" (change)="optionChange(option.key, $event.target.value)">

        <option *ngFor="let o of option.value" value="{{o}}">{{o}}</option>

      </select>
    </div>
  </div>
 <!-- Import Button -->
  <div class="input-group my-2">
    <div class="input-group-append">
      <span class="input-group-text">Datei</span>
    </div>
    <div class="custom-file">
      <input type="file" class="custom-file-input"
         name="xlfile" id="upload" (change)="handleFileUpload($event.target.files)">>
      <label class="custom-file-label" id="upload" for="customFile">Datei auswählen</label>
    </div>
  </div>

  <hr>
  <p *ngIf="importStatus.length !== 0"><strong>Importliste</strong></p>
  <!-- Ergebnisse -->
  <ul>
    <li *ngFor="let status of importStatus">
      <span [ngSwitch]=status.status>
        <i *ngSwitchCase="'ok'" class="fas fa-check-circle fa-fw primaryFontColor"></i>
        <i *ngSwitchCase="'fehler'" class="fas fa-times-circle fa-fw text-danger"></i>
        <i *ngSwitchCase="'wait'" class="fas fa-circle-notch fa-fw"></i>
        <i *ngSwitchCase="'loading'" class="fas fa-circle-notch fa-spin fa-fw"></i>
      </span>
      <span>{{status.name}}</span>
      <span>{{status.msg}}</span>
    </li>
  </ul>

  <button class="btn scButton float-right ml-3 mb-3 " (click)="close()">Schließen</button>
</div>
