<div class="root">

  <app-filter [query]="query" [actionButtons]="actionButtons" (changeText)="onFilterChange($event)" [categories]="categories"></app-filter>

  <div class="container">
    <ng-container *ngFor="let event of events | paginate: { itemsPerPage: 10, currentPage: currentPage }">
      <div class="seperator" *ngIf="event.enddatum < old">
        <h3 class="text-center">Alte Veranstaltungen</h3>

      </div>
      <app-item-event [class.old]="event.enddatum < old" [event]="event" ></app-item-event>
    </ng-container>
  </div>
  <pagination-controls autoHide="true" previousLabel="" nextLabel="" (pageChange)="currentPage = $event; scrollUp()"></pagination-controls>
</div>
