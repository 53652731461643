<div class="container pt-2">
    <h2 class="text-center primaryFontColor">Anmeldung Drucken</h2>

    <p class="text-center text-lg-left">Über die folgende Auswahl kann entschieden werden, welches Stamm-Logo auf der Anmeldung erscheinen wird:</p>
    <select class="form-control my-2" [(ngModel)]="selectedStamm">
        <option *ngFor="let s of nutzerStaemme" [ngValue]="s">{{s.name + (s.title?(" (" + s.title + ")"):"")}}</option>
    </select>
  <div class="secondaryBgColor rounded d-flex justify-content-center justify-content-lg-end align-items-center">
    <div class="p-2">Aufgrund aktueller rechtlicher Unklarheiten, sind die Anmeldebögen zurzeit nicht Verfügbar. Weitere Information können beim Vorstand des REGP eingeholt werden.</div>
<!--    <button class="btn pcButton m-2" (click)="genPDF()">PDF (Blanko) erzeugen</button>-->
    <button class="btn pcButton m-2" disabled="disabled">PDF (Blanko) erzeugen</button>
<!--    <button class="btn pcButton m-2" (click)="genPdfFilled()">PDF (Vorausgefüllt) erzeugen</button>-->
    <button class="btn pcButton m-2" disabled="disabled">PDF (Vorausgefüllt) erzeugen</button>
  </div>


    <div *ngIf="canEditEvent">
        <hr>
        <p class="text-center text-lg-left">Auf die Anmeldung sollen folgende Felder gedruckt werden:</p>
        <ul class="list-group my-2">
            <li *ngFor="let rf of registerForm" class="list-group-item">
                <span *ngIf="rf.size" [class]="rf.size === 'lg' ? 'font-weight-bold' : 'font-weight-light'">
                    {{rf.msg}}
                </span>
                <span *ngIf="!rf.size">
                    <input type="checkbox" [disabled]="rf.print === 'ja' || rf.pflichtfeld" [(ngModel)]="rf.selected" style="transform: scale(2);" class="mx-2">
                    {{rf.title}}
                    <span class="primaryFontColor float-right" *ngIf="rf.pflichtfeld">(Veranstaltungspflichtfeld)</span>
                </span>
            </li>
        </ul>
      <div style="height:55px;"></div>
      <div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
        <button class="btn pcButton m-2" (click)="save()">Speichern</button>
      </div>
    </div>
</div>
