import { Component, OnInit, Input, Output, ElementRef } from '@angular/core';
import { ITeilnehmer } from 'src/app/models/ITeilnehmer.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-anmeldung-select-tn',
  templateUrl: './anmeldung-select-tn.component.html',
  styleUrls: ['./anmeldung-select-tn.component.scss']
})
export class AnmeldungSelectTnComponent implements OnInit {

  @Input() tns!: ITeilnehmer[];
  selectedTns: ITeilnehmer[] = [];

  public checkboxStates: boolean[] = [];

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.tns.forEach(() => this.checkboxStates.push(false));
  }

  public onCbChange($event, tn) {
    const oldIndex = this.selectedTns.indexOf(tn);
    if(oldIndex !== -1){
      this.selectedTns.splice(oldIndex, 1);
    }

    if($event.currentTarget.value === "on"){
      this.selectedTns.push(tn);
    }
  }

  public selectAll() {
    this.selectedTns.length = 0;
    this.selectedTns.push(...this.tns);

    this.checkboxStates = this.checkboxStates.map(() => true);
  }

  public selectNone() {
    this.selectedTns.length = 0;

    this.checkboxStates = this.checkboxStates.map(() => false);
  }

  public cancel() {
    this.activeModal.dismiss();
  }

  public accept() {
    this.activeModal.close(this.selectedTns);
  }
}
