import {Component, Input, OnInit} from '@angular/core';
import {IVeranstaltung} from '../../../models/IVeranstaltung.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { DetailsEventComponent } from '../../details-event/details-event.component';
import { Router } from '@angular/router';
import {Icon} from "../../../utils/icon/icon.component";

@Component({
  selector: 'app-item-event',
  templateUrl: './item-event.component.html',
  styleUrls: ['./item-event.component.scss']
})
export class ItemEventComponent implements OnInit {
  @Input() event?: IVeranstaltung;
  get typeIcon(){
    switch(this.event.veranstaltungstyp){
      case "0": return Icon.VERANSTALTUNGSTYP_LOCAL;
      case "1": return Icon.VERANSTALTUNGSTYP_REGIO;
      case "2": return Icon.VERANSTALTUNGSTYP_REGP;
      default: return Icon.VERANSTALTUNGSTYP_LOCAL;
    }
  }

  constructor(private ngbModal: NgbModal, private router: Router) { }

  ngOnInit() {

  }

  public open() {
    if(this.event === undefined){
      console.warn("Failed to open EventDetails Popup, event is undefined");
      return;
    }

    const activeModal = this.ngbModal.open(DetailsEventComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.id = this.event.id;
  }

  public showDetails(id:number){
    this.router.navigate(['eventDetails/'+id]);
  }

  isHistoric() {
    let toady = new Date();
    toady.setHours(0);
    toady.setMinutes(0);
    toady.setSeconds(0);
    return this.event.enddatum < toady;
  }
}
