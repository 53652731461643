import { Pipe, PipeTransform } from '@angular/core';
import { IStamm } from '../models/IStamm.interface';

@Pipe({
  name: 'stammName'
})
export class StammNamePipe implements PipeTransform {

  transform(stamm: IStamm): string {
    if(stamm.name && stamm.title){
      return stamm.name + " (" + stamm.title +")";
    }
    if(stamm.name){
      return stamm.name;
    }
    if(stamm.title){
      return stamm.title;
    }

    return "---";
  }

}
