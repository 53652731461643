<div class="container text-center" >
  <div class="p-lg-3 p-2">
    <div class="rounded shadow pcBorder p-1 p-lg-3">
      <div class="d-flex">
        <div class="d-flex flex-fill justify-content-center flex-column">
          <h3 class="primaryFontColor">Angemeldete Personen für</h3>
          <h4 class="primaryFontColor">{{event.titel}}</h4>
        </div>
        <div class="d-flex ml-2 justify-content-center">
          <div class="secondaryBgColor rounded shadow align-self-center p-3">
            {{event.teilnehmer.length}} <span class="d-none d-md-inline">/</span><span class="d-inline d-md-none"><br>-<br></span> {{event.max_teilnehmeranzahl}}
          </div>
        </div>
      </div>

    </div>
    <app-filter [query]="query" [actionButtons]="actionButtons" (changeText)="onFilterChange($event)"
                [categories]="categories" [useUrlBar]="useUrlBarInSearch"></app-filter>


    <div *ngIf="!loading" id="content">
      <div class="rounded shadow pcBorder p-3 my-3 d-lg-block d-none" *ngFor="let mapping of sortetStaemme">
        <div class="d-flex sticky-top whiteBackground border-bottom pb-3">
          <div class="d-flex iconHeader align-self-center ml-5 justify-content-center">
            <img src="{{mapping.icon}}" class="stammIcon "/>
          </div>
          <div class="d-flex flex-fill align-self-center mx-2 text-center justify-content-center titleHeader">{{mapping | stammName}}</div>
          <div class="d-flex justify-content-center align-self-center">
            <div class="secondaryBgColor rounded shadow align-self-center p-3">
              {{anmeldungen.get(mapping).length}} / {{mapping.mitglieder.length}}
            </div>
            <button class="btn scButton secondaryBgColor rounded shadow align-self-center p-3 ml-2 collapsed" data-toggle="collapse" attr.data-target="#col{{mapping.id}}">
              <i class="fas fa-caret-down"></i>
              <i class="fas fa-caret-up"></i>
            </button>
          </div>

        </div>
        <div class="collapse" id="col{{mapping.id}}">
          <table class="table text-center" >
            <thead class="">
            <tr class="text-center border-top-0 ">
              <th class="sort-th" (click)="setSort('$nachname')" colspan="2">Name</th>
              <th class="sort-th" (click)="setSort('$geburtsdatum')">Alter</th>
              <th>Preis</th>
              <th><span ngbTooltip="Ist der Teilnehmer Mitarbeiter in der Veranstaltung">Mitarbeiter</span></th>
              <th><span ngbTooltip="Ist ein Teilnehmer nicht zur Veranstaltung erschienen.">Ausgefallen</span></th>
              <th>Aktion</th>
            </tr>
            </thead>
            <tr *ngFor="let an of anmeldungen.get(mapping)">
              <td>{{an.teilnehmer.vorname}} {{an.teilnehmer.nachname}}</td>
              <td><app-icon [icon]="Icon.MITARBEITERSTATUS" [disabled]="!an.teilnehmer.mitarbeiterstatus"></app-icon> </td>
              <td>{{an.teilnehmer.geburtsdatum | ageFilter}}</td>
              <td>
                <div *ngIf="!an.edit" class="preisDiv rounded" (click)="an.edit = true"
                     ngbTooltip="Klicken zum Bearbeiten">
                  {{an.preis || 0 | number:'1.2-2'}} €
                </div>
                <input type="number" class="inputPreis" [(ngModel)]="an.preis" min="0" step="0.01" *ngIf="an.edit">
              </td>
              <td>
                <label class="checkboxContainer" *ngIf="an.mitarbeiter !== null">
                  <input (change)="an.preis = an.mitarbeiter? event.mitarbeiter_preis:event.preis; an.edit = true"
                         type="checkbox" [(ngModel)]="an.mitarbeiter">
                  <span class="checkmark"></span>
                </label>
              </td>
              <td>
                <label class="checkboxContainer" *ngIf="an.ausgefallen !== null">
                  <input (change)="an.preis = an.ausgefallen? event.ausfallgebuehr:event.preis; an.edit = true"
                         type="checkbox" [(ngModel)]="an.ausgefallen">
                  <span class="checkmark"></span>
                </label>
              </td>
              <td>
                <button class="btn scButton" (click)="openDetails(an.teilnehmer)"><i class="fa fa-eye"></i></button>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!-- Mobile -->
      <div class="rounded shadow pcBorder px-2 pb-2 my-2 d-block d-lg-none" *ngFor="let mapping of sortetStaemme">
        <div class="d-flex py-2 sticky-top whiteBackground justify-content-start border-bottom">
          <div class="d-flex justify-content-start align-self-center"><img src="{{mapping.icon}}" class="stammIcon "/></div>
          <div class="d-flex flex-fill mx-2 align-self-center"><b>{{mapping | stammName}}</b></div>
          <div class="d-flex justify-content-center">
            <div class="secondaryBgColor rounded shadow align-self-center p-2">
              {{anmeldungen.get(mapping).length}} / {{mapping.mitglieder.length}}
            </div>
            <button class="btn scButton secondaryBgColor rounded shadow align-self-center p-3 ml-2 collapsed" data-toggle="collapse" attr.data-target="#mob{{mapping.id}}">
              <i class="fas fa-caret-down"></i>
              <i class="fas fa-caret-up"></i>
            </button>
          </div>
        </div>


        <div class="collapse" id="mob{{mapping.id}}" *ngFor="let an of anmeldungen.get(mapping); last as isLast">
        <div >
          <div class="d-flex align-items-center">
            <div class="mr-auto">{{an.teilnehmer.vorname}} {{an.teilnehmer.nachname}}</div>
            <div class="mr-2"><app-icon [icon]="Icon.MITARBEITERSTATUS" [disabled]="!an.teilnehmer.mitarbeiterstatus"></app-icon>
            </div>
            <div class="alterDivMobile">{{an.teilnehmer.geburtsdatum | ageFilter}} Jahre</div>
          </div>
          <div class="d-flex align-items-center mt-2">
            <div class="mr-auto" *ngIf="!an.edit">{{an.preis || 0 | number:'1.2-2'}} € <a (click)="an.edit = true"
                                                                                          class=""><i
              class="far fa-edit"></i></a></div>
            <div class="mr-auto"><input type="number" inputmode="decimal" class="inputPreis" [(ngModel)]="an.preis"
                                        min="0" step="0.01" *ngIf="an.edit"></div>
            <div class="mr-1" ngbTooltip="Mitarbeiter"  *ngIf="an.mitarbeiter !== null">
              <button class="btn scButton" ngbTooltip="Mitarbeiter" (click)=" an.mitarbeiter = true;an.preis = an.mitarbeiter? event.mitarbeiter_preis:event.preis;" *ngIf="!an.mitarbeiter">M</button>
              <button class="btn pcButton" ngbTooltip="Mitarbeiter" (click)=" an.mitarbeiter = false;an.preis = an.mitarbeiter? event.mitarbeiter_preis:event.preis;" *ngIf="an.mitarbeiter === true">M</button>
            </div>
            <div class="mr-1" ngbTooltip="Ausgefallen">
              <button class="btn scButton" ngbTooltip="Ausgefallen" (click)=" an.ausgefallen = true;an.preis = an.ausgefallen? event.mitarbeiter_preis:event.preis;" *ngIf="!an.ausgefallen">A</button>
              <button class="btn pcButton" ngbTooltip="Ausgefallen" (click)=" an.ausgefallen = false;an.preis = an.ausgefallen? event.mitarbeiter_preis:event.preis;" *ngIf="an.ausgefallen === true">A</button>
            </div>

            <button class="btn scButton" (click)="openDetails(an.teilnehmer)"><i class="fa fa-eye"></i></button>

          </div>

        </div>
          <hr *ngIf="!isLast"/>
        </div>
      </div>

    </div>

    <div *ngIf="loading">Laden, bitte warten...</div>
  </div>
  <div style="height:55px;"></div>
  <div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
    <button *ngIf="!loading" class="btn pcButton m-2" (click)="savePrices()">Speichern</button>
  </div>

</div>
