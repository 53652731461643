import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ProductionService } from './production.service';

@Injectable({
  providedIn: 'root'
})
export class EventQueueService {

  private msg: BehaviorSubject<string> = new BehaviorSubject("init");

  private readonly observable = this.msg.asObservable();

  constructor(private toastr: ToastrService) {
  }

  public getObservable(): Observable<string>{
    return this.observable;
  }

  public fireEvent(msg: string){
    if(ProductionService.IS_DEVELOP){
      this.toastr.warning("EventQueueService: NewEvent - " + msg);
    }
    console.log("EventQueueService: NewEvent - " + msg);
    this.msg.next(msg);
  }
}
