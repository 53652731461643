import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { SessionService } from 'src/app/service/session.service';
import { IVeranstaltung } from 'src/app/models/IVeranstaltung.interface';
import { Rolle } from 'src/app/models/Rolle.enum';
import { EventQueueService } from 'src/app/service/event-queue-service.service';


@Component({
  selector: 'app-detail-navigation',
  templateUrl: './detail-navigation.component.html',
  styleUrls: ['./detail-navigation.component.scss']
})
export class DetailNavigationComponent implements OnChanges {

  @Output() public selectedElement = new EventEmitter<string>();
  @Input() public veranstaltung: IVeranstaltung;


  public options = [
    {
      icon: "fa fa-eye",
      name: "Details",
      key: "Detail",
      needPermission: false
    },{
      icon: "fa fa-edit",
      name: "Bearbeiten",
      key: "Edit",
      needPermission: true
    },{
      icon: "fa fa-star-of-life",
      name: "Pflichtfelder",
      key: "Pflichtfelder",
      needPermission: true
    },{
      icon: "fas fa-user-check",
      name: "Teilnehmer anmelden",
      key: "Anmelden",
      needPermission: false
    },{
      icon: "fas fa-user-lock",
      name: "Angemeldete Teilnehmer",
      key: "Angemeldet",
      needPermission: true
    },{
      icon: "fas fa-print",
      name: "Anmeldung drucken",
      key: "Drucken",
      needPermission: false
    },{
      icon: "fas fa-file-invoice-dollar",
      name: "Rechnungen",
      key: "Rechnung",
      needPermission: true
    },
  ];

  public currentElement = "";
  public isVAM = false;
  constructor(
    private sessionService: SessionService,
    private eventQueue: EventQueueService
  ) {
    this.selectedElement.subscribe((elem) => {
      this.currentElement = elem;
      console.log("Selected: " + elem);
    });

    this.eventQueue.getObservable().subscribe(msg => {
      if (msg === "initDone" && this.veranstaltung) {
        const currentPermission = this.sessionService.session.currentPermission;
        const isAdmin = currentPermission === Rolle.ADM || currentPermission === Rolle.SU;
        const newPermission = isAdmin || this.veranstaltung.veranstaltungsmanager.map(vam => vam.id).indexOf(this.sessionService.session.nutzer.id) !== -1;
        if(newPermission !== this.isVAM){
          this.selectedElement.emit("Detail");
          this.isVAM = newPermission;
        }
      }
    });

  }

  ngOnChanges() {
    this.selectedElement.emit("Detail");
    const currentPermission = this.sessionService.session.currentPermission;
    const isAdmin = currentPermission === Rolle.ADM || currentPermission === Rolle.SU;
    this.isVAM = isAdmin || this.veranstaltung.veranstaltungsmanager.map(vam => vam.id).indexOf(this.sessionService.session.nutzer.id) !== -1;
  }

  getTitleOfCurrentSelection() {
    return this.options.filter(e => e.key === this.currentElement)[0];
  }
}
