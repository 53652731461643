import { Component } from '@angular/core';
import { CheatServiceService } from './service/cheat-service.service';
import { NutzerService } from './service/nutzer.service';
import { StammService } from './service/stamm.service';
import { TeilnehmerService } from './service/teilnehmer.service';
import { VeranstaltungenService } from './service/veranstaltungen.service';
import { EventQueueService } from './service/event-queue-service.service';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from './service/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Click Dummy';

  public i = 0;
  public readonly serviceCount = 4;

  constructor(
    cheatServiceInit: CheatServiceService, //Wichtig, damit der überhapt geladen wird
    nutzerService: NutzerService,
    stammService: StammService,
    tnService: TeilnehmerService,
    veranstaltungenService: VeranstaltungenService,
    sessionService: SessionService,
    private eventQueue: EventQueueService,
    private toastr: ToastrService
    ){
      toastr.toastrConfig.positionClass = "toast-bottom-right";
      toastr.toastrConfig.closeButton = true;
      toastr.toastrConfig.preventDuplicates = true;
      toastr.toastrConfig.countDuplicates = true;
      toastr.toastrConfig.resetTimeoutOnDuplicate = true;
      toastr.toastrConfig.enableHtml = true;

      const init = () => {
        const serviceInitDone = () => {
          this.i++;
          if(this.i === this.serviceCount){
            this.eventQueue.fireEvent("initDone");
          }
        }

        nutzerService.updateData().then(serviceInitDone);
        stammService.updateData().then(serviceInitDone);
        tnService.updateData().then(serviceInitDone);
        veranstaltungenService.updateData().then(serviceInitDone);
      }

      sessionService.restoreSession().then(init).catch(init);
  }

}
