<div class="container text-center">
  <div class="p-lg-3">
    <div class="rounded shadow pcBorder p-3 mt-3 mt-lg-0">
      <h3 class="primaryFontColor">Anmeldung zur Veranstaltung</h3>
      <h4 class="primaryFontColor">{{event.titel}}</h4>
    </div>
  </div>

  <div class="p-lg-3">
    <app-filter [query]="query" [actionButtons]="actionButtons" (changeText)="onFilterChange($event)" [categories]="categories" [useUrlBar]="useUrlBarInSearch"></app-filter>
  </div>

  <div class="">
      <div class="rounded shadow pcBorder p-3 m-3 d-none d-lg-block" *ngFor="let s of sortetStaemme">

        <table class="table">
          <thead class="sticky-top whiteBackground">
          <tr class="text-center">
            <th colspan="1" class="iconHeader border-top-0"><img src="{{s.icon}}" class="stammIcon "/></th>
            <th colspan="5" class="titleHeader border-top-0">{{s | stammName}}</th>

          </tr>
          <tr class="">
            <th class="sort-th" (click)="setSort('$nachname')">Name</th>
            <th class="sort-th" (click)="setSort('$geburtsdatum')">Alter</th>
            <th class="">Weitere Informationen</th>
            <th class="">Anmelden</th>
            <th class=""></th>
            <th class="">Aktion</th>

          </tr>
          </thead>
          <tr *ngFor="let an of groupAnm.get(s)">
              <td>{{an.tn.vorname}} {{an.tn.nachname}}</td>
              <td>{{an.tn.geburtsdatum | ageFilter}}</td>
              <td class="text-center">
                <app-icon [icon]="an.tn.veganer ? Icon.VEGANER : Icon.VEGETARIER" [disabled]="!(an.tn.veganer || an.tn.vegetarier)"></app-icon>
                <app-icon [icon]="Icon.MITARBEITERSTATUS" [disabled]="!an.tn.mitarbeiterstatus"></app-icon>
                <app-icon [icon]="Icon.MEDIZINISCHE_HINWEISE" [disabled]="!an.tn.medizinische_hinweise"></app-icon>
                <app-icon [icon]="Icon.KRANKHEITEN" [disabled]="!an.tn.krankheiten"></app-icon>
                <app-icon [icon]="Icon.ERNAEHRUNGSBESONDERHEITEN" [disabled]="!an.tn.ernaehrungsbesonderheiten"></app-icon>
              </td>
              <td class="text-center">

                  <label class="checkboxContainer">
                      <input type="checkbox" [(ngModel)]="an.selected" [disabled]="an.missingFields && !an.selected && isSL">
                      <span class="checkmark"></span>
                  </label>
              </td>
              <td>
                <ng-template #htmlContent>
                  <div [innerHTML]="an.missingFields"></div>
                </ng-template>
                <i *ngIf="an.missingFields" [ngbTooltip]="htmlContent" class="fas fa-exclamation-triangle text-warning mr-2 xl-tooltip"></i>
              </td>
              <td>
                <button class="btn scButton" (click)="open(an.tn.id)"><i class="fa fa-eye"></i></button>
              </td>
          </tr>
        </table>
      </div>
    <!-- MOBILE ---->

    <div class="rounded shadow pcBorder d-block d-lg-none my-2 p-2" *ngFor="let s of sortetStaemme ">
      <div class="text-center sticky-top" style="background-color:#fff">
        <p><b><img src="{{s.icon}}" class="stammIcon "/> {{s | stammName}}</b></p>
        <hr/>
      </div>
      <ng-container *ngFor="let an of groupAnm.get(s); last as isLast">
      <div class=" pt-1" >
        <div class="d-flex pb-2">
          <div class="mr-auto">
            {{an.tn.vorname}} {{an.tn.nachname}}
          </div>
          <div class="">{{an.tn.geburtsdatum | ageFilter}} Jahre</div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="">
            <app-icon [icon]="an.tn.veganer ? Icon.VEGANER : Icon.VEGETARIER" [disabled]="!(an.tn.veganer || an.tn.vegetarier)"></app-icon>
            <app-icon [icon]="Icon.MITARBEITERSTATUS" [disabled]="!an.tn.mitarbeiterstatus"></app-icon>
            <app-icon [icon]="Icon.MEDIZINISCHE_HINWEISE" [disabled]="!an.tn.medizinische_hinweise"></app-icon>
            <app-icon [icon]="Icon.KRANKHEITEN" [disabled]="!an.tn.krankheiten"></app-icon>
            <app-icon [icon]="Icon.ERNAEHRUNGSBESONDERHEITEN" [disabled]="!an.tn.ernaehrungsbesonderheiten"></app-icon>
          </div>
          <div class="d-flex">

            <ng-template #htmlContent>
              <div [innerHTML]="an.missingFields"></div>
            </ng-template>

            <i *ngIf="an.missingFields" [ngbTooltip]="htmlContent" class="fas fa-exclamation-triangle text-warning mr-2 icon"></i>
            <label class="checkboxContainer ">
              <input type="checkbox" [(ngModel)]="an.selected" [disabled]="an.missingFields && !an.selected && isSL">
              <span class="checkmark"></span>
            </label>
            <button class="btn scButton" style="transform: scale(0.8); margin-top: -5px;" (click)="open(an.tn.id)"><i class="fa fa-eye"></i></button>
          </div>
        </div>
      </div>
        <hr *ngIf="!isLast"/>
      </ng-container>

    </div>
    <div style="height:55px;"></div>

    <div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
      <button class="btn pcButton m-2" (click)="save()">Speichern</button>
    </div>
  </div>
</div>
