<div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 d-none d-lg-block">
  <h3 class="text-center primaryFontColor">Basisdaten</h3>
  <hr>
  <div class="row ">
    <div class="col-10 input-group">
      <div class="input-group-prepend">
        <span class="input-group-text pflichtfeld">Titel der Veranstaltung</span>
      </div>

      <input type="text" class="form-control" [(ngModel)]="event.titel" placeholder="Title"
             ngbTooltip="Titel der Veranstaltung">
    </div>

    <div class="col-2  text-center position-relative" style="">
      <app-icon-switch style="position: absolute; top:-5px; left:0;" [options]="eventSizeIconOption" [(value)]="event.veranstaltungstyp" ></app-icon-switch>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-4 input-group" ngbTooltip="Beginn der Veranstaltung">
      <div class="input-group-prepend">
        <span class="input-group-text pflichtfeld">Vom</span>
      </div>
      <input class="form-control" placeholder="tt.mm.jjjj" name="dpStartdatum" [(ngModel)]="startDatum"
             ngbDatepicker #d="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn pcButton border" (click)="d.toggle()" type="button"><i
          class="far fa-calendar-alt"></i></button>
      </div>
    </div>
    <div class="col-4 input-group" ngbTooltip="Ende der Veranstaltung">
      <div class="input-group-prepend">
        <span class="input-group-text pflichtfeld">Bis</span>
      </div>
      <input class="form-control" placeholder="tt.mm.jjjj" name="dpEnddatum" [(ngModel)]="endDatum"
             ngbDatepicker #e="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn pcButton border" (click)="e.toggle()" type="button"><i
          class="far fa-calendar-alt"></i></button>
      </div>
    </div>
    <div class="col-4 input-group" ngbTooltip="Anmeldeschluss">
      <div class="input-group-prepend">
        <span class="input-group-text pflichtfeld">Anmeldung Bis</span>
      </div>
      <input class="form-control" placeholder="tt.mm.jjjj" name="dpAnmeldeschluss"
             [(ngModel)]="anmeldeschluss" ngbDatepicker #f="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn pcButton border" (click)="f.toggle()" type="button"><i
          class="far fa-calendar-alt"></i></button>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-4 input-group" ngbTooltip="Uhrzeitbeginn der Veranstaltung">
      <div class="input-group-prepend">
        <span class="input-group-text">Anfangsuhrzeit</span>
      </div>
      <input class="form-control" placeholder="00:00" [(ngModel)]="event.anfangsuhrzeit">
    </div>
    <div class="col-4 input-group" ngbTooltip="Uhrzeitende der Veranstaltung">
      <div class="input-group-prepend">
        <span class="input-group-text">Enduhrzeit</span>
      </div>
      <input class="form-control" placeholder="00:00" [(ngModel)]="event.enduhrzeit">
    </div>
    <div class="col-4 input-group">

    </div>
  </div>
</div>
<div class="shadow pcBorder p-3 rounded my-5 grayBgColor d-none d-lg-block">
  <h3 class="text-center primaryFontColor">Veranstaltungsinfos</h3>
  <hr>
  <span class="primaryFontColor pflichtfeld">Beschreibung:</span>
  <textarea class="form-control" rows="6" [(ngModel)]="event.kurzbeschreibung"
            placeholder="Eine Kurze Beschreibung der Veranstaltung"></textarea>
  <div class="row mt-2">
    <div class="col-6 input-group">
      <div class="input-group-prepend">
        <span class="input-group-text  pflichtfeld">Max</span>
      </div>

      <input type="number" class="form-control" min="0" [(ngModel)]="event.max_teilnehmeranzahl"
             placeholder="0" ngbTooltip="Maximale Teilnehmer">
    </div>
    <div class="col-6 input-group">
      <div class="input-group-prepend">
        <span class="input-group-text pflichtfeld">Ort</span>
      </div>

      <input type="text" class="form-control" [(ngModel)]="event.ort" placeholder="Ort"
             ngbTooltip="Ort der Veranstaltung">
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12 input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">Kursleitung</span>
      </div>

      <input type="text" class="form-control" [(ngModel)]="event.kursleitung"
             placeholder="Max & Erika Mustermann" ngbTooltip="Kursleitung">
    </div>
  </div>
</div>
<div class="shadow pcBorder p-3 rounded my-5 grayBgColor d-none d-lg-block">
  <h3 class="text-center primaryFontColor">Einstellungen</h3>
  <hr>
  <div class="row">
    <div class="col-6 input-group">
      <div class="input-group-prepend">
        <span class="input-group-text pflichtfeld">Veranstalter</span>
      </div>
      <app-single-datalist class="form-control p-0 datalist1" (onSelect)="setVeranstallter($event)"
                           [trackByFn]="veranstalterToId" [toHtml]="veranstalterToHtml" [toText]="veranstalterToText"
                           [auswahl]="event.veranstalter"
                           [fullList]="stammService.getStammListe()"></app-single-datalist>

    </div>
    <div class="col-6 input-group">
      <div class="input-group-prepend">
        <span class="input-group-text pflichtfeld">Teilnehmerpreis</span>
      </div>
      <input type="number" min="0" step="0.01" class="form-control" [(ngModel)]="event.preis" placeholder="0.00"
             ngbTooltip="Kosten pro Teilnehmer">
      <div class="input-group-append">
        <span class="input-group-text">€</span>
      </div>

    </div>
  </div>
  <div class="row mt-2">
    <div class="col-6 input-group">
      <div class="input-group-prepend">
        <span class="input-group-text pflichtfeld">Mitarbeiterpreis</span>
      </div>
      <input type="number" min="0" step="0.01" class="form-control" [(ngModel)]="event.mitarbeiter_preis"
             placeholder="0.00"
             ngbTooltip="Kosten pro Mitarbeiter">
      <div class="input-group-append">
        <span class="input-group-text">€</span>
      </div>

    </div>
    <div class="col-6 input-group">
      <div class="input-group-prepend">
        <span class="input-group-text pflichtfeld">Ausfallgebühr</span>
      </div>
      <input type="number" min="0" step="0.01" class="form-control" [(ngModel)]="event.ausfallgebuehr"
             placeholder="0.00"
             ngbTooltip="Kosten die gezahlt werden müssen, wenn ein Teilnehmer sich nach Anmeldeschluss abmeldet">
      <div class="input-group-append">
        <span class="input-group-text">€</span>
      </div>

    </div>
  </div>
  <div class="row mt-2" *ngIf="event.veranstaltungstyp === '1'">
    <div class="col-12 input-group" ngbTooltip="Anzahl der Stämme die zu dieser Veranstaltung eingeladen werden können. Kann nur von einem REGP-Admin angepasst werden.">
      <div class="input-group-prepend">
        <span class="input-group-text pflichtfeld">Maximal eingeladene Stämme</span>
      </div>
      <input type="number" min="10" step="1" class="form-control" [(ngModel)]="event.maximale_einladungen" placeholder="10"
              [disabled]="sessionService.session.currentPermission === Rolle.SL" >
    </div>

  </div>
  <div class="row mt-2">
    <div class="col-12">
      <app-nutzer-multi-data-list class="" [dropDownTitle]="'Veranstaltungsmanager hinzufügen:'"
                                  [fullList]="getPossibleVams()"
                                  [listTitle]="'Veranstaltungsmanager'" [partialList]="event.veranstaltungsmanager"
                                  [toggleTitle]="'Nutzer, die Bearbeitungsrechte für diese Veranstaltung haben'">
      </app-nutzer-multi-data-list>
    </div>
  </div>
</div>


<!----------------------------------------------------------------------
---- MOBILE !!!!!!!!!!!!
------>

<div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 d-block d-lg-none">
  <h3 class="text-center primaryFontColor">Basisdaten</h3>
  <hr>
  <div class="titleIcon my-2 text-center">
    <app-icon-switch [options]="eventSizeIconOption" [(value)]="event.veranstaltungstyp" ></app-icon-switch>
  </div>
  <hr>

  <div class="form-group">
    <label class="primaryFontColor pflichtfeld">Titel der Veranstaltung</label>
    <input type="text" class="form-control" [(ngModel)]="event.titel" placeholder="Titel"
           ngbTooltip="Titel der Veranstaltung">
  </div>

  <div class="form-group" ngbTooltip="Beginn der Veranstaltung">
    <label class="primaryFontColor pflichtfeld">Vom</label>
    <input class="form-control" placeholder="tt.mm.jjjj" name="dpStartdatum" [(ngModel)]="startDatum"
           ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" >
  </div>
  <div class=" form-group" ngbTooltip="Ende der Veranstaltung">
    <label class="primaryFontColor pflichtfeld">Bis</label>

    <input class="form-control" placeholder="tt.mm.jjjj" name="dpEnddatum" [(ngModel)]="endDatum"
           ngbDatepicker #e="ngbDatepicker" (click)="e.toggle()">

  </div>
  <div class="form-group" ngbTooltip="Anmeldeschluss">

    <label class="primaryFontColor pflichtfeld">Anmeldung Bis</label>

    <input class="form-control" placeholder="tt.mm.jjjj" name="dpAnmeldeschluss"
           [(ngModel)]="anmeldeschluss" ngbDatepicker #f="ngbDatepicker" (click)="f.toggle()">

  </div>


  <div class="form-group" ngbTooltip="Uhrzeitbeginn der Veranstaltung">
    <label class="primaryFontColor">Anfangsuhrzeit</label>
    <input class="form-control" placeholder="00:00" [(ngModel)]="event.anfangsuhrzeit">
  </div>
  <div class="form-group" ngbTooltip="Uhrzeitende der Veranstaltung">

    <label class="primaryFontColor">Enduhrzeit</label>

    <input class="form-control" placeholder="00:00" [(ngModel)]="event.enduhrzeit">
  </div>
</div>
<div class="shadow pcBorder p-3 rounded my-5 grayBgColor d-block d-lg-none">
  <h3 class="text-center primaryFontColor">Veranstaltungsinfos</h3>
  <hr>
  <div class="form-group">
    <label class="primaryFontColor pflichtfeld ">Beschreibung:</label>
    <textarea class="form-control" rows="6" [(ngModel)]="event.kurzbeschreibung"
              placeholder="Eine Kurze Beschreibung der Veranstaltung"></textarea>
  </div>
  <div class="form-group">

    <label class="primaryFontColor pflichtfeld">Max</label>


    <input type="number" class="form-control" min="0" [(ngModel)]="event.max_teilnehmeranzahl"
           placeholder="0" ngbTooltip="Maximale Teilnehmer">
  </div>
  <div class="form-group">
    <label class="primaryFontColor pflichtfeld">Ort</label>

    <input type="text" class="form-control" [(ngModel)]="event.ort" placeholder="Ort"
           ngbTooltip="Ort der Veranstaltung">
  </div>

  <div class="form-group">
    <label class="primaryFontColor">Kursleitung</label>

    <input type="text" class="form-control" [(ngModel)]="event.kursleitung"
           placeholder="Max & Erika Mustermann" ngbTooltip="Kursleitung">
  </div>
</div>
<div class="shadow pcBorder p-3 rounded my-5 grayBgColor d-block d-lg-none">
  <h3 class="text-center primaryFontColor">Einstellungen</h3>
  <hr>

  <div class="form-group">
    <label class="primaryFontColor pflichtfeld">Veranstalter</label>
    <app-single-datalist class="form-control p-0 datalist1" (onSelect)="setVeranstallter($event)"
                         [trackByFn]="veranstalterToId" [toHtml]="veranstalterToHtml" [toText]="veranstalterToText"
                         [auswahl]="event.veranstalter"
                         [fullList]="stammService.getStammListe()"></app-single-datalist>

  </div>
  <div class="form-group">
    <label class="primaryFontColor pflichtfeld">Teilnehmerpreis</label>
    <div class="input-group">
      <input type="number" min="0" step="0.01" class="form-control" [(ngModel)]="event.preis" placeholder="0.00"
             ngbTooltip="Kosten pro Teilnehmer">
      <div class="input-group-append">
        <span class="input-group-text">€</span>
      </div>
    </div>
  </div>

  <div class="form-group">
    <label class="primaryFontColor pflichtfeld">Mitarbeiterpreis</label>
    <div class="input-group">
      <input type="number" min="0" step="0.01" class="form-control" [(ngModel)]="event.mitarbeiter_preis"
             placeholder="0.00"
             ngbTooltip="Kosten pro Mitarbeiter">
      <div class="input-group-append">
        <span class="input-group-text">€</span>
      </div>
    </div>

  </div>
  <div class="form-group">
    <label class="primaryFontColor pflichtfeld">Ausfallgebühr</label>
    <div class="input-group">
      <input type="number" min="0" step="0.01" class="form-control" [(ngModel)]="event.ausfallgebuehr"
             placeholder="0.00"
             ngbTooltip="Kosten die gezahlt werden müssen, wenn ein Teilnehmer sich nach Anmeldeschluss abmeldet">
      <div class="input-group-append">
        <span class="input-group-text">€</span>
      </div>
    </div>
  </div>
  <div class="form-group" *ngIf="event.veranstaltungstyp === '1'" ngbTooltip="Anzahl der Stämme die zu dieser Veranstaltung eingeladen werden können. Kann nur von einem REGP-Admin angepasst werden.">
    <label class="primaryFontColor pflichtfeld">Maximal eingeladene Stämme</label>
    <div class="input-group">
      <input type="number" min="10" step="1" class="form-control" [(ngModel)]="event.maximale_einladungen"
             placeholder="10" [disabled]="sessionService.session.currentPermission === Rolle.SL">

    </div>
  </div>
  <app-nutzer-multi-data-list class="" [dropDownTitle]="'Veranstaltungsmanager hinzufügen:'"
                                [fullList]="getPossibleVams()"
                                [listTitle]="'Veranstaltungsmanager'" [partialList]="event.veranstaltungsmanager"
                                [toggleTitle]="'Nutzer, die Bearbeitungsrechte für diese Veranstaltung haben'">
  </app-nutzer-multi-data-list>


</div>
