import { Injectable } from '@angular/core';
import { IVeranstaltung } from '../models/IVeranstaltung.interface';
import { BackendService } from './backend.service';
import { SessionService } from './session.service';
import { JsonOf, WriteReturnType } from '../utils/types/backendTypes';
import { ITeilnehmer } from '../models/ITeilnehmer.interface';
import { Anmeldung } from '../models/IAnmeldung.interface';
import { IRechnungItem } from '../models/IRechnungItem';
import { IStamm } from '../models/IStamm.interface';
import { IStatistics } from '../models/IStatistics.interface';
import { Rolle } from '../models/Rolle.enum';

@Injectable({
  providedIn: 'root'
})
export class VeranstaltungenService {

  private revision: number = -1;

  constructor(private backend: BackendService,
    private sessionService: SessionService) {
  }

  private veranstaltungen: IVeranstaltung[] = [];

  public static fromJsonObj<T>(obj: JsonOf<T>): T {
    if (Array.isArray(obj)) {
      return obj.map(VeranstaltungenService.fromJsonObj) as unknown as T;
    }

    BackendService.updateIdToProperty(obj, 'veranstaltungsmanager', (id) => (window as any).nutzerService.getNutzer(id), true, () => (window as any).nutzerService.getRevision());
    BackendService.updateIdToProperty(obj, 'staemme', (id) => (window as any).stammService.getStamm(id), true, () => (window as any).stammService.getRevision());
    BackendService.updateIdToProperty(obj, 'veranstalter', (id) => (window as any).stammService.getStamm(id), false, () => (window as any).stammService.getRevision());
    BackendService.updateIdToProperty(obj, 'teilnehmer', (id) => (window as any).teilnehmerService.getTn(id), true, () => (window as any).teilnehmerService.getRevision());
    BackendService.replaceDate(obj, 'anfangsdatum');
    BackendService.replaceDate(obj, 'enddatum');
    BackendService.replaceDate(obj, 'anmeldeschluss');
    return obj as unknown as T;
  }

  public canEditEvent(event: IVeranstaltung): boolean {
    switch(this.sessionService.session.currentPermission) {
      case Rolle.ADM:
      case Rolle.SU:
        return true;
      case Rolle.SL:
        return event.veranstaltungsmanager.indexOf(this.sessionService.session.nutzer) !== -1;
      default:
        return false;
    }
  }

  public async getStats(): Promise<IStatistics[]>{
    const response = await this.backend.sendRequest("getStats", {
      sessionId: this.sessionService.session.sessionId
    }, false);

    response.forEach(line => {
      BackendService.updateIdToProperty(line, "event", (id) => (window as any).veranstaltungService.getEvent(id), false, () => (window as any).veranstaltungService.getRevision());
    });

    return response;
  }

  public async getRechnungenToEvent(event: IVeranstaltung): Promise<IRechnungItem[]> {
    const response = await this.backend.sendRequest("getRechnungenToEvent", {
      sessionId: this.sessionService.session.sessionId,
      eventId: event.id
    }, false);

    response.forEach(line => {
      BackendService.updateIdToProperty(line, "stamm", (id) => (window as any).stammService.getStamm(id), false, () => (window as any).stammService.getRevision());
      BackendService.updateIdToProperty(line, "veranstaltung", (id) => (window as any).veranstaltungService.getEvent(id), false, () => (window as any).veranstaltungService.getRevision());
    });

    return response as unknown as IRechnungItem[];
  }

  public async updateRechnung(event: IVeranstaltung,stamm: IStamm, rechnungsnummer: string, betrag: number, status: string, manungsanzahl: number, pdf: string | null): Promise<WriteReturnType>{
    const sessionId = this.sessionService.session.sessionId;
    const result = await this.backend.sendRequest("updateRechnung", {
      sessionId: sessionId,
      eventId: event.id,
      stammId: stamm.id,
      rechnungsnummer: rechnungsnummer,
      betrag: betrag,
      status: status,
      manungsanzahl: manungsanzahl,
      pdf: pdf
    }, true);

    return result;
  }

  public async getRechnungPdf(event: IVeranstaltung, stamm: IStamm): Promise<string>{
    const result = await this.backend.sendRequest("getRechnungPdf",{
      sessionId: this.sessionService.session.sessionId,
      eventId: event.id,
      stammId: stamm.id
    }, false);

    if(result.error){
      throw new Error(result.error);
    }
    return result.file;
  }

  public async sendRechnung(event: IVeranstaltung, stamm: IStamm): Promise<WriteReturnType>{
    return this.backend.sendRequest("sendRechnung", {
      sessionId: this.sessionService.session.sessionId,
      stammId: stamm.id,
      eventId: event.id
    }, true);
  }

  public async updateTnOnEvent(remove: ITeilnehmer[], add: ITeilnehmer[], event: IVeranstaltung): Promise<string[]> {
    let removeResult;
    let addResult;
    let hasError = false;
    try {
      removeResult = await this.backend.handleWriteReturnTypeRequest('removeTnFromEvent', {
        tns: remove.map(tn => tn.id),
        event: event.id,
        sessionId: this.sessionService.session.sessionId
      }, false as unknown as true);
    } catch (error) {
      hasError = true;
      removeResult = error;
    }
    try {
      addResult = await this.backend.handleWriteReturnTypeRequest('addTnToEvent', {
        tns: add.map(tn => tn.id),
        event: event.id,
        sessionId: this.sessionService.session.sessionId
      }, true);
    } catch (error) {
      hasError = true;
      addResult = error;
    }

    if (hasError) {
      throw [removeResult, addResult];
    }

    return [removeResult, addResult];
  }

  public updateVeranstaltung(veranstaltung: IVeranstaltung): Promise<string> {
    return this.backend.handleWriteReturnTypeRequest('updateVeranstaltung', {
      veranstaltung: BackendService.toJsonObj(veranstaltung),
      sessionId: this.sessionService.session.sessionId
    }, true);
  }

  public async getPricesToEvent(veranstaltung: IVeranstaltung): Promise<Anmeldung[]> {
    const anmeldungen = await this.backend.sendRequest('getPricesToEvent', { event: veranstaltung.id }, false);

    anmeldungen.forEach(anmeldung => {
      BackendService.updateIdToProperty(anmeldung, 'teilnehmer', id => (window as any).teilnehmerService.getTn(id), false, () => (window as any).teilnehmerService.getRevision());
      BackendService.updateIdToProperty(anmeldung, 'veranstaltung', id => this.getEvent(id), false, () => (window as any).veranstaltungService.getRevision());
    });

    return anmeldungen as unknown as Anmeldung[];
  }

  public async setPricesToEvent(anmeldungen: Anmeldung[]): Promise<string> {
    const jsonList = anmeldungen.map(a => {
      return {
        preis: a.preis,
        teilnehmer: a.teilnehmer.id,
        veranstaltung: a.veranstaltung.id,
        mitarbeiter: a.mitarbeiter,
        ausgefallen: a.ausgefallen
      };
    });
    const result = await this.backend.sendRequest('setPricesToEvent', {
      anmeldungen: jsonList,
      sessionId: this.sessionService.session.sessionId
    }, true);

    if (!result.success) {
      throw result.error;
    }
    return 'Änderungen gespeichert';
  }

  /**
   * ID wird nicht mit geschrieben
   */
  public createVeranstaltung(veranstaltung: IVeranstaltung): Promise<string> {
    return this.backend.sendRequest('createVeranstaltung', {
      veranstaltung: BackendService.toJsonObj(veranstaltung),
      sessionId: this.sessionService.session.sessionId
    }, true).then(result => {
      if (result.success) {
        return 'Veranstaltung erfolgreich angelegt';
      }
      throw result.error;
    });
  }

  public async deleteVeranstaltung(veranstaltung: IVeranstaltung): Promise<string> {
    const result = await this.backend.sendRequest("deleteVeranstaltung", {
      id: veranstaltung.id,
      sessionId: this.sessionService.session.sessionId
    }, true);
    if(result.success){
      return "Veranstaltung erfolgreich gelöscht";
    }
    throw result.error;
  }

  public updateData(): Promise<IVeranstaltung[]> {
    const data = this.backend.sendRequest('getVeranstaltungen', {
      sessionId: this.sessionService.session.sessionId
    }, false);

    const result = data.then(d => {
      if (d === null) {
        return [];
      }

      return d.map(VeranstaltungenService.fromJsonObj);
    }).catch(err => {
      console.log('Failed to load Veranstaltungen');
      console.log(err);
    }).then(d => {
      this.veranstaltungen = d as unknown as IVeranstaltung[];
      this.revision++;
      return this.veranstaltungen;
    });
    return result;
  }

  public getEvents(): IVeranstaltung[] {
    return this.veranstaltungen;
  }

  public getEvent(id: number): IVeranstaltung {
    return this.veranstaltungen.find(v => v.id === id);
  }

  public getRevision(): number {
    return this.revision;
  }

}
