import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { INutzer } from 'src/app/models/INutzer.interface';

@Component({
  selector: 'app-nutzer-multi-data-list',
  templateUrl: './nutzer-multi-data-list.component.html',
  styleUrls: ['./nutzer-multi-data-list.component.scss']
})
export class NutzerMultiDataListComponent implements OnInit, OnChanges {

  @Input()
  public listTitle: string;
  @Input()
  public dropDownTitle: string = "Nutzer hinzufügen:";
  @Input()
  public fullList: INutzer[];
  @Input()
  public partialList: INutzer[];
  @Input()
  public toggleTitle: string;
  public addNutzer = (nutzer) => {this.partialList.push(nutzer); this.filter()};
  public removeNutzer = (nutzer) => {this.partialList.splice(this.partialList.indexOf(nutzer), 1); this.filter()};
  public fullListFiltered: INutzer[];

  constructor() { }

  ngOnInit() {
    this.filter();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.fullList){
      this.filter();
    }
  }

  private filter(){
    this.fullListFiltered = this.fullList.filter(e=>this.partialList.indexOf(e) === -1)
  }


  public nutzerToText(nutzer: INutzer) {
    return nutzer.username + "-" + nutzer.vorname + " " + nutzer.nachname;
  }

  public nutzerToHtml(nutzer: INutzer) {
    return "<p class=\"self-align-center\">"+nutzer.username + " | " + nutzer.vorname + " " + nutzer.nachname+"</p>";
  }

  public nutzerToId(nutzer: INutzer) {
    return nutzer.id;
  }
}
