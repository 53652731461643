<div class="row border-top-rounded primaryBackround"  >
  <div class="col-12 input-group fielDescription d-none d-lg-flex">
      <div class="input-group-prepend" ngbTooltip="{{toggleTitle}}">
          <span class="input-group-text">{{dropDownTitle}}</span>
      </div>
      <app-single-datalist class="form-control p-0" (onSelect)="addNutzer($event)" [trackByFn]="nutzerToId" [multi]="true"
      [toHtml]="nutzerToHtml" [toText]="nutzerToText" [auswahl]="" [fullList]="fullListFiltered">
    </app-single-datalist>
  </div>
  <div class="col-12 form-group fielDescription d-block d-lg-none">
    <label class="primaryFontColor">{{dropDownTitle}}</label>
    <app-single-datalist class="form-control p-0" (onSelect)="addNutzer($event)" [trackByFn]="nutzerToId" [multi]="true"
                         [toHtml]="nutzerToHtml" [toText]="nutzerToText" [auswahl]="" [fullList]="fullListFiltered">
    </app-single-datalist>
  </div>
</div>

<table class="table primaryBackround border-bottom-rounded" *ngIf="(partialList.length>0)">
  <tr>
    <th colspan="4" class="fielDescription text-center">{{listTitle}}</th>
  </tr>
  <tr *ngFor="let n of partialList">
    <td>{{n.vorname}}</td>
    <td>{{n.nachname}}</td>
    <td>{{n.username}}</td>
    <td><button class="btn btn-danger float-right" (click)="removeNutzer(n)">Entfernen</button></td>
  </tr>
</table>
