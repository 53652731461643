import { Injectable } from '@angular/core';
import { ITeilnehmer } from '../models/ITeilnehmer.interface';
import { SessionService } from './session.service';
import { BackendService } from './backend.service';
import { JsonOf } from '../utils/types/backendTypes';
import {Icon} from "../utils/icon/icon.component";
import {binaryIconOptions, IconOption} from "../utils/icon-switch/icon-switch.component";

@Injectable({
  providedIn: 'root'
})
export class TeilnehmerService {

  private revision: number = -1;

  constructor(private backend: BackendService,
    private sessionService: SessionService) {
  }

  private teilnehmer: ITeilnehmer[] = [];

  public readonly iconList:Array<{icon:Icon,options:IconOption[],key:string}> = [
    {icon: Icon.DARF_UNTER_AUFSICHT_SCHWIMMEN,options: binaryIconOptions(Icon.DARF_UNTER_AUFSICHT_SCHWIMMEN),key: "darf_unter_aufsicht_schwimmen"},
    {icon: Icon.KRANKENVERSICHERUNG,options: binaryIconOptions(Icon.KRANKENVERSICHERUNG),key: "krankenversicherung"},
    {icon: Icon.TETANUS_IMPFUNG,options: binaryIconOptions(Icon.TETANUS_IMPFUNG),key: "tetanus_impfung"},
    {icon: Icon.BESONDERE_AKTIVITAETEN,options: binaryIconOptions(Icon.BESONDERE_AKTIVITAETEN),key: "besondere_aktivitaeten"},
    {icon: Icon.ZECKEN,options: binaryIconOptions(Icon.ZECKEN),key: "zecken"},
    {icon: Icon.MEDI_VERABREICHEN,options: binaryIconOptions(Icon.MEDI_VERABREICHEN),key: "medi_verabreichen"}
  ];

  public static fromJsonObj<T>(obj: JsonOf<T>): T {
    BackendService.updateIdToProperty(obj, "stamm", (id) => (window as any).stammService.getStamm(id), false, () => (window as any).stammService.getRevision());
    BackendService.replaceDate(obj, "geburtsdatum");
    return obj as unknown as T;
  }

  public updateData(): Promise<ITeilnehmer[]> {
    const data = this.backend.sendRequest("getTeilnehmer", {
      sessionId: this.sessionService.session.sessionId
    }, false);

    const result = data.then(d => {
      if (d === null) {
        return [];
      }

      return d.map(TeilnehmerService.fromJsonObj);
    }).catch(err => {
      console.log("Failed to load Veranstaltungen");
      console.log(err);
    }).then(d => {
      this.teilnehmer = d as unknown as ITeilnehmer[];
      this.revision++;
      return this.teilnehmer;
    });
    return result;
  }

  public getTnList(): ITeilnehmer[] {
    return this.teilnehmer;
  }

  public getTn(id: number): ITeilnehmer {
    return this.teilnehmer.find(tn => tn.id === id);
  }

  public updateTn(tn: ITeilnehmer, ignoreReload?: boolean): Promise<string> {
    try {
      console.log("updateTnToJSON: ");
      console.log(BackendService.toJsonObj(tn));
      console.log("updateTnObject: ")
      console.log(tn);
      const data = this.backend.sendRequest("updateTeilnehmer", {
        teilnehmer: BackendService.toJsonObj(tn),
        sessionId: this.sessionService.session.sessionId
      }, !ignoreReload as unknown as true);
      return data.then(msg => {
        return new Promise<string>((resolve, reject) => {
          if (msg.success === false) {
            reject(msg.error);
          }
          resolve("Änderungen gespeichert");
        })
      });
    } catch (e) {
      return Promise.reject(e.message)
    }
  }

  /**
   * ID wird nicht mit geschrieben
   */
  public async createTn(teilnehmer: ITeilnehmer, ignoreReload = false): Promise<string> {
    const createResult = await this.backend.sendRequest("createTeilnehmer", {
      teilnehmer: BackendService.toJsonObj(teilnehmer),
      sessionId: this.sessionService.session.sessionId
    }, !ignoreReload as unknown as true);

    if(createResult.success){
      return "Teilnehmer erfolgreich angelegt";
    } else {
      throw createResult.error;
    }
  }

  public async deleteTn(tn: ITeilnehmer): Promise<string>{
    const deleteResult = await this.backend.sendRequest("deleteTn", {
      id: tn.id,
      sessionId: this.sessionService.session.sessionId
    }, true);

    if(deleteResult.success){
      return "Teilnehmer gelöscht";
    } else {
      throw deleteResult.error;
    }
  }

  public getRevision(): number {
    return this.revision;
  }
}
