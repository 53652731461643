import { Component, OnInit } from '@angular/core';
import { INutzer } from 'src/app/models/INutzer.interface';
import { SessionService } from 'src/app/service/session.service';

@Component({
  selector: 'app-view-settings',
  templateUrl: './view-settings.component.html',
  styleUrls: ['./view-settings.component.scss']
})
export class ViewSettingsComponent implements OnInit {

  public selectedElement: string;
  public nutzer: INutzer;

  constructor(
    private sessionService: SessionService
  ) {
  }

  ngOnInit() {
    this.nutzer = this.sessionService.session.nutzer;
  }

}
