import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ListTnComponent} from './teilnehmer/list-tn/list-tn.component';
import {ListEventComponent} from './event/list-event/list-event.component';
import {DetailsTnComponent} from './teilnehmer/details-tn/details-tn.component';
import {DetailsEventComponent} from './event/details-event/details-event.component';
import {EditTnComponent} from './teilnehmer/edit-tn/edit-tn.component';
import { NewEventComponent } from './event/new-event/new-event.component';
import { NewTnComponent } from './teilnehmer/new-tn/new-tn.component';
import { ViewSettingsComponent } from './settings/view-settings/view-settings.component';
import { SessionService } from './service/session.service';
import { HomeComponent } from './home/home.component';
import { RegistrationComponent } from './home/registration/registration.component';
import { EditEventComponent } from './event/details-event/edit-event/edit-event.component';
import { TnListComponent } from './event/details-event/tn-list/tn-list.component';
import { EventAnmeldungComponent } from './event/details-event/event-anmeldung/event-anmeldung.component';
import { AnmeldungDruckenComponent } from './event/details-event/anmeldung-drucken/anmeldung-drucken.component';
import { RecoveryComponent } from './home/recovery/recovery.component';
import { DatenschutzComponent } from './home/datenschutz/datenschutz.component';
import { ImpressumComponent } from './home/impressum/impressum.component';
import { RechnungsuebersichtComponent } from './rechnungsuebersicht/rechnungsuebersicht.component';
import { HelpComponent } from './utils/help/help.component';
import { GlobalStatsComponent } from './global-stats/global-stats.component';

const routes: Routes = [
  { path: 'tn',               component: ListTnComponent, canActivate: [SessionService]},
  { path: 'event',            component: ListEventComponent, canActivate: [SessionService]},
  { path: 'tnDetails/:id',    component: DetailsTnComponent, canActivate: [SessionService]},
  { path: 'eventTnList/:id',  component: TnListComponent, canActivate: [SessionService]},
  { path: 'tnEdit/:id',       component: EditTnComponent, canActivate: [SessionService]},
  { path: 'tnAnmeldung/:id',  component: EventAnmeldungComponent, canActivate:[SessionService]},
  { path: 'tnNew',            component: NewTnComponent, canActivate: [SessionService]},
  { path: 'eventDetails/:id', component: DetailsEventComponent, canActivate: [SessionService]},
  { path: 'eventEdit/:id',    component: EditEventComponent, canActivate: [SessionService]},
  { path: 'anmeldungDrucken/:id',   component: AnmeldungDruckenComponent, canActivate: [SessionService]},
  { path: "eventNew",         component: NewEventComponent, canActivate: [SessionService]},
  { path: "setting",          component: ViewSettingsComponent, canActivate: [SessionService]},
  { path: "stats",            component: GlobalStatsComponent, canActivate: [SessionService]},
  { path: "home",             component: HomeComponent},
  { path: "rechnung",         component: RechnungsuebersichtComponent},
  { path: "recovery/:token",  component: RecoveryComponent},
  { path: "registration",     component: RegistrationComponent},
  { path: "datenschutz",      component: DatenschutzComponent},
  { path: "impressum",        component: ImpressumComponent},
  { path: "help",             component: HelpComponent},
  { path: '**',               component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
