<h1 class="text-center">{{title}}</h1>
<app-filter [query]="query" [actionButtons]="null" (changeText)="onFilterChange($event)" [categories]="categories" [useUrlBar]="useUrlBarInSearch"></app-filter>
<table class="table text-center d-none d-lg-table">
  <tr>
    <th class="sort-th" (click)="setSort('$vorname')">Vorname</th>
    <th class="sort-th" (click)="setSort('$nachname')">Nachname</th>
    <th class="sort-th" (click)="setSort('$e_mail_adresse')">E-Mail</th>
    <th class="sort-th" (click)="setSort('$rolle')">Rolle</th>
    <th>Aktion</th>
  </tr>
  <tr *ngFor="let n of filteredList">
    <td>{{n.vorname}}</td>
    <td>{{n.nachname}}</td>
    <td>{{n.e_mail_adresse}}</td>
    <td>{{n.rolle}}</td>
    <td><button class="btn scButton" (click)="open(n)" ngbTooltip="Bearbeiten"><i class="fa fa-edit"></i></button></td>
  </tr>
</table>

<ng-container *ngFor="let n of filteredList last as isLast">
  <div class=" pcBorder rounded my-2 d-lg-none d-block" >
    <div class="d-flex pb-2 align-items-center pr-2">
      <div class="mr-auto p-2 stammMobileBorder secondaryBgColor">
        <b>{{n.vorname}} {{n.nachname}}</b>
      </div>
      <div class="alterDivMobile pl-2">{{n.rolle}} </div>

    </div>

    <div class="d-flex p-2 pt-0 align-items-center">
      <div class="mr-auto">{{n.e_mail_adresse}}</div>


      <button class="btn scButton" (click)="open(n)" ngbTooltip="Bearbeiten"><i class="fa fa-edit"></i></button>
    </div>
  </div>
</ng-container>
