import { Component, OnInit, NgZone } from '@angular/core';
import { IVeranstaltung, getVeranstaltungsSearchCategories } from '../../models/IVeranstaltung.interface';
import { VeranstaltungenService } from '../../service/veranstaltungen.service';
import { IActionButton } from '../../utils/filter/buttons';
import { FilterEngineService } from 'src/app/service/filter-engine.service';
import { Router } from '@angular/router';
import { EventQueueService } from 'src/app/service/event-queue-service.service';
import { ExportService } from 'src/app/service/export-service.service';
const MINUTE = 60*1000;
const HOUR = 60*MINUTE;
const DAY = 24*HOUR;
@Component({
  selector: 'app-list-event',
  templateUrl: './list-event.component.html',
  styleUrls: ['./list-event.component.scss']
})
export class ListEventComponent implements OnInit {
  public currentPage = 0;
  public readonly old = Date.now() - 3*DAY;
  public events: IVeranstaltung[] = [];
  private eventFullList: IVeranstaltung[] = [];
  public categories: {display: string, key: string, type: string}[];
  actionButtons: IActionButton[] = [
    { title: 'Neue Veranstaltung', value: '<i class="fas fa-plus"></i>', callback: () => this.newVeranstaltug(), mobile:true },
    {title: 'Export', value: '<i class="fas fa-file-export"></i>', callback: () => this.export(), mobile:false}
  ];
  query = '';

  constructor(
    private readonly router: Router,
    private readonly veranstaltungenService: VeranstaltungenService,
    private readonly zone: NgZone,
    private readonly filter: FilterEngineService,
    private readonly eventQueue: EventQueueService,
    private readonly exportService: ExportService
  ) {
    this.eventQueue.getObservable().subscribe(msg => {
      if (msg === "initDone") {
        this.ngOnInit();
      }
    });
    this.categories = getVeranstaltungsSearchCategories();
  }

  ngOnInit() {
    this.events = this.veranstaltungenService.getEvents();
    this.eventFullList = this.veranstaltungenService.getEvents();
    this.onFilterChange(this.query);
  }

  private newVeranstaltug() {
    this.router.navigate(['eventNew']);
  }

  onFilterChange(newQuery: string) {
    this.query = newQuery;
    const newList = this.filter.filter(newQuery, this.eventFullList);
    newList.then((filteredList) => this.zone.run(() => {
      console.log('update UI, ' + filteredList.length + ' elements');
      this.events = filteredList.sort((e1, e2) => {
        if(this.old < e1.enddatum.getTime()){
          if(this.old < e2.enddatum.getTime()){
            return e1.anfangsdatum.getTime() - e2.anfangsdatum.getTime();
          }
          return -1;
        }
        if(this.old < e2.enddatum.getTime()){
          return 1;
        }
        return e2.anfangsdatum.getTime() - e1.anfangsdatum.getTime();
      });
      this.currentPage = 0;
    })).catch(error => console.log('Failed to filter: ', error));
    console.log('on Filter Change: ' + newQuery);
  }

  private export() {
    this.exportService.export(this.events, "Veranstaltungen");
  }

  protected readonly window = window;

  scrollUp() {
    window.scrollTo(0,0);
  }
}
