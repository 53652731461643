import {Component, NgZone, OnInit} from '@angular/core';
import {VeranstaltungenService} from '../service/veranstaltungen.service';
import {NutzerService} from '../service/nutzer.service';
import {getRechnungsItemSearchCategories, IRechnungItem} from '../models/IRechnungItem';
import {EventQueueService} from '../service/event-queue-service.service';
import {FilterEngineService} from '../service/filter-engine.service';
import {DownloadService} from "../service/download.service";

@Component({
  selector: 'app-rechnungsuebersicht',
  templateUrl: './rechnungsuebersicht.component.html',
  styleUrls: ['./rechnungsuebersicht.component.scss']
})
export class RechnungsuebersichtComponent implements OnInit {


  public rechnungenAll: IRechnungItem[] = [];
  public rechnungen: IRechnungItem[] = [];
  public query = "";
  public categories = getRechnungsItemSearchCategories();

  constructor(
    private eventService: VeranstaltungenService,
    private nutzerService: NutzerService,
    private eventQueue: EventQueueService,
    private filter: FilterEngineService,
    private zone: NgZone,
    private downloadService: DownloadService
  ) {
    this.eventQueue.getObservable().subscribe(msg => {
      if(msg === "initDone"){
        this.reload();
      }
    });
  }

  public onFilterChange(newQuery: string){
    if(typeof newQuery !== "string"){
      return;
    }
    this.query = newQuery;
    this.filter.filter(newQuery, this.rechnungenAll).then((filteredList) => this.zone.run(() => {
      console.log('update UI, ' + filteredList.length + ' elements');
      this.rechnungen = filteredList;
      if(this.query.split(" ").filter(e => e.startsWith("$")).length === 0) {
        this.rechnungen.sort((r1, r2) => {
          const erg = r2.rechnungsStatus.localeCompare(r1.rechnungsStatus);
          if (erg) {
            return erg;
          }
          return r1.veranstaltung.anfangsdatum.getTime() - r2.veranstaltung.anfangsdatum.getTime();
        });
      }
    })).catch(error => console.log('Failed to filter: ', error));
    console.log('on Filter Change: ' + newQuery);
  }

  public setSort(newSortKey: string) {
    const elements = this.query.split(" ");
    const withoutSearch = elements.filter(e => !e.startsWith("$"));
    this.query = withoutSearch.join(" ") + " " + newSortKey;
  }

  ngOnInit() {
    this.reload();
  }

  private async reload(){
    this.rechnungenAll = await this.nutzerService.getRechnungen();
    this.onFilterChange(this.query);
  }

  public async showRechnung(rechnung: IRechnungItem) {
    const pdf = await this.eventService.getRechnungPdf(rechnung.veranstaltung, rechnung.stamm);
    this.downloadService.downloadURI(pdf, rechnung.rechnungsNummer + ".pdf");

  }
}
