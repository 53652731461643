<span [ngbTooltip]="helpText" >
<ng-container *ngIf="strike">
  <span class="position-relative">
    <i class="fas fa-ban position-absolute icon banIcon" ></i>
    <i [class]="iconClass" class=" icon" ></i>
  </span>
</ng-container>
<ng-container *ngIf="!strike">
  <i [class]="iconClass" class="icon"></i>
</ng-container>
</span>
