<div class="container pt-3">
    <app-event-edit-mask [event]="event"></app-event-edit-mask>
    <div class="shadow pcBorder p-3 rounded mt-5 mb-2 grayBgColor" *ngIf="event.veranstaltungstyp === '1'">
        <h3 class="text-center primaryFontColor">Einladungen</h3>
        <hr>
        <app-stamm-multi-data-list [partialList]="event.staemme" [fullList]="stammService.getStammListe()"
            [listTitle]="'Einladungen'"></app-stamm-multi-data-list>
    </div>
  <div style="height:55px;"></div>
  <div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
    <button class="btn pcButton m-2" (click)="save()">Speichern</button>
    <button class="btn btn-danger m-2" (click)="delete()">Löschen</button>
  </div>

</div>
