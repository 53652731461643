import { Injectable } from '@angular/core';
import { INutzer } from '../models/INutzer.interface';
import { BackendService } from './backend.service';
import { SessionService } from './session.service';
import { JsonOf, WriteReturnType } from '../utils/types/backendTypes';
import { IRechnungItem } from '../models/IRechnungItem';

@Injectable({
  providedIn: 'root'
})
export class NutzerService {

  private nutzer: INutzer[] = [];
  private revision: number = -1;

  public static fromJsonObj<T>(obj: JsonOf<T>): T {
    BackendService.updateIdToProperty(obj, "stamme", (id) => (window as any).stammService.getStamm(id), true, ()=>(window as any).stammService.getRevision());
    BackendService.replaceDate(obj, "geburtsdatum");
    return obj as unknown as T;
  }

  constructor(
    private backend: BackendService,
    private sessionService: SessionService,
  ) {
  }


  /**
   * ID wird nicht mit geschrieben
   * @param nutzer
   */
  public createNutzer(nutzer: INutzer): Promise<string> {
    try {

      const data = this.backend.sendRequest("createNutzer", {
        nutzer: BackendService.toJsonObj(nutzer),
        sessionId: this.sessionService.session.sessionId
      }, true);

      return data.then(msg => {
        return new Promise<string>((resolve, reject) => {
          if (msg.success === false) {
            reject(msg.error);
          }
          resolve("Nutzer erfolgreich angelegt");
        })
      });
    } catch (e) {
      return Promise.reject(e.message);
    }
  }

  public async deleteNutzer(nutzer: INutzer): Promise<string> {
    const response = await this.backend.sendRequest("deleteNutzer", {
      id: nutzer.id,
      sessionId: this.sessionService.session.sessionId
    }, true);
    if(response.success){
      return "Succes";
    }
    throw response.error;
  }

  public async getRechnungen(): Promise<IRechnungItem[]> {
    const response = await this.backend.sendRequest("getRechnungenToUser", {
      sessionId: this.sessionService.session.sessionId
    }, false);

    response.forEach(line => {
      BackendService.updateIdToProperty(line, "stamm", (id) => (window as any).stammService.getStamm(id), false, ()=>(window as any).stammService.getRevision());
      BackendService.updateIdToProperty(line, "veranstaltung", (id) => (window as any).veranstaltungService.getEvent(id), false, ()=>(window as any).veranstaltungService.getRevision());
    });

    return response as unknown as IRechnungItem[];
  }

  public async sendPasswordRecoveryMail(email): Promise<true> {
    const result = await this.backend.sendRequest("sendPasswordRecoveryMail", {email: email}, false);
    return result.ok;
  }

  public async sendUsernameRecoveryMail(email): Promise<true> {
    const result = await this.backend.sendRequest("sendUsernameRecoveryMail", {email: email}, false);
    return result.ok;
  }

  public async setPasswordFromRecovery(username, token, password): Promise<WriteReturnType> {
    const result = await this.backend.sendRequest("setPasswordFromRecovery", {
      username: username,
      token: token,
      password: password
    }, false);
    return result;
  }

  public updateData(): Promise<INutzer[]> {
    const data = this.backend.sendRequest("getNutzer", {
      sessionId: this.sessionService.session.sessionId
    }, false);

    const result = data.then(d => {
      if (d === null) {
        return [];
      }

      return d.map(NutzerService.fromJsonObj);
    }).catch(err => {
      console.log("Failed to load Nutzer");
      console.log(err);
    }).then(d => {
      this.nutzer = d as unknown as INutzer[];
      this.revision++;
      return this.nutzer;
    });
    return result;
  }


  public getNutzerByUsername(username: string): INutzer {
    return this.nutzer.find(n => n.username === username);
  }

  public getNutzer(id: number): INutzer {
    return this.nutzer.find(n => n.id === id);
  }

  public updateNutzerBasics(nutzer: INutzer): Promise<string> {
    try {
      const data = this.backend.sendRequest("updateNutzerBasics", {
        nutzer: BackendService.toJsonObj(nutzer),
        sessionId: this.sessionService.session.sessionId
      }, true);
      return data.then(msg => {
        return new Promise<string>((resolve, reject) => {
          if (msg.success === false) {
            reject(msg.error);
          }
          resolve("Änderungen gespeichert");
        })
      });
    } catch (e) {
      return Promise.reject(e.message)
    }
  }

  public updateNutzerCredentials(nutzer: INutzer): Promise<string> {
    try {
      const data = this.backend.sendRequest("updateNutzerCredentials", {
        nutzer: BackendService.toJsonObj(nutzer),
        sessionId: this.sessionService.session.sessionId
      }, true);
      return data.then(msg => {
        return new Promise<string>((resolve, reject) => {
          if (msg.success === false) {
            reject(msg.error);
          }
          resolve("Änderungen gespeichert");
        })
      });
    } catch (e) {
      return Promise.reject(e.message)
    }
  }

  getNutzerListe(): INutzer[] {
    return this.nutzer;
  }

  public getRevision(): number {
    return this.revision;
  }
}
