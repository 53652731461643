import { Component, NgZone, OnInit } from '@angular/core';
import { ITeilnehmer, getTeilnehmerSearchCategories } from '../../models/ITeilnehmer.interface';
import { TeilnehmerService } from '../../service/teilnehmer.service';
import { IActionButton } from '../../utils/filter/buttons';
import { FilterEngineService } from '../../service/filter-engine.service';
import { Router } from '@angular/router';
import { EventQueueService } from 'src/app/service/event-queue-service.service';
import { DetailsTnComponent } from '../details-tn/details-tn.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'src/app/service/session.service';
import { Rolle } from 'src/app/models/Rolle.enum';
import { ExportService } from 'src/app/service/export-service.service';
import { ImportDialogComponent } from 'src/app/utils/import-dialog/import-dialog.component';
import { StammService } from 'src/app/service/stamm.service';
import { IStamm } from 'src/app/models/IStamm.interface';
import { WorkBook } from 'xlsx/types';
import {Icon} from "../../utils/icon/icon.component";

@Component({
  selector: 'app-list-tn',
  templateUrl: './list-tn.component.html',
  styleUrls: ['./list-tn.component.scss']
})
export class ListTnComponent implements OnInit {
  public readonly Icon = Icon;

  public teilnehmer?: ITeilnehmer[];
  public categories: { display: string, key: string, type: string }[];
  public currentPage = 0;
  private teilnehmerFullList: ITeilnehmer[] = [];
  actionButtons: IActionButton[] = [
    { title: 'Neuer Teilnehmer', value: '<i class="fas fa-plus"></i>', callback: () => this.newTeilnehmer(), mobile:true },
    { title: 'Import', value: '<i class="fas fa-file-import"></i>', callback: () => this.import(), mobile:false },
    { title: 'Export', value: '<i class="fas fa-file-export"></i>', callback: () => this.export() , mobile:false}
  ];
  query = '';

  public importFelder: { type: string, felder: string }[] = [
    { type: "Text", felder: "Vorname, Nachname, Hausnummer, ..." },
    { type: "Datum", felder: "Geburtsdatum" },
    { type: "Zahlen", felder: "Postleitzahl" },
    { type: "Status", felder: "Mitarbeitstatus, Vegetarier, Veganer, ..." }
  ];

  constructor(
    private teilnehmerService: TeilnehmerService,
    private stammService: StammService,
    private zone: NgZone,
    private filter: FilterEngineService,
    private sessionService: SessionService,
    private router: Router,
    private eventQueue: EventQueueService,
    private exportService: ExportService,
    private ngbModal: NgbModal) {
    this.eventQueue.getObservable().subscribe(msg => {
      if (msg === "initDone") {
        this.updateData();
      }
    });
    this.categories = getTeilnehmerSearchCategories();
  }

  private newTeilnehmer() {
    this.router.navigate(["tnNew"]);
  }

  ngOnInit() {
    this.updateData();
  }

  private updateData() {
    this.teilnehmerFullList = this.teilnehmerService.getTnList().sort((tn1, tn2) => {
      const sortStamm = tn1.stamm.name.localeCompare(tn2.stamm.name);
      if(sortStamm !== 0){
        return sortStamm;
      }
      const d =  tn1.nachname.localeCompare(tn2.nachname);
      if(d === 0){
        return  tn1.vorname.localeCompare(tn2.vorname);
      }
      return d;
    });
    const currentPermission = this.sessionService.session.currentPermission;
    this.teilnehmerFullList = this.teilnehmerFullList.filter(tn => tn.nachname !== 'deleted');
    if (currentPermission !== Rolle.ADM && currentPermission !== Rolle.SU) {
      this.teilnehmerFullList = this.teilnehmerFullList.filter(tn => this.sessionService.session.nutzer.stamme.map(s => s.id).indexOf(tn.stamm.id) !== -1);
    }
    this.onFilterChange(this.query);
  }

  onFilterChange(newQuery: string) {
    if (typeof newQuery !== "string") {
      return;
    }
    this.query = newQuery;
    this.filter.filter(newQuery, this.teilnehmerFullList).then((filteredList) => this.zone.run(() => {
      console.log('update UI, ' + filteredList.length + ' elements');
      this.teilnehmer = filteredList;
      this.currentPage = 0;
    })).catch(error => console.log('Failed to filter: ', error));
    console.log('on Filter Change: ' + newQuery);
  }

  public setSort(newSortKey: string) {
    const elements = this.query.split(" ");
    const withoutSearch = elements.filter(e => !e.startsWith("$"));
    this.query = withoutSearch.join(" ") + " " + newSortKey;
  }

  private import() {
    const selectableStamme = this.getSelectableStamme();
    let selectedStamm = this.parseStamm(selectableStamme[0]);
    const importStatus = [];

    const activeModal = this.ngbModal.open(ImportDialogComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.title = "Teilnehmer importieren";
    activeModal.componentInstance.importFelder = this.importFelder;
    activeModal.componentInstance.exampleDownloadPath = "../../assets/Vorlage Teilnehmer.xlsx";
    const options = new Map<string, string[]>();
    options.set("Stamm", this.getSelectableStamme());
    activeModal.componentInstance.importStatus = importStatus;
    activeModal.componentInstance.options = options;
    activeModal.componentInstance.optionSelected.subscribe(sel => {
      selectedStamm = this.parseStamm(sel.option);
    });
    activeModal.componentInstance.uploadComplete.subscribe((book: WorkBook) => {
      this.exportService.importTeilnehmer(book, selectedStamm, importStatus);
    });
  }

  private parseStamm(name: string): IStamm {
    const id = parseInt(/^\((\d+)\)/.exec(name)[1], 10);
    return this.stammService.getStamm(id);
  }

  private getSelectableStamme() {
    let stamme: IStamm[];
    if (this.sessionService.session.currentPermission === Rolle.SL) {
      stamme = this.sessionService.session.nutzer.stamme;
    } else {
      stamme = this.stammService.getStammListe();
    }
    return stamme.sort((s1, s2) => s1.name.localeCompare(s2.name)).map(s => "(" + s.id + ") " + s.name);
  }

  private export() {
    this.exportService.export(this.teilnehmer, "Teilnehmer");
  }

  public open(tnId: number) {
    const activeModal = this.ngbModal.open(DetailsTnComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.tnId = tnId;
  }

  protected readonly window = window;

  scrollUp() {
    window.scrollTo(0,0);
  }
}
