<div class="root">

  <app-filter [actionButtons]="actionButtons" [query]="query" (changeText)="onFilterChange($event)"
              [categories]="categories"></app-filter>

  <table class="table d-none d-lg-table">
    <thead class="sticky-top">
    <tr class="text-center">
      <th class="sort-th" (click)="setSort('$stamm')">Stamm</th>
      <th class="sort-th" (click)="setSort('$nachname')">Name</th>
      <th class="sort-th" (click)="setSort('$geburtsdatum')">Alter</th>
      <th class="sort-th" (click)="setSort('$ort')">Ort</th>
      <th>Weitere Informationen</th>
      <th>Aktionen</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let tn of teilnehmer | paginate: { itemsPerPage: 25, currentPage: currentPage }; index as i"
        class="text-center">
      <td valign="middle" class="text-left"><img src="{{tn.stamm.icon}}"
                                                 class="stammIcon"/><span>{{tn.stamm | stammName}}</span></td>
      <td valign="middle">{{tn.vorname}} {{tn.nachname}}</td>
      <td valign="middle">{{tn.geburtsdatum | ageFilter}}</td>
      <td valign="middle">{{tn.ort}}</td>
      <td valign="middle">
        <app-icon [icon]="tn.veganer ? Icon.VEGANER : Icon.VEGETARIER" [disabled]="!tn.veganer && !tn.vegetarier"></app-icon>
        <app-icon [icon]="Icon.MITARBEITERSTATUS" [disabled]="!tn.mitarbeiterstatus"></app-icon>
        <app-icon [icon]="Icon.MEDIZINISCHE_HINWEISE" [disabled]="!tn.medizinische_hinweise"></app-icon>
        <app-icon [icon]="Icon.KRANKHEITEN" [disabled]="!tn.krankheiten"></app-icon>
        <app-icon [icon]="Icon.ERNAEHRUNGSBESONDERHEITEN" [disabled]="!tn.ernaehrungsbesonderheiten"></app-icon>
      </td>
      <td valign="middle">
        <button class="btn scButton shadow-small" routerLink="/tnEdit/{{tn.id}}">
          <i class="fa fa-edit"></i>
        </button>

        <button class="btn scButton shadow-small" (click)="open(tn.id)">
          <i class="fa fa-eye"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
  <!-- MOBILE ---->


    <ng-container *ngFor="let tn of teilnehmer | paginate: { itemsPerPage: 25, currentPage: currentPage }; index as i; last as isLast">
      <div class=" pcBorder rounded my-2 d-lg-none d-block" >
        <div class="d-flex pb-2 align-items-center pr-2">
          <div class="mr-auto p-2 stammMobileBorder secondaryBgColor">
            <b>{{tn.vorname}} {{tn.nachname}}</b>
          </div>
          <div class="alterDivMobile pl-2">{{tn.geburtsdatum | ageFilter}} Jahre</div>

        </div>
        <div class="d-flex p-2 pt-0 align-items-center">
          <div class="mr-auto">
            <img src="{{tn.stamm.icon}}"
                 class="stammIcon mr-2 "/><span>{{tn.stamm | stammName}}</span>

          </div>
          <div class="">{{tn.ort}}</div>
        </div>
        <div class="d-flex p-2 pt-0 align-items-center">
          <app-icon [icon]="tn.veganer ? Icon.VEGANER : Icon.VEGETARIER" [disabled]="!tn.veganer && !tn.vegetarier"></app-icon>
          <app-icon [icon]="Icon.MITARBEITERSTATUS" [disabled]="!tn.mitarbeiterstatus"></app-icon>
          <app-icon [icon]="Icon.MEDIZINISCHE_HINWEISE" [disabled]="!tn.medizinische_hinweise"></app-icon>
          <app-icon [icon]="Icon.KRANKHEITEN" [disabled]="!tn.krankheiten"></app-icon>
          <app-icon [icon]="Icon.ERNAEHRUNGSBESONDERHEITEN" [disabled]="!tn.ernaehrungsbesonderheiten"></app-icon>
          <div class="flex-grow-1"></div>
          <button class="btn scButton shadow-small" routerLink="/tnEdit/{{tn.id}}">
            <i class="fa fa-edit"></i>
          </button>
          <button class="btn scButton"  (click)="open(tn.id)"><i class="fa fa-eye"></i></button>
        </div>
      </div>
    </ng-container>
  <div class="mt-5" style="height:55px;"></div>
  <div *ngIf="teilnehmer?.length > 25" class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
  <pagination-controls  class="m-2" autoHide="true" previousLabel="" nextLabel=""
                       (pageChange)="currentPage = $event; scrollUp()"></pagination-controls>
  </div>
</div>



