import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeadlineComponent } from './headline/headline.component';
import { LoginComponent } from './headline/login/login.component';
import {NgbModule, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { DetailsEventComponent } from './event/details-event/details-event.component';
import { ItemEventComponent } from './event/list/item-event/item-event.component';
import { ListEventComponent } from './event/list-event/list-event.component';
import { ListTnComponent } from './teilnehmer/list-tn/list-tn.component';
import { DetailsTnComponent } from './teilnehmer/details-tn/details-tn.component';
import { EditTnComponent } from './teilnehmer/edit-tn/edit-tn.component';
import { AgeFilterPipe } from './filters/age-filter.pipe';
import {FormsModule} from '@angular/forms';
import { FilterComponent } from './utils/filter/filter.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { LoadingGifComponent } from './utils/loading-gif/loading-gif.component';
import { SingleDatalistComponent } from './utils/single-datalist/single-datalist.component';
import { NewEventComponent } from './event/new-event/new-event.component';
import { NewTnComponent } from './teilnehmer/new-tn/new-tn.component';
import { ViewSettingsComponent } from './settings/view-settings/view-settings.component';
import { NavSettingsComponent } from './settings/nav-settings/nav-settings.component';
import { ViewUserSettingsComponent } from './settings/view-user-settings/view-user-settings.component';
import { ViewStammSettingsComponent } from './settings/view-stamm-settings/view-stamm-settings.component';
import { HomeComponent } from './home/home.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { StammMultiDataListComponent } from './utils/stamm-multi-data-list/stamm-multi-data-list.component';
import { ViewUserManagementComponent } from './settings/view-user-management/view-user-management.component';
import { RegistrationComponent } from './home/registration/registration.component';
import { NewStammComponent } from './settings/view-stamm-settings/new-stamm/new-stamm.component';
import { NutzerMultiDataListComponent } from './utils/nutzer-multi-data-list/nutzer-multi-data-list.component';
import { ConfirmWindowComponent } from './utils/confirm-window/confirm-window.component';
import { StammesleiterComponent } from './settings/view-stamm-settings/stammesleiter/stammesleiter.component';
import { SafePipePipe } from './filters/safe-pipe.pipe';
import { DetailViewComponent } from './event/details-event/detail-view/detail-view.component';
import { DetailNavigationComponent } from './event/details-event/detail-navigation/detail-navigation.component';
import { EditEventComponent } from './event/details-event/edit-event/edit-event.component';
import { TnListComponent } from './event/details-event/tn-list/tn-list.component';
import { EventAnmeldungComponent } from './event/details-event/event-anmeldung/event-anmeldung.component';
import { AnmeldungDruckenComponent } from './event/details-event/anmeldung-drucken/anmeldung-drucken.component';
import { UserTableComponent } from './settings/view-user-management/user-table/user-table.component';
import { ImportDialogComponent } from './utils/import-dialog/import-dialog.component';
import { RecoveryComponent } from './home/recovery/recovery.component';
import { DatenschutzComponent } from './home/datenschutz/datenschutz.component';
import { ImpressumComponent } from './home/impressum/impressum.component';
import { RechnungComponent } from './event/details-event/rechnung/rechnung.component';
import { RechnungsuebersichtComponent } from './rechnungsuebersicht/rechnungsuebersicht.component';
import { RechnungsnummerComponent } from './utils/rechnungsnummer/rechnungsnummer.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { ViewStatsComponent } from './settings/view-stats/view-stats.component';
import { StammNamePipe } from './filters/stamm-name.pipe';
import { HelpComponent } from './utils/help/help.component';
import { GlobalStatsComponent } from './global-stats/global-stats.component';
import { NgbDateCustomParserFormatter } from './utils/NgbDateCustomParserFormatter';
import { AnmeldungSelectTnComponent } from './event/details-event/anmeldung-drucken/anmeldung-select-tn/anmeldung-select-tn.component';
import { PasswordCheckComponent } from './utils/password-check/password-check.component';
import { EventEditMaskComponent } from './event/common/event-edit-mask/event-edit-mask.component';
import { PflichtfelderComponent } from './event/details-event/pflichtfelder/pflichtfelder.component';
import { IconComponent } from './utils/icon/icon.component';
import { IconSwitchComponent } from './utils/icon-switch/icon-switch.component';
import { EditorTnComponent } from './teilnehmer/editor-tn/editor-tn.component';

@NgModule({
  declarations: [
    AppComponent,
    HeadlineComponent,
    LoginComponent,
    EditEventComponent,
    DetailsEventComponent,
    ItemEventComponent,
    ListEventComponent,
    ListTnComponent,
    DetailsTnComponent,
    EditTnComponent,
    AgeFilterPipe,
    FilterComponent,
    LoadingGifComponent,
    SingleDatalistComponent,
    NewEventComponent,
    NewTnComponent,
    ViewSettingsComponent,
    NavSettingsComponent,
    ViewUserSettingsComponent,
    ViewStammSettingsComponent,
    HomeComponent,
    StammMultiDataListComponent,
    ViewUserManagementComponent,
    EventAnmeldungComponent,
    RegistrationComponent,
    NewStammComponent,
    NutzerMultiDataListComponent,
    ConfirmWindowComponent,
    StammesleiterComponent,
    TnListComponent,
    AnmeldungDruckenComponent,
    SafePipePipe,
    DetailNavigationComponent,
    DetailViewComponent,
    UserTableComponent,
    ImportDialogComponent,
    RecoveryComponent,
    DatenschutzComponent,
    ImpressumComponent,
    RechnungComponent,
    RechnungsuebersichtComponent,
    RechnungsnummerComponent,
    ViewStatsComponent,
    StammNamePipe,
    HelpComponent,
    GlobalStatsComponent,
    AnmeldungSelectTnComponent,
    PasswordCheckComponent,
    EventEditMaskComponent,
    PflichtfelderComponent,
    IconComponent,
    IconSwitchComponent,
    EditorTnComponent,
  ],
  entryComponents: [
    ViewUserSettingsComponent,
    DetailsEventComponent,
    DetailsTnComponent,
    NewStammComponent,
    StammesleiterComponent,
    ConfirmWindowComponent,
    ImportDialogComponent
  ],
  imports: [
    NgxPaginationModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    HttpClient,
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
    AgeFilterPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
