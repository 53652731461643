import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IVeranstaltung } from '../../../models/IVeranstaltung.interface';
import { VeranstaltungenService } from '../../../service/veranstaltungen.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventQueueService } from 'src/app/service/event-queue-service.service';
import {Icon} from "../../../utils/icon/icon.component";

@Component({
  selector: 'app-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.scss']
})
export class DetailViewComponent implements OnInit {
  @Input()
  public eventId: number;
  public event: IVeranstaltung;

  constructor(
    private veranstaltungenService: VeranstaltungenService,
    private eventQueue: EventQueueService) {
    this.eventQueue.getObservable().subscribe(msg => {
      if (msg === "initDone") {
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    this.event = this.veranstaltungenService.getEvent(this.eventId);
  }


  protected readonly Icon = Icon;
  get typeIcon(){
    switch(this.event.veranstaltungstyp){
      case "0":
        return Icon.VERANSTALTUNGSTYP_LOCAL;
      case "1":
        return Icon.VERANSTALTUNGSTYP_REGIO;
      case "2":
        return Icon.VERANSTALTUNGSTYP_REGP;
      default:
        return Icon.VERANSTALTUNGSTYP_LOCAL;
    }
  }
}
