import {Component, OnInit} from '@angular/core';
import {INutzer} from 'src/app/models/INutzer.interface';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {IStamm} from 'src/app/models/IStamm.interface';
import {NutzerService} from 'src/app/service/nutzer.service';
import {StammService} from 'src/app/service/stamm.service';
import {ToastrService} from 'ngx-toastr';
import {SessionService} from 'src/app/service/session.service';
import {Rolle} from 'src/app/models/Rolle.enum';
import {Gender} from 'src/app/models/gender.enum';
import {Router} from '@angular/router';
import * as sha1 from 'js-sha1';
import {TimePickerService} from 'src/app/service/time-picker-service.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  public nutzer: INutzer & {passwort: string, passwortKopie: string};
  public readonly fields1 = [
    {key: 'vorname', label: 'Vorname', type: 'text', placeholder: 'Max', pflichtfeld: true},
    {key: 'nachname', label: 'Nachname', type: 'text', placeholder: 'Mustermann', pflichtfeld: true},
    {key: 'username', label: 'Benutzername', type: 'text', placeholder: 'maxi', pflichtfeld: true},
    {key: 'passwort', label: 'Passwort', type: 'password', placeholder: '************', pflichtfeld: true},
    {key: 'passwortKopie',label: 'Passwort Wiederholen',type: 'password',placeholder: '************',pflichtfeld: true},
  ];
  public readonly fields2 = [
    {key: 'e_mail_adresse', label: 'E-Mail', type: 'text', placeholder: 'max@mustermann.de', pflichtfeld: true},
    {key: 'geburtsdatum', label: 'Geburtsdatum', type: 'date', placeholder: '1990-01-01', pflichtfeld: false},
    {key: 'geschlecht', label: 'Geschlecht', type: 'dropDown', placeholder: 'male', pflichtfeld: true},
    {key: 'strasse', label: 'Straße', type: 'text', placeholder: 'Haupstraße', pflichtfeld: false},
    {key: 'hausnummer', label: 'Hausnummer', type: 'text', placeholder: '1a', pflichtfeld: false},
    {key: 'postleitzahl', label: 'Postleitzahl', type: 'number', placeholder: '11111', pflichtfeld: false},
    {key: 'ort', label: 'Ort', type: 'text', placeholder: 'Musterstadt', pflichtfeld: false},
    {key: 'zusatz', label: 'Addresszusatz', type: 'text', placeholder: 'Im ersten Stock', pflichtfeld: false}
  ];
  private _gebDate: NgbDateStruct;
  public staemme: IStamm[];
  public Gender = Gender;
  public Object = Object;
  public passwordValid = false;


  constructor(
    private nutzerService: NutzerService,
    private stammService: StammService,
    private timePickerService: TimePickerService,
    private router: Router,
    private toast: ToastrService,
    public sessionService: SessionService
  ) {
    this.staemme = stammService.getStammListe();
    this.nutzer = {
      id: -1,
      e_mail_adresse: '',
      geburtsdatum: new Date('2000-01-01T00:00:00Z'),
      geschlecht: Gender.MALE,
      hausnummer: '',
      nachname: '',
      ort: '',
      passworthash: '',
      postleitzahl: '' as unknown as number,
      rolle: Rolle.WAIT,
      stamme: [],
      strasse: '',
      username: '',
      vorname: '',
      zusatz: '',
      last_password_change: new Date(),
      passwortKopie: '',
      passwort: ''
    };
    this._gebDate = this.timePickerService.dateToStruct(this.nutzer.geburtsdatum);
  }

  public set gebDate(newDate: NgbDateStruct) {
    this._gebDate = newDate;
    this.nutzer.geburtsdatum = this.timePickerService.structToDate(newDate);
  }

  public get gebDate(): NgbDateStruct {
    return this._gebDate;
  }

  ngOnInit() {
  }


  public send() {
    const vaildationResult = this.validate();
    if (vaildationResult === 'OK') {
      // @ts-ignore
      this.nutzer.passworthash = sha1(this.nutzer.passwort);
      this.nutzerService.createNutzer(this.nutzer).then(msg => {
        this.toast.success(msg, 'Erfolgreich');
        this.router.navigate(["/home"]);
      }).catch(msg => this.toast.error(msg, 'Fehler'));
    } else {
      this.toast.warning(vaildationResult, 'Ungültige Daten');
    }
  }

  private validate(): string {
    if (this.nutzer.username.indexOf(" ") !== -1) {
      return 'Nutzername darf kein Leerzeichen enthalten'
    }
    if (!this.nutzer.username) {
      return 'Nutzername muss gesetzt werden';
    }
    if (!this.nutzer.vorname) {
      return 'Es muss ein Vorname eingegeben werden';
    }
    if (!this.nutzer.passwort) {
      return 'Das Passwort darf nicht leer sein';
    }
    if(!this.passwordValid) {
      return 'Das Passwort erfüllt nicht die Sicherheitsanforderungen';
    }
    if(this.nutzer.passwortKopie !== this.nutzer.passwort) {
      return "Passwörter stimmen nicht überein";
    }
    return 'OK';
  }

  public addStamm(stamm: IStamm) {
    this.nutzer.stamme.push(stamm);
  }

  public removeStamm(stamm: IStamm) {
    this.nutzer.stamme.splice(this.nutzer.stamme.indexOf(stamm), 1);
  }

}
