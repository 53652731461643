<div class="container-fluid" style="padding: 0;">
  <div class="row m-0">
    <app-detail-navigation (selectedElement)="selectedElement=$event"  class="col-12 col-lg-3 navigation p-0"
                           [veranstaltung]="event"></app-detail-navigation>

    <div [ngSwitch]="selectedElement" class="col-12 col-lg-9 inhalt px-lg-4">

      <app-detail-view *ngSwitchCase="'Detail'" [eventId]="id" class="w-100"></app-detail-view>
      <app-edit-event *ngSwitchCase="'Edit'" [eventId]="id" class="w-100"></app-edit-event>
      <app-pflichtfelder *ngSwitchCase="'Pflichtfelder'" [eventId]="id" class="w-100"></app-pflichtfelder>
      <app-event-anmeldung *ngSwitchCase="'Anmelden'" [eventId]="id" class="w-100"></app-event-anmeldung>
      <app-tn-list *ngSwitchCase="'Angemeldet'" [eventId]="id" class="w-100"></app-tn-list>
      <app-anmeldung-drucken *ngSwitchCase="'Drucken'" [eventId]="id" class="w-100"></app-anmeldung-drucken>
      <app-rechnung *ngSwitchCase="'Rechnung'" [eventId]="id" class="w-100"></app-rechnung>
      <div *ngSwitchDefault>Bitte im Menü links einen Punkt auswählen</div>

    </div>

  </div>
</div>
