import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ElementRef} from '@angular/core';
import {IActionButton} from './buttons';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnChanges {
  @Input() actionButtons: IActionButton[] = [];
  @Output() changeText: EventEmitter<string> = new EventEmitter<string>();
  @Input() query: string = "";
  @Input() useUrlBar = true;
  @Input() public categories = [{key: "Name", type: "boolean"}, {key: "Hausnummer", type: "number"}];
  public actionButtonsLeft: IActionButton[] = [];
  public actionButtonsRight: IActionButton[] = [];

  constructor(
    private readonly el: ElementRef,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    if (this.useUrlBar) {
      this.route.queryParams.subscribe(params => {
        if(params.q && typeof params.q === "string") {
          this.query = decodeURIComponent(params.q);
          this.textFieldUpdate();
        }
      });
    }

    this.textFieldUpdate();

    this.actionButtonsLeft = this.actionButtons.filter(ab => ab.position !== "right");
    this.actionButtonsRight = this.actionButtons.filter(ab => ab.position === "right");
  }

  ngOnChanges() {
    this.changeText.emit(this.query);

    this.actionButtonsLeft = this.actionButtons.filter(ab => ab.position !== "right");
    this.actionButtonsRight = this.actionButtons.filter(ab => ab.position === "right");
  }

  textFieldUpdate() {
    this.changeText.emit(this.query);
  }

  addQuery(newPart){
    this.query = this.query + newPart;
    this.textFieldUpdate();
    this.focus();
  }

  focus(){
    this.el.nativeElement.querySelector("input").focus();
  }

  updateUrl() {
    if (!this.query) {
      return;
    }

    if(this.useUrlBar){
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {q: encodeURIComponent(this.query)},
      });
    }
  }
}
