import { Component, OnInit, NgZone } from '@angular/core';
import { StammService } from 'src/app/service/stamm.service';
import { IStamm, getStammSearchCategories } from 'src/app/models/IStamm.interface';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewStammComponent } from 'src/app/settings/view-stamm-settings/new-stamm/new-stamm.component';
import { StammesleiterComponent } from './stammesleiter/stammesleiter.component';
import { FilterEngineService } from 'src/app/service/filter-engine.service';
import { IActionButton } from 'src/app/utils/filter/buttons';
import { ExportService } from 'src/app/service/export-service.service';
import { ImportDialogComponent } from 'src/app/utils/import-dialog/import-dialog.component';
import { WorkBook } from 'xlsx/types';
import { ConfirmWindowComponent } from 'src/app/utils/confirm-window/confirm-window.component';
import {Icon} from "../../utils/icon/icon.component";


@Component({
  selector: 'app-view-stamm-settings',
  templateUrl: './view-stamm-settings.component.html',
  styleUrls: ['./view-stamm-settings.component.scss']
})
export class ViewStammSettingsComponent implements OnInit {
  public readonly Icon = Icon;

  public isOpen = {};
  public staemme: IStamm[];
  public useUrlBarInSearch = false;
  public categories: any;
  public query = "";
  public actionButtons: IActionButton[] = [
    {title: 'Neuer Stamm', value: '<i class="fas fa-plus"></i>', callback: () => this.newStamm(), mobile:false},
    {title: 'Import', value: '<i class="fas fa-file-import"></i>', callback: () => this.import(), mobile:false},
    {title: 'Export', value: '<i class="fas fa-file-export"></i>', callback: () => this.export(), mobile:false},
    {title: 'Aktualisieren', value: '<i class="fas fa-sync-alt"></i>', callback: () => this.ngOnInit(), mobile:true}
  ];

  public importFelder: {type: string, felder: string}[] = [
    {type: "Text",    felder: "Name, Titel, Hausnummer, ..."},
    {type: "Zahlen",  felder: "Postleitzahl"}
  ];

  constructor(
    private stammService: StammService,
    private toast: ToastrService,
    private filter: FilterEngineService,
    private zone: NgZone,
    private ngbModal: NgbModal,
    private exportService: ExportService
  ) {
    this.categories = getStammSearchCategories();
  }

  ngOnInit() {
    this.staemme = [];
    this.onFilterChange(this.query);
  }

  public save(id: number) {
    this.stammService.updateStamm(this.staemme[id]).
      then(msg => {
        this.toast.success(msg, "Erfolgreich");
        this.isOpen[id] = false;
      }).
      catch(msg => this.toast.error(msg, "Fehler"));
  }

  // TODO prüfen, ob wirklich ein Bild ausgewählt wurde!!!!!!!
  public fileUpload($event: any, id: number) {
    const fileName = $event.target.files[0].name;
    $event.target.nextSibling.innerHTML = fileName;

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.staemme.find(s => s.id === id).icon = myReader.result.toString();
    };
    myReader.readAsDataURL(file);
  }

  public onFilterChange(newQuery: string) {
    if (typeof newQuery !== "string") {
      return;
    }
    this.isOpen = {};
    this.query = newQuery;
    this.filter.filter(newQuery, this.stammService.getStammListe()).then((filteredList) => this.zone.run(() => {
      this.staemme = filteredList;
    })).catch(error => console.log('Failed to filter: ', error));
  }

  public newStamm() {
    const activeModal = this.ngbModal.open(NewStammComponent, {
      size: 'lg'
    });
  }

  public delete(id: number){
    const stamm = this.staemme[id];
    const activeModal = this.ngbModal.open(ConfirmWindowComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.titel = "Stamm löschen?";
    activeModal.componentInstance.text = "Soll der Stamm " + stamm.title + " unwiederuflich gelöscht werden? Hierdurch werden alle Teilnehmer und Rechnungen gelöscht.";
    activeModal.result.then(async (result) => {
      if(result){
        try{
          await this.stammService.deleteStamm(stamm);
          this.toast.success("Stamm wurde gelöscht", "Erfolgreich");
        } catch (error) {
          this.toast.error(error, "Fehler");
        }
      } else {
        this.toast.info("Stamm wurde nicht gelöscht.", "Abgebrochen");
      }
    }).catch(()=>{
      this.toast.info("Stamm wurde nicht gelöscht.", "Abgebrochen");
    });
  }

  public openStammesleiterPopUp(id) {
    const activeModal = this.ngbModal.open(StammesleiterComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.stamm = this.staemme[id];
  }

  private export() {
    this.exportService.export(this.staemme, "Stämme");
  }

  private import() {
    const defaultIcon = StammService.DEFAULT_ICON;
    const importStatus = [];

    const activeModal = this.ngbModal.open(ImportDialogComponent, {
      size: 'lg'
    });
    activeModal.componentInstance.title = "Stämme importieren";
    activeModal.componentInstance.importFelder = this.importFelder;
    activeModal.componentInstance.exampleDownloadPath = "../../assets/Vorlage Stämme.xlsx";
    const options = new Map<string, string[]>();
    activeModal.componentInstance.importStatus = importStatus;
    activeModal.componentInstance.options = options;

    activeModal.componentInstance.uploadComplete.subscribe((book: WorkBook) => {
      this.exportService.importStamm(book, importStatus);
    });
  }
}
