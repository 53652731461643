<div class="p-3 text-center">
  <h1 class="primaryFontColor">Statistische Übersichten</h1>
  <p>Hier werden Statistiken über alle zugreifbaren Daten angezeigt. Wenn sie auf verschiedene Daten keine Rechte haben,
    sind diese hier nicht berücksichtigt.</p>

  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link primaryFontColor active" data-toggle="tab" href="#globalStats">Globale Statistik</a>
    </li>
    <li class="nav-item">
      <a class="nav-link primaryFontColor" data-toggle="tab" href="#eventStats">Veranstaltungsstatistik</a>
    </li>
    <li class="nav-item">
      <a class="nav-link primaryFontColor" data-toggle="tab" href="#stamStats">Stammstatistik</a>
    </li>
  </ul>

  <div class="tab-content">

    <div class="tab-pane active py-3" id="globalStats">
      <h3 class="primaryFontColor my-3">Globale Statistiken</h3>
      <div class="table-responsive">
        <table class="table table-striped ">
          <tr>
            <th>
              <span ngbTooltip="Anzahl Teilnehmer im System, inkl. gelöschter.">Teilnehmer im System</span>
            </th>
            <th>
              Gelöschte Teilnehmer
            </th>
            <th>
              Nutzer im System
            </th>
            <th>
              SuperUser
            </th>
            <th>
              <span  ngbTooltip="Anzahl Nutzer im System, die noch nicht bestätigt wurden.">Offene Nutzeranfragen</span>
            </th>
          </tr>
          <tr>
            <td>{{global.tnCount}}</td>
            <td>{{global.tnDeleteCount}}</td>
            <td>{{global.userCount}}</td>
            <td>{{global.userSuCount}}</td>
            <td>{{global.userOpenCount}}</td>
          </tr>
        </table>
      </div>
    </div>


    <div class="tab-pane py-3 fade" id="eventStats">
      <h3 class="primaryFontColor my-3">Veranstaltungsstatistik</h3>
      <p>Diese Liste ist nach Titel sortiert.</p>
      <div class="table-responsive">
        <table class="table table-striped">
          <tr>
            <th>Titel</th>
            <th><span ngbTooltip="Teilgenommen | Mitarbeiter | Ausgefallen | Gesamt | ü18">Teilnehmer</span></th>
            <th><span ngbTooltip="Anzahl Stämme, die zum Event eingeladen wurden.">Eingeladene Stämme</span></th>
            <th><span ngbTooltip="Anzahl Rechnungen, die nicht bezahlt oder storniert wurden.">Offene Rechnungen</span></th>
            <th><span ngbTooltip="Gesamtwert aller Rechnungen addiert.">Gesamtrechnungsbetrag</span></th>
          </tr>
          <tr *ngFor="let va of vas">
            <td><app-icon [icon]="typeIcon(va.veranstaltungstyp)" class="bigfont"></app-icon></td>
            <td>
              <i class="fas fa-circle-notch fa-spin" *ngIf="va.teilgenommenCount === 'loading'"></i>
              <span ngbTooltip="Teilgenommen" class="badge badge-success mx-1"
                *ngIf="va.teilgenommenCount !== 'loading'">{{va.teilgenommenCount}}</span>
              <span ngbTooltip="Mitarbeiter" class="badge badge-warning mx-1"
                *ngIf="va.mitarbeiterCount !== 'loading'">{{va.mitarbeiterCount}}</span>
              <span ngbTooltip="Ausgefallen" class="badge badge-danger mx-1"
                *ngIf="va.ausgefallenCount !== 'loading'">{{va.ausgefallenCount}}</span>
              <span ngbTooltip="Gesamt" class="badge badge-secondary mx-1"
                *ngIf="va.rechnungOffenCount !== 'loading'">{{va.tnCount}}</span>
              <span ngbTooltip="Gesamt älter 18" class="badge badge-primary mx-1"
                *ngIf="va.rechnungOffenCount !== 'loading'">{{va.tnCount18}}</span>
            </td>
            <td>{{va.inviteCount}}</td>
            <td>
              <i class="fas fa-circle-notch fa-spin" *ngIf="va.rechnungOffenCount === 'loading'"></i>
              <span *ngIf="va.rechnungOffenCount !== 'loading'">{{va.rechnungOffenCount}}</span>
            </td>
            <td>
              <i class="fas fa-circle-notch fa-spin" *ngIf="va.rechnungGesamtBetrag === 'loading'"></i>
              <span *ngIf="va.rechnungGesamtBetrag !== 'loading'">{{va.rechnungGesamtBetrag| number:'1.2-2'}} €</span>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="tab-pane py-3 fade" id="stamStats">
      <h3 class="primaryFontColor my-3">Stämme Statistik</h3>
      <div class="table-responsive">
        <table class="table table-striped">

          <tr>
            <th>Name</th>
            <th>Mitglieder</th>
            <th>Mitglieder älter 18</th>
            <th>Mitarbeiter</th>
            <th>Stammesleiter</th>
            <th><span ngbTooltip="Zeigt auf, wie häufig ein Stamm zu einem Event eingeladen wurde.">Einladungen zu Events</span></th>
            <th><span ngbTooltip="Anzahl Events, die durch den Stamm erstellt wurden.">Erstellte Events</span></th>
            <th><span ngbTooltip="Zeigt auf, wie viele Einladungen zu den erstellen Events versendet wurden.">Versendete Einladungen zu Events</span></th>
            <th><span ngbTooltip="Anzahl Rechnungen, die noch durch den Stamm zu bezahlen sind.">Offene Rechnungen (Empfangen)</span></th>
            <th><span ngbTooltip="Anzahl Rechnungen, für die der Stamm noch keine Zahlung erhalten hat.">Offene Rechnungen (Versendet)</span></th>
          </tr>

          <tr *ngFor="let stamm of ss">
            <td>{{stamm.stamm | stammName}}</td>
            <td>{{stamm.tnCount}}</td>
            <td>{{stamm.tnCount18}}</td>
            <td>{{stamm.mitarbeiterCount}}</td>
            <td>
              <ul class="list-group">
                <li class="list-group-item" *ngFor="let sl of stamm.sls">{{sl}}</li>
              </ul>
            </td>
            <td>{{stamm.inviteRecvCount}}</td>

            <td class="bigfont">
              <app-icon [icon]="Icon.VERANSTALTUNGSTYP_LOCAL"></app-icon>
              {{stamm.eventCount0}},<br>
              <app-icon [icon]="Icon.VERANSTALTUNGSTYP_REGIO"></app-icon>
              {{stamm.eventCount1}},<br>
              <app-icon [icon]="Icon.VERANSTALTUNGSTYP_REGP"></app-icon>
              {{stamm.eventCount2}}
            </td>
            <td>{{stamm.inviteSendCount}}</td>
            <td>
              <i class="fas fa-circle-notch fa-spin" *ngIf="stamm.rechnungOffenCountIn === 'loading'"></i>
              <span *ngIf="stamm.rechnungOffenCountIn !== 'loading'">{{stamm.rechnungOffenCountIn}}</span>
            </td>
            <td>
              <i class="fas fa-circle-notch fa-spin" *ngIf="stamm.rechnungOffenCountOut === 'loading'"></i>
              <span *ngIf="stamm.rechnungOffenCountOut !== 'loading'">{{stamm.rechnungOffenCountOut}}</span>
            </td>
          </tr>

        </table>
      </div>
    </div>
  </div>
</div>
