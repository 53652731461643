<div class="container-fluid pt-5">
  <div class="row">
    <div class="col-lg-2 col-xl-3">

    </div>
    <div class="col-12 col-lg-8 col-xl-6">

      <h3>Registration</h3>
      <p>
        Wenn Sie Stammesleiter sind und noch keinen Account besitzen, können Sie über dieses Formular einen anfordern:
      </p>
      <div class="container-fluid">

        <div class=" form-group my-4 my-lg-2" *ngFor="let line of fields1">

          <label [ngClass]="{'pflichtfeld':line.pflichtfeld}" class="primaryFontColor">{{line.label}}</label>
          <ng-container [ngSwitch]="line.type">
            <input *ngSwitchCase="'date'" class="form-control " type="text" placeholder="tt.mm.jjjj"
                   [(ngModel)]="gebDate" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()">
            <select *ngSwitchCase="'dropDown'" class="form-control" [(ngModel)]="nutzer[line.key]">

              <option *ngFor="let g of Object.values(Gender)" value="{{g}}">{{g}}</option>

            </select>
            <input *ngSwitchDefault class="form-control" type="{{line.type}}" [(ngModel)]="nutzer[line.key]"
                   placeholder="{{line.placeholder}}">
          </ng-container>
        </div>
        <div class="w-100">
          <app-password-check [password]="nutzer.passwort"
                              (passwordValid)="passwordValid = $event"></app-password-check>
        </div>
        <div class=" form-group my-4 my-lg-2" *ngFor="let line of fields2">

          <label [ngClass]="{'pflichtfeld':line.pflichtfeld}" class="primaryFontColor">{{line.label}}</label>
          <ng-container [ngSwitch]="line.type">
            <input *ngSwitchCase="'date'" class="form-control " type="text" placeholder="tt.mm.jjjj"
                   [(ngModel)]="gebDate" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()">
            <select *ngSwitchCase="'dropDown'" class="form-control" [(ngModel)]="nutzer[line.key]">

              <option *ngFor="let g of Object.values(Gender)" value="{{g}}">{{g}}</option>

            </select>
            <input *ngSwitchDefault class="form-control" type="{{line.type}}" [(ngModel)]="nutzer[line.key]"
                   placeholder="{{line.placeholder}}">
          </ng-container>
        </div>
        <app-stamm-multi-data-list
          [listTitle]="'Geleitete Stämme'"
          [fullList]="staemme"
          [partialList]="nutzer.stamme"
          [required]="true"
        ></app-stamm-multi-data-list>

      </div>
      <button class="btn pcButton my-3 ml-3" (click)="send()">Zugang Anfragen</button>
    </div>

  </div>
</div>
