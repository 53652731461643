<div class="loadingGif" *ngIf="!tn">

  <app-loading-gif></app-loading-gif>
</div>

<app-editor-tn [tn]="tn" *ngIf="tn"></app-editor-tn>

<div style="height:55px;"></div>
<div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
  <button ngbTooltip="Hier klicken, um zum Duplikat zu kommen." *ngIf="validateDuplicate() as id" class="btn btn-danger m-2" [routerLink]="'/tnEdit/' + id"><i class="fas fa-warning"></i> Teilnehmer schon vorhanden!</button>
  <button class="btn pcButton m-2" (click)="save()">Speichern</button>
</div>
