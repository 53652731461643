import { Component, OnInit } from '@angular/core';
import { IStatistics } from 'src/app/models/IStatistics.interface';
import { VeranstaltungenService } from 'src/app/service/veranstaltungen.service';
import { ExportService } from 'src/app/service/export-service.service';

@Component({
  selector: 'app-view-stats',
  templateUrl: './view-stats.component.html',
  styleUrls: ['./view-stats.component.scss']
})
export class ViewStatsComponent implements OnInit {

  public stats: IStatistics[];

  constructor(
    public eventService: VeranstaltungenService,
    public exportService: ExportService
  ) { }

  async ngOnInit() {
    this.stats = await this.eventService.getStats();
  }

  public download(){
    this.exportService.export(this.stats);
  }

}
