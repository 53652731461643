import {Component, Input, OnInit} from '@angular/core';
import {IVeranstaltung} from "../../../models/IVeranstaltung.interface";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {TimePickerService} from "../../../service/time-picker-service.service";
import {INutzer} from "../../../models/INutzer.interface";
import {IStamm} from "../../../models/IStamm.interface";
import {StammService} from "../../../service/stamm.service";
import {NutzerService} from "../../../service/nutzer.service";
import {SessionService} from "../../../service/session.service";
import {Rolle} from "../../../models/Rolle.enum";
import {Icon} from "../../../utils/icon/icon.component";
import {IconOption} from "../../../utils/icon-switch/icon-switch.component";

@Component({
  selector: 'app-event-edit-mask',
  templateUrl: './event-edit-mask.component.html',
  styleUrls: ['./event-edit-mask.component.scss']
})

export class EventEditMaskComponent implements OnInit {
  @Input() public event: IVeranstaltung;
  private _startDatum?: NgbDateStruct;
  private _endDatum?: NgbDateStruct;
  private _anmeldeschluss?: NgbDateStruct;
  protected readonly Icon = Icon;
  public Rolle = Rolle;
  public eventSizeIconOption :IconOption[] = [
    {icon: Icon.VERANSTALTUNGSTYP_LOCAL, value:"0",strike:false},
    {icon: Icon.VERANSTALTUNGSTYP_REGIO, value:"1",strike:false},
    {icon: Icon.VERANSTALTUNGSTYP_REGP, value:"2",strike:false}];
  constructor(
    private readonly timePickerService: TimePickerService,
    private readonly nutzerService: NutzerService,
    public readonly stammService: StammService,
    public readonly sessionService: SessionService
  ) {

  }

  ngOnInit(): void {
  }

  updateOption(type: string) {
    if(this.event === undefined){
      console.warn("Failed to update Event Type");
      return;
    }

    this.event.veranstaltungstyp = type;
  }

  public get startDatum(): NgbDateStruct {
    if (!this._startDatum) {
      this._startDatum = this.timePickerService.dateToStruct(this.event.anfangsdatum);
    }
    return this._startDatum;
  }

  public set startDatum(newDate: NgbDateStruct) {
    this.event.anfangsdatum = this.timePickerService.structToDate(newDate);
    this._startDatum = newDate;
  }


  public get endDatum(): NgbDateStruct {
    if (!this._endDatum) {
      this._endDatum = this.timePickerService.dateToStruct(this.event.enddatum);
    }
    return this._endDatum;
  }

  public set endDatum(newDate: NgbDateStruct) {
    this.event.enddatum = this.timePickerService.structToDate(newDate);
    this._endDatum = newDate;
  }

  public get anmeldeschluss(): NgbDateStruct {
    if (!this._anmeldeschluss) {
      this._anmeldeschluss = this.timePickerService.dateToStruct(this.event.anmeldeschluss);
    }
    return this._anmeldeschluss;
  }

  public set anmeldeschluss(newDate: NgbDateStruct) {
    this.event.anmeldeschluss = this.timePickerService.structToDate(newDate);
    this._anmeldeschluss = newDate;
  }

  public veranstalterToText(stamm: IStamm) {
    return stamm.name + (stamm.title ? (" (" + stamm.title + ")") : "");
  }

  public veranstalterToHtml(stamm: IStamm) {
    const txt = stamm.name + (stamm.title ? (" (" + stamm.title + ")") : "");
    return `<img height="32" width="32" src="${stamm.icon}">${txt}`;
  }

  public veranstalterToId(stamm: IStamm) {
    return stamm.id;
  }

  public setVeranstallter(stamm: IStamm) {
    if(this.event === undefined){
      console.warn("Failed to update Veranstalter, Event is undefined");
      return;
    }

    this.event.veranstalter = stamm;
  }

  public getPossibleVams(): INutzer[]{
    return this.nutzerService.getNutzerListe().filter(n => {
      if(this.event.veranstaltungstyp === "2") {
        return true;
      }
      if(this.event.staemme.some(s => n.stamme.some(ns => ns.id === s.id))){
        return true;
      }
      if(n.stamme.some(ns => ns.id === this.event.veranstalter.id)) {
        return true;
      }
      return false;
    });
  }



}
