import { Component, OnInit } from '@angular/core';
import { NutzerService } from 'src/app/service/nutzer.service';
import { ToastrService } from 'ngx-toastr';
import * as sha1 from 'js-sha1';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';



@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {

  public token = "";
  public username = "";
  public password = "";
  public passwordCheck = "";
  public passwordValid = false;

  constructor(
    private nutzerService: NutzerService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    this.route.params.subscribe(prams => {
      this.token = prams['token'];
    });
  }

  ngOnInit() {
  }

  public setPassword(){
    if(!this.passwordValid) {
      this.toast.error("Das Passwort erfüllt nicht die Richtlinien", "Fehler");
      return;
    }
    if(this.password !== this.passwordCheck){
      this.toast.error("Die Passwörter sind nicht identisch", "Fehler");
      return;
    }
    if(!this.password){
      this.toast.error("Bitte gib ein Passwort ein", "Fehler");
      return;
    }
    if(!this.username){
      this.toast.error("Bitte gib deinen Nutzernamen ein", "Fehler");
      return;
    }
    if(!this.token){
      this.toast.error("Der Link ist ungültig", "Fehler");
      return;
    }
    this.nutzerService.setPasswordFromRecovery(this.username, this.token, sha1(this.password)).then((result)=>{
      if(result.success){
        this.toast.success("Passwort wurde erfolgriech gesetzt", "Erfolgreich");
        this.router.navigateByUrl('/home');
      } else {
        this.toast.error(result.error, "Fehler");
      }
    }).catch(e=>{
      this.toast.error("Fehler beim Setzen des Passworts", "Fehler");
    });
    this.password = "";
    this.passwordCheck = "";
  }
}
