<div class="container">
  <h1 class="text-center primaryFontColor">Rechnungen</h1>
  <p class="text-center mb-3">Hier werden alle Rechnungen aufgelistet, die an Stämme gesendet wurden, für die Sie Stammesleiter sind.</p>
  <div class="p-3">
    <app-filter [query]="query" [actionButtons]="[]" (changeText)="onFilterChange($event)" [categories]="categories" [useUrlBar]="false"></app-filter>
  </div>
  <table class="table d-none d-lg-table">
    <tr>
      <th class="sort-th" (click)="setSort('$veranstaltung')">Event</th>
      <th class="sort-th" (click)="setSort('$stamm')">Stamm</th>
      <th class="sort-th" (click)="setSort('$rechnungsNummer')">Rechnungsnummer</th>
      <th class="sort-th" (click)="setSort('$betrag')">Betrag</th>
      <th class="sort-th" (click)="setSort('$rechnungsStatus')">Status</th>
      <th></th>
    </tr>
    <tr *ngFor="let rechnung of rechnungen">
      <td>{{rechnung.veranstaltung.titel}} <a routerLink="/eventDetails/{{rechnung.veranstaltung.id}}" class="text-dark small"><i class="fas fa-external-link-alt"></i></a></td>
      <td>
        <img src="{{rechnung.stamm.icon}}" [ngbTooltip]="rechnung.stamm | stammName" class="stammIcon "> {{rechnung.stamm.name + (rechnung.stamm.title?(" (" + rechnung.stamm.title + ")"):"") }}
      </td>
      <td><app-rechnungsnummer [rechnungsnummer]="rechnung.rechnungsNummer"></app-rechnungsnummer></td>
      <td>{{rechnung.betrag.toFixed(2)}} €</td>
      <td>{{rechnung.rechnungsStatus}}</td>
      <td><button class="btn scButton" (click)="showRechnung(rechnung)" ngbTooltip="Rechnung herunterladen"><i class="fas fa-download"></i></button></td>
    </tr>
  </table>

  <!-- MOBILE -->
  <div class="d-lg-none p-2">
    <ng-container *ngFor="let rechnung of rechnungen">
      <div class=" pcBorder rounded my-2 d-lg-none d-block" >
        <div class="d-flex pb-2 align-items-center pr-2">
          <div class="mr-auto p-2 stammMobileBorder secondaryBgColor">
            <b>{{rechnung.veranstaltung.titel}} <a routerLink="/eventDetails/{{rechnung.veranstaltung.id}}" class="text-dark small"><i class="fas fa-external-link-alt"></i></a></b>
          </div>
          <div class="alterDivMobile pl-2">{{rechnung.rechnungsStatus}}</div>

        </div>
        <div class="d-flex p-2 pt-0 align-items-center">
          <div class="mr-auto">
            <img src="{{rechnung.stamm.icon}}"
                 class="stammIcon mr-2"/><span>{{rechnung.stamm | stammName}}</span>

          </div>
          <div class="alterDivMobile text-right">{{rechnung.betrag.toFixed(2)}} €</div>
        </div>
        <div class="d-flex p-2 pt-0 align-items-center">
          <div class="mr-auto"><app-rechnungsnummer [rechnungsnummer]="rechnung.rechnungsNummer"></app-rechnungsnummer></div>
          <button  class="btn scButton"  (click)="showRechnung(rechnung)" ><i class="fas fa-download"></i></button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
