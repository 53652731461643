import { ITeilnehmer } from './ITeilnehmer.interface';
import { INutzer } from './INutzer.interface';

export interface IStamm{
    id: number;
    title: string;
    icon: string;
    mitglieder: ITeilnehmer[];
    stammesleiter: INutzer[];
    hausnummer: string;
    postleitzahl: number;
    strasse: string;
    ort: string;
    name: string;
    land: string;
    website: string;
    email: string;
    telefon: string;
    fax: string;
    kontoinhaber: string;
    iban: string;
    bic: string;
    bank: string;
    alt_rechnung_empfaenger: string
    alt_rechnung_hausnummer: string
    alt_rechnung_postleitzahl: string
    alt_rechnung_strasse: string
    alt_rechnung_ort: string
    alt_rechnung_land: string
}

export function isStamm(obj: Object): obj is IStamm {
    function d(value: string): boolean{
      return value !== undefined;
    }

    return obj &&
    d(obj["id"]) &&
    d(obj["title"]) &&
    d(obj["name"]) &&
    d(obj["icon"]) &&
    d(obj["mitglieder"]) &&
    d(obj["stammesleiter"]) &&
    d(obj["hausnummer"]) &&
    d(obj["postleitzahl"]) &&
    d(obj["strasse"]) &&
    d(obj["land"]) &&
    d(obj["website"]) &&
    d(obj["email"]) &&
    d(obj["telefon"]) &&
    d(obj["fax"]) &&
    d(obj["kontoinhaber"]) &&
    d(obj["iban"]) &&
    d(obj["bic"]) &&
    d(obj["bank"]) &&
    d(obj["ort"]) &&
    d(obj["alt_rechnung_empfaenger"]) &&
    d(obj["alt_rechnung_hausnummer"]) &&
    d(obj["alt_rechnung_postleitzahl"]) &&
    d(obj["alt_rechnung_strasse"]) &&
    d(obj["alt_rechnung_ort"]) &&
    d(obj["alt_rechnung_land"]);
}

export function getStammSearchCategories() {
  return [
    { display: "Allgemein", key: "Allgemein", type: "CAT" },
    { display: "Titel", key: "title", type: "string"},
    { display: "Mitglieder", key: "mitglieder", type: "ITeilnehmer[]"},
    { display: "Stammesleiter", key: "stammesleiter", type: "INutzer[]"},
    { display: "Hausnummer", key: "hausnummer", type: "string"},
    { display: "Postleitzahl", key: "postleitzahl", type: "number"},
    { display: "Strasse", key: "strasse", type: "string"},
    { display: "Ort", key: "ort", type: "string"},
    { display: "ID", key: "id", type: "number"}
  ];
}
