import {Component, Input, OnInit} from '@angular/core';
import {ITeilnehmer} from "../../models/ITeilnehmer.interface";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {TimePickerService} from "../../service/time-picker-service.service";
import {binaryIconOptions, IconOption} from "../../utils/icon-switch/icon-switch.component";
import {Icon, IconMeta} from "../../utils/icon/icon.component";
import {Gender} from "../../models/gender.enum";
import {ISession} from "../../models/ISession.interface";
import {SessionService} from "../../service/session.service";
import {Rolle} from "../../models/Rolle.enum";
import {IStamm} from "../../models/IStamm.interface";
import {StammService} from "../../service/stamm.service";
import {TeilnehmerService} from "../../service/teilnehmer.service";
import {EventQueueService} from "../../service/event-queue-service.service";

@Component({
  selector: 'app-editor-tn',
  templateUrl: './editor-tn.component.html',
  styleUrls: ['./editor-tn.component.scss']
})
export class EditorTnComponent implements OnInit {
  public readonly Icon = Icon;
  @Input()
  public tn!: ITeilnehmer;
  private _birthDate?: NgbDateStruct;
  public now = Date.now();
  public session : ISession;
  public nutzerStaemme: IStamm[];
  public iconList: Array<{icon:Icon, options: IconOption[], key:string}>;
  public iconMeta = IconMeta;
  genderOptions: IconOption[] = [
    {icon:Icon.MAENNLICH, strike: false, value: Gender.MALE},
    {icon:Icon.WEIBLICH, strike: false, value: Gender.FEMALE},
    {icon:Icon.DIVERS, strike: false, value: Gender.DIVERS}
  ];
  veganOption: IconOption[] = [
    {icon:Icon.VEGETARIER, strike: true, value: 0},
    {icon:Icon.VEGETARIER, strike: false, value: 1},
    {icon:Icon.VEGANER, strike: false, value: 2}
  ];
  mitarbeiterOption: IconOption[] = binaryIconOptions(Icon.MITARBEITERSTATUS);

  constructor(
    private timePickerService: TimePickerService,
    private sessionService:SessionService,
    private stammService:StammService,
    private teilnehmerService: TeilnehmerService,
    private eventQueue: EventQueueService
  ) {
    this.session = this.sessionService.session;
    this.iconList = this.teilnehmerService.iconList;

    this.eventQueue.getObservable().subscribe(msg => {
      if (msg === "initDone") {
        this.ngOnInit();
      }
    });

  }

  ngOnInit(): void {
    if(this.sessionService.session.currentPermission === Rolle.ADM || this.sessionService.session.currentPermission === Rolle.SU){
      this.nutzerStaemme = this.stammService.getStammListe();
    }else{
      this.nutzerStaemme = this.sessionService.session.nutzer.stamme;
    }
  }

  public get birthDate(): NgbDateStruct {
    if (!this._birthDate) {
      this._birthDate = this.timePickerService.dateToStruct(this.tn.geburtsdatum);
    }
    return this._birthDate;
  }

  public set birthDate(newDate: NgbDateStruct) {
    this._birthDate = newDate;
    this.tn.geburtsdatum = this.timePickerService.structToDate(newDate);
  }

  public get veganState():0 | 1 | 2{
    return this.tn.veganer ? 2: this.tn.vegetarier ? 1: 0
  }

  public set veganState(state:0 | 1 | 2){
    this.tn.veganer = state === 2;
    this.tn.vegetarier = state === 1;
  }

  public copyTnAddrToPer() {
    this.tn.per_nachname = this.tn.nachname;
    this.tn.per_zusatz = this.tn.zusatz;
    this.tn.per_telefonnummer = this.tn.handynummer;
    this.tn.per_strasse = this.tn.strasse;
    this.tn.per_hausnummer = this.tn.hausnummer;
    this.tn.per_postleitzahl = this.tn.postleitzahl;
    this.tn.per_ort = this.tn.ort;
    this.tn.per_e_mail_adresse = this.tn.e_mail_adresse;
  }
}
