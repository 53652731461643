<div class="d-inline-block d-flex flex-column align-items-center">
  <div class="d-inline-block switchBox shadow position-relative" (click)="selectedOption = (selectedOption + 1) % options.length ; valueChange.emit(options[selectedOption].value)">
    <app-icon *ngFor="let o of options" [icon]="o.icon" [strike]="o.strike" class="switchBall icon_opacity" style="margin: 0 2px;"></app-icon>
    <div class="d-inline-block position-absolute switchBall" [style.left]="'calc('+selectedOption/options.length*100+'% + 2px)'" *ngIf="selectedOption !== -1">
      <app-icon [icon]="options[selectedOption].icon" [strike]="options[selectedOption].strike"></app-icon>
    </div>
  </div>
  <div class="d-block d-lg-none text-center" *ngIf="showHint && selectedOption !== -1">
    {{iconMeta.get(options[selectedOption].icon)[(options[selectedOption].strike ? 3 : 2)]}}
  </div>
</div>
