<div class="row d-none d-lg-flex rounded shadow-lg row-eq-height event position-relative">
  <ng-container *ngIf="isHistoric()">
    <div class="historic-marker p-1 rounded shadow text-white">Veranstaltung liegt in der Vergangenheit</div>

  </ng-container>
  <div class="col-3 text-center pc">
    <div class="row borderBottom row-eq-height">
      <div class="col-12 ">
        <div class="title">Vom</div>
        <div class="date">{{event.anfangsdatum | date:"dd.MM."}}</div>
        <div class="d-flex year">
          <div class="text-left">{{event.anfangsuhrzeit}}</div>
          <div class="ml-auto">{{event.anfangsdatum | date:"yyyy"}}</div>
        </div>

      </div>
    </div>
    <div class="row borderBottom row-eq-height">
      <div class="col-12 ">
        <div class="title">Bis</div>
        <div class="date">{{event.enddatum | date:"dd.MM."}}</div>
        <div class="d-flex year">
          <div class="text-left">{{event.enduhrzeit}}</div>
          <div class="ml-auto">{{event.enddatum | date:"yyyy"}}</div>
        </div>
      </div>
    </div>
    <div class="row row-eq-height">
      <div class="col-12 ">
        <div class="title">Anmeldung bis</div>
        <div class="date">{{event.anmeldeschluss | date:"dd.MM."}}</div>
        <div class="year text-right">{{event.anmeldeschluss | date:"yyyy"}}</div>
      </div>
    </div>
  </div>
  <div class="col-9 sc1 ">
    <div class="row eventTitle text-center">
      <div class="col-10 ">
        {{event.titel}}
      </div>
      <div class="col-2">
        <img src="{{event.veranstalter.icon}}" style="padding-bottom:2px;" width="38" height="38" placement="bottom" ngbTooltip="{{event.veranstalter.name}}">
      </div>
    </div>
    <div class="eventDesc text-left">
      <pre class="w-100">{{event.kurzbeschreibung}}</pre>
    </div>
    <div class="bottomRow">
      <div class="row  h-100 bottomRowBorder">
        <div class="col-4 align-self-center text-left">
          <b>Ort:</b> {{event.ort}}
        </div>
        <div class="col-4 align-self-center text-center">
          <b>Teilnehmer:</b> {{event.teilnehmer.length}}/{{event.max_teilnehmeranzahl}}
        </div>
        <div class="col-4 align-self-center text-right">

          <button class="btn pcButton shadow-small" ngbTooltip="Details" routerLink="/eventDetails/{{event.id}}">
            <i class="fa fa-eye"></i>
          </button>
          <app-icon class="bigfont" [icon]="typeIcon"></app-icon>

        </div>
      </div>
    </div>
    <div class="bottomRow1 primaryBgColor">
      <div class="row h-100 primaryFgColor px-3">
        <div class="col-4 align-self-center" ngbTooltip="Preis pro Teilnehmer">
          Teilnehmerpreis: {{event.preis | number:'1.2-2'}} €
        </div>
        <div class="col-4 align-self-center text-center" ngbTooltip="Preis pro Mitarbeiter">
          Mitarbeiterpreis: {{event.mitarbeiter_preis | number:'1.2-2'}} €
        </div>

        <div class="col-4 align-self-center text-right" ngbTooltip="Preis bei abmeldung nach Anmeldefrist">
          Ausfallgebühr: {{event.ausfallgebuehr | number:'1.2-2'}} €
        </div>



      </div>
    </div>
  </div>
</div>

<div class="row d-flex d-lg-none primaryBgColor text-light rounded shadow my-2 text-center p-2" (click)="showDetails(event.id)">
  <div class="col-12">
    <div class="row ">
      <div class="col-12 py-1">
        <strong>{{event.titel}}</strong>
      </div>
    </div>
    <div class="row ">
      <div class="col-6 text-left">
        Vom: {{event.anfangsdatum | date:"dd.MM."}}
      </div>
      <div class="col-6 text-right">
        Bis: {{event.enddatum | date:"dd.MM."}}
      </div>
    </div>

    <div class="row ">
      <div class="col-6 text-left">
       {{event.preis | number:'1.2-2'}} €
      </div>
      <div class="col-6 text-right">
        Ort: {{event.ort}}
      </div>
    </div>
    <div *ngIf="isHistoric()" class="row">
      <div class="col-12 text-center">
       Veranstaltung liegt in der Vergangenheit
      </div>
    </div>
  </div>
</div>
