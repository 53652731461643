import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IStamm } from 'src/app/models/IStamm.interface';
import { NutzerService } from 'src/app/service/nutzer.service';
import { StammService } from 'src/app/service/stamm.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-stammesleiter',
  templateUrl: './stammesleiter.component.html',
  styleUrls: ['./stammesleiter.component.scss']
})
export class StammesleiterComponent implements OnInit {

  public stamm: IStamm;
  constructor(
    private activeModal: NgbActiveModal,
    private toast: ToastrService,
    public nutzerService: NutzerService,
    private stammService: StammService) { }

  ngOnInit() {
    if (this.stamm === undefined) {
      this.stamm = null;
    }
  }

  public save() {
    this.stammService.updateStamm(this.stamm).
    then(msg => {
      this.toast.success(msg, 'Erfolgreich');
      this.activeModal.close();
    }).
    catch(msg => this.toast.error(msg, 'Fehler'));
  }
}
