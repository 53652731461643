<div class="d-none d-lg-block">
  <div *ngFor="let option of options">
    <div class="d-flex w-100 navPoint align-items-center py-2 pl-4 pr-1" (click)="selectedElement.emit(option.key)"
         [ngClass]="currentElement === option.key ? 'active':''" *ngIf="!option.needPermission || isVAM">
      <div class="mr-2">
        <i [ngClass]="option.icon" class="fa-fw"></i>
      </div>
      {{option.name}}
    </div>
  </div>
</div>

<nav class="navbar navbar-expand-lg secondaryBgColor navbar-light d-flex d-lg-none">  <!-- Toggler/collapsibe Button -->
  <span class="primaryFontColor"><i [ngClass]="getTitleOfCurrentSelection().icon" class="fa-fw mr-2"></i> {{getTitleOfCurrentSelection().name}}</span>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#subMenu">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Navbar links -->
  <div class="collapse navbar-collapse" id="subMenu" >
    <ul class="navbar-nav">
      <ng-container *ngFor="let option of options">
        <li class="nav-item p-2" *ngIf="!option.needPermission || isVAM" [ngClass]="currentElement === option.key ? 'active':''">
          <a data-toggle="collapse" data-target="#subMenu" class="nav-link " (click)="selectedElement.emit(option.key)"><i [ngClass]="option.icon" class="fa-fw"></i> {{option.name}}</a>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>
