 <nav class="navbar navbar-expand-xl navbar-dark primaryBgColor"  [ngClass]="{'dev': dev}" >
  <!-- Brand -->
  <a class="navbar-brand" href="#">
    <img src="../../assets/img/regp-logo.png" class="logo" alt="Logo" style="width:40px;">
  </a>

  <!-- Toggler/collapsibe Button -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Navbar links -->
  <div class="collapse navbar-collapse" id="collapsibleNavbar">


    <ul class="navbar-nav" *ngIf="sessionService.session.sessionId">
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="event">Events</a>
      </li>
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="tn">Teilnehmer</a>
      </li>
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="rechnung">Rechnungen</a>
      </li>
      <li class="nav-item" *ngIf="sessionService.session.currentPermission != 'stammesleiter'">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="stats" >Statistik</a>
      </li>
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="setting">Einstellungen</a>
      </li>
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="datenschutz">Datenschutz</a>
      </li>
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="impressum">Impressum</a>
      </li>
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="help">Handbuch</a>
      </li>
    </ul>

    <ul class="navbar-nav" *ngIf="!sessionService.session.sessionId">
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="home">Anmeldung</a>
      </li>
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="datenschutz">Datenschutz</a>
      </li>
      <li class="nav-item">
        <a data-toggle="collapse" data-target="#collapsibleNavbar" class="nav-link" routerLink="impressum">Impressum</a>
      </li>
    </ul>
    <hr class="d-block d-lg-none">
    <form class="form-inline ml-lg-auto">
      <app-login></app-login>
    </form>
  </div>

</nav>
