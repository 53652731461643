<div class="border-top-rounded primaryBackround">
  <div class=" form-group">

      <label class="primaryFontColor" [ngClass]="{'pflichtfeld':required}" > Stamm hinzufügen</label>
    <app-single-datalist  class="form-control p-0" (onSelect)="addStamm($event)" [trackByFn]="stammToId" [multi]="true"
      [toHtml]="stammToHtml" [toText]="stammToText" [auswahl]="" [fullList]="fullListFiltered">
    </app-single-datalist>

  </div>
</div>

<table class="table primaryBackround border-bottom-rounded" *ngIf="(partialList.length>0)">
  <tr>
    <th colspan="3"class="fielDescription text-center">{{listTitle}}</th>
  </tr>
  <tr *ngFor="let stamm of partialList">
    <td><img src="{{stamm.icon}}" width="32px" height="32px" /></td>
    <td>{{stamm.name + (stamm.title?(" (" + stamm.title + ")"):"")}}</td>
    <td><button class="btn btn-danger float-right" (click)="removeStamm(stamm)">Entfernen</button></td>
  </tr>
</table>
