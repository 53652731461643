import {Injectable} from '@angular/core';
import {ISession} from '../models/ISession.interface';
import {BackendService} from './backend.service';
import { EventQueueService } from './event-queue-service.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Rolle } from '../models/Rolle.enum';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private _session: ISession = {sessionId: null, username: null, nutzer: null, currentPermission: Rolle.WAIT};
  private navigateAfterRestore: Parameters<Router["navigate"]>;
  private changePasswordToastId: number = 0;

  constructor(
    private backend: BackendService,
    private eventQueue: EventQueueService,
    private router: Router,
    private toastr: ToastrService) {
    this.eventQueue.getObservable().subscribe(msg => this.checkPasswordUpdateRequired(msg));
  }

  public async restoreSession(): Promise<void> {
    const sessionId = localStorage.getItem('sessionId');
    if (sessionId) {
      const result = await this.backend.sendRequest('checkSession', {
        sessionId: sessionId
      }, false);

      if (result.success) {
        this._session.sessionId = sessionId;
        this._session.username = result.username;
        this._session.currentPermission = result.currentPermission;

        BackendService.updateIdToProperty(this._session, 'nutzer', () => (window as any).nutzerService.getNutzerByUsername(result.username), false, () => (window as any).nutzerService.getRevision());
        this.eventQueue.fireEvent('permissionChange');
        this.toastr.success('Sitzung wiederhergestellt', 'Erfolgreiche Anmeldung');
        await this.router.navigate(...this.navigateAfterRestore);
      }
    }
  }

  public checkPasswordUpdateRequired(msg: String) {
    if(msg != 'initDone') {
      return;
    }
    if(!this._session.nutzer){
      return;
    }
    if(!this._session.nutzer.last_password_change && !this.changePasswordToastId) {
      this.changePasswordToastId = this.toastr.info(
        'Ihr Passwort entspricht nicht mehr den aktuellen Anforderungen und muss in den Einstellungen geändert werden!',
        'Sicherheitswarnung',
        {
          closeButton: false,
          disableTimeOut: true,
          tapToDismiss: false,
          positionClass: "toast-bottom-left",
          enableHtml: true
        }).toastId;
    } else if(this._session.nutzer.last_password_change && this.changePasswordToastId) {
      this.toastr.clear(this.changePasswordToastId);
    }
  }

  public login(username: string, password: string): void {
    const result = this.backend.sendRequest('login', {
      username: username,
      password: password
    }, false);

    result.then(session => {
      if (session.sessionId === null) {
        console.log(session);
        this._session = {sessionId: null, username: null, nutzer: null, currentPermission: Rolle.WAIT};
        this.toastr.error(session.msg, 'Fehlerhafte Anmeldung');
        return;
      }

      BackendService.updateIdToProperty(session, 'nutzer', (id) => (window as any).nutzerService.getNutzer(id), false, () => (window as any).nutzerService.getRevision());
      Object.defineProperty(session, 'username', {get: () => username, enumerable: true});
      this._session = session as unknown as ISession;
      localStorage.setItem('sessionId', this._session.sessionId);
      this.eventQueue.fireEvent('permissionChange');
      this.router.navigate(['/event']);
      this.toastr.success('Sie haben sich erfolgreich angemeldet', 'Erfolgreiche Anmeldung');

    }).catch(error => {
      console.log(error);
      this._session = {sessionId: null, username: null, nutzer: null, currentPermission: Rolle.WAIT};
      this.toastr.error('Anmeldung fehlgeschlagen', 'Fehlerhafte Anmeldung');
    });
  }

  public get session() {
    return this._session;
  }

  public logout() {
    this.backend.sendRequest('logout', {sessionId: this._session.sessionId}, false);
    this._session = {sessionId: null, username: null, nutzer: null, currentPermission: Rolle.WAIT};
    localStorage.removeItem('sessionId');
    this.router.navigate(['/home']);
    this.eventQueue.fireEvent('permissionChange');
    this.toastr.success('Sie haben sich erfolgreich abgemeldet', 'Erfolgreiche Abmeldung');
  }

  public togglePermission() {
    if(this.session.currentPermission === this.session.nutzer.rolle) {
      this.backend.sendRequest('setSessionPermissions', {
        sessionId: this.session.sessionId,
        newPermissions: Rolle.SL
      }, true).then(_ => {
        this.session.currentPermission = Rolle.SL;
      });
    } else {
      this.backend.sendRequest('setSessionPermissions', {
        sessionId: this.session.sessionId,
        newPermissions: this.session.nutzer.rolle
      }, true).then(_ => {
        this.session.currentPermission = this.session.nutzer.rolle;
      });
    }
  }

  // Check Funktion fürs Routing
  canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): boolean {
    const ignorError = !this.navigateAfterRestore;
    this.navigateAfterRestore = [[route.url.join('/')], {
      queryParams: route.queryParams,
      queryParamsHandling: "merge"
    }];
    const isLoggedIn = this._session && this._session.username;
    if (isLoggedIn) {
      return true;
    } else if (ignorError) {
      this.router.navigate(['/home'], {queryParams:route.queryParams, queryParamsHandling: "merge"});
      return false;
    } else {
      this.router.navigate(['/home'], {queryParams:route.queryParams, queryParamsHandling: "preserve"});
      this.toastr.warning('Navigation nicht möglich. Bitte einloggen!', 'Keine Berechtigung');
      return false;
    }

  }
}
