<div class="container">
  <div class="d-flex flex-wrap align-items-center">
    <div title="Geschlecht" class="bigfont mr-2">
      <app-icon [icon]="(tn.geschlecht === Gender.MALE ? Icon.MAENNLICH : tn.geschlecht === Gender.FEMALE ? Icon.WEIBLICH : Icon.DIVERS)"></app-icon>
    </div>
    <div class="bigfont mr-auto" ngbTooltip="Name">
      <b>{{tn.vorname + " " + tn.nachname}}</b>
    </div>
    <div class="bigfont d-flex align-items-center">
      <img src="{{tn.stamm.icon}}" [ngbTooltip]="tn.stamm | stammName" class="stammIcon mr-2"/>
      <app-icon [icon]="Icon.MITARBEITERSTATUS" [strike]="!tn.mitarbeiterstatus"></app-icon>
      <app-icon [icon]="tn.veganer? Icon.VEGANER : Icon.VEGETARIER" [strike]="!(tn.vegetarier || tn.veganer)"></app-icon>
    </div>
  </div>

  <div class="mt-2 d-flex flex-wrap flex-column flex-lg-row">
    <div class="d-flex p-2 rounded secondaryBgColor mr-lg-2 mb-2 align-items-center" ngbTooltip="Geburtsdatum und Alter">
      <div class="mr-2"><i class="fas fa-calendar-day icon phone"></i></div>
      <div>{{tn.geburtsdatum | date: 'dd.MM.yyyy'}}<br>{{tn.geburtsdatum | ageFilter}} Jahre</div>
    </div>
    <div class="p-2 rounded secondaryBgColor mr-lg-2 d-flex align-items-center mb-2" ngbTooltip="Adresse">
      <div class="mr-2 icon phone"><i class="fas fa-mail-bulk"></i></div>
      <div class="">
        {{tn.zusatz}}
        <ng-container *ngIf="tn.zusatz"><br></ng-container>
        {{tn.strasse + " " + tn.hausnummer}}
        <br>
        {{tn.postleitzahl}} {{tn.ort}}
      </div>
    </div>
    <div class="d-flex align-items-center p-2 rounded secondaryBgColor mr-lg-auto mb-2">
      <div>
        <app-icon [icon]="Icon.HANDYNUMMER" *ngIf="tn.handynummer"></app-icon>
        <a class="noLink" href="tel:{{tn.handynummer}}">

          {{tn.handynummer}}
        </a>
        <br>
        <app-icon [icon]="Icon.E_MAIL_ADRESSE" *ngIf="tn.e_mail_adresse"></app-icon>
        <a class="noLink" href="mailto:{{tn.e_mail_adresse}}">
          {{tn.e_mail_adresse}}
        </a>
      </div>
    </div>
  </div>

  <div class="mt-2" id="kontaktPerson">
    <div class="card ">
      <div class="card-header collapsed" data-toggle="collapse" href="#kontakt">
        <i class="fas fa-caret-up arrowUp"></i>
        <i class="fas fa-caret-down arrowDown"></i>
        Kontakt­person
      </div>
      <div id="kontakt" class="collapse " data-parent="#kontaktPerson">
        <div class="card-body">
          <h5>{{tn.per_vorname}} {{tn.per_nachname}}</h5>
          <div class="row">
            <div class="col-12 col-lg-6">
              {{tn.per_zusatz}} <ng-container *ngIf="tn.per_zusatz"><br></ng-container>
              {{tn.per_strasse}} {{tn.per_hausnummer}}<br>
              {{tn.per_postleitzahl}} {{tn.per_ort}}
            </div>
            <div class="col-12 col-lg-6 mt-2 mt-lg-0">
              <div class="row">
                <div class="col-1 col-lg-3">
                  <app-icon [icon]="Icon.PER_TELEFONNUMMER"></app-icon>
                </div>
                <div class="col-10 col-lg-9">
                  <a class="noLink" href="tel:{{tn.per_telefonnummer}}">{{tn.per_telefonnummer}}</a>
                </div>
              </div>
              <div class="row">
                <div class="col-1 col-lg-3">
                  <app-icon [icon]="Icon.PER_HANDYNUMMER"></app-icon>
                </div>
                <div class="col-10 col-lg-9">
                  <a class="noLink" href="tel:{{tn.per_handynummer}}">{{tn.per_handynummer}}</a>
                </div>
              </div>
              <div class="row">
                <div class="col-1 col-lg-3">
                  <app-icon [icon]="Icon.PER_E_MAIL_ADRESSE"></app-icon>
                </div>
                <div class="col-10 col-lg-9">
                  <a class="noLink" href="mailto:{{tn.per_e_mail_adresse}}">{{tn.per_e_mail_adresse}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="tn.medizinische_hinweise || tn.krankheiten">
      <div class="card-header collapsed" data-toggle="collapse" href="#medizin">
        <i class="fas fa-caret-up arrowUp"></i> <i class="fas fa-caret-down arrowDown"></i>Medi­ka­mente & Krank­heiten
        <app-icon [icon]="Icon.KRANKHEITEN" [disabled]="!tn.krankheiten" class="float-right"></app-icon>
        <app-icon [icon]="Icon.MEDIZINISCHE_HINWEISE" [disabled]="!tn.medizinische_hinweise" class="float-right"></app-icon>
      </div>
      <div id="medizin" class="collapse" data-parent="#kontaktPerson">
        <div class="card-body">
          <h5 *ngIf="tn.medizinische_hinweise">Medizinische Hinweise</h5>
          {{tn.medizinische_hinweise}}
          <h5 *ngIf="tn.krankheiten">Krankheiten</h5>
          {{tn.krankheiten}}
        </div>
      </div>
    </div>
    <div class="card" *ngIf="tn.ernaehrungsbesonderheiten">
      <div class="card-header collapsed" data-toggle="collapse" href="#ernaehrungsbesonderheiten">
        <i class="fas fa-caret-up arrowUp"></i> <i class="fas fa-caret-down arrowDown"></i> Ernährungsbesonderheiten
        <app-icon [icon]="Icon.ERNAEHRUNGSBESONDERHEITEN" [disabled]="!tn.ernaehrungsbesonderheiten" class="float-right"></app-icon>
      </div>
      <div id="ernaehrungsbesonderheiten" class="collapse" data-parent="#kontaktPerson">
        <div class="card-body">
          {{tn.ernaehrungsbesonderheiten}}
        </div>
      </div>
    </div>
    <div class="card" *ngIf="tn.schwimmabzeichen">
      <div class="card-header collapsed schwimmabzeichen">
        Schwimmabzeichen: {{tn.schwimmabzeichen}}
      </div>
    </div>
    <div class="card" *ngIf="tn.weitere_bemerkungen">
      <div class="card-header collapsed" data-toggle="collapse" href="#bemerkung">
        <i class="fas fa-caret-up arrowUp"></i> <i class="fas fa-caret-down arrowDown"></i> Bemerkung
      </div>
      <div id="bemerkung" class="collapse" data-parent="#kontaktPerson">
        <div class="card-body">
          {{tn.weitere_bemerkungen}}
        </div>
      </div>
    </div>
  </div>


  <div class="container p-0">

  <div class="row m-0">
    <div class="col-12 rounded height1 bigfont iconsContainer d-flex align-items-center flex-wrap justify-content-around">
      <div class="{{iconList.length > 6 ? 'col-1' : 'col-2'}} text-center align-self-center"
           *ngFor="let i of iconList">
        <app-icon [icon]="i.icon" [strike]="i.options[0].value !== tn[i.key]" ></app-icon>
      </div>
    </div>
  </div>

  </div>

</div>

<a class="closeIcon text-danger" (click)="close()"><i class="far fa-2x fa-times-circle"></i></a>
