<div class="loadingGif" *ngIf="!tn">
  <app-loading-gif></app-loading-gif>
</div>

<app-editor-tn *ngIf="tn" [tn]="tn"></app-editor-tn>

<!-----------------------------------------Buttons------------------------------------->
<div style="height:55px;"></div>
<div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
  <button class="btn pcButton m-2" (click)="save()">Speichern</button>
  <button class="btn btn-danger m-2" (click)="delet()">Löschen</button>
</div>
